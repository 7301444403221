import React from 'react';
import "../styles/ChooseTags.css";

interface ChooseTagsProps {
    disabled?: boolean;
    label?: string;
    name?: string;
    type: string;
    value: string,
     onChange?:  any;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    errors?: string | null;
    addClass?: string;
    selectedValue?: string;
    placeholder?: string;
    id?: string;
    checked?:boolean;
    answer_uuid?:string
}

export const ChooseTags: React.FC<ChooseTagsProps> = (
    {   id,
        label,
        onChange,
        name,
        type,
        errors,
        onBlur,
        addClass,
        value,
        placeholder, 
        checked,
        answer_uuid,
        disabled

    }) => {
        const tabIndex = 0;
        const handleChange = (e:any) => {
            onChange(e.target.value, id,answer_uuid)   
        }
    return (
        <>
            <label className={`choosetags-group ${addClass}`}>
                <input   
                    tabIndex={tabIndex}
                    id={id}                
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    checked = {checked}
                    disabled = {disabled}
                />
                <span onClick={() => {}}
                    className="choose-checkmark">
                    {label}
                </span>
            </label>
            {errors && <div className="form-error-msg">{errors}</div>}
        </>
    );
}