import React, { Fragment } from "react";
import { Button } from "../components/Button/Button";
import { ReactComponent as SalfordLogo } from "../assets/svg/SalfordLogo.svg";
import { ReactComponent as NCAlliance } from "../assets/svg/NorthernCareAlliance.svg";
import "../styles/Login.css";

interface HomePage {}
const signIn = () => {
  window.location.href = "/user/login";
};

export const HomePage: React.FC<HomePage> = () => {
  return (
    <Fragment>
      <div className="main-section">
        <div className="block-center">
          <span>
            <SalfordLogo />
          </span>
          <br />
          <Button
            btntype="button"
            text="Sign in"
            addClass="primary-btn w-full btn-login"
            onClick={signIn}
          />
          <br />
        </div>
      </div>
      <div className="block-bottom">
        <NCAlliance tabIndex={0} aria-label="NHS FOOTER LOGO"/>
      </div>
    </Fragment>
  );
};
