import React, { useState, useEffect, useRef } from "react";
import { Button } from "../Button/Button";
import { Layout } from "../Layout/Layout";
import "../styles/Charts.css";
import { BarChart } from "../GraphCharts/BarChart";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import "../styles/PassiveChart.css";
import apiFunctions from "../../services/api-service";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loading from "../../components/Loading/Loading";
import moment from "moment";
import useAuthContext from "../../context/providers/AuthContext";
import { ToastContainer, toast } from "react-toastify";

interface PassiveChartProps { }

export const PassiveChart: React.FC<PassiveChartProps> = () => {
  const {
    state: { participant },
  } = useAuthContext();
  const navigate = useNavigate()

  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  // Strat Graph

  const [startDate, setStartDate] = useState(new Date());
  const [xValues, setxValues] = useState<any>([]);
  const [yValues, setyValues] = useState<any>([]);

  const [sleeDay, setSleepDay] = useState(false);
  const [dayValues, setDateValues] = useState([0, 0, 0, 0, 0]);
  const [timeValues, settimeValues] = useState([
    "00:00",
    "06:00",
    "12:00",
    "18:00",
    "23:00",
  ]);
  const [feildyValues, setFeildValues] = useState<any>([]);
  const [feildType, setFeildType] = useState("calories");
  const [chartType, setChartType] = useState("day");
  const [chartName, setChartName] = useState<any>({
    LightSleep: [],
    DeepSleep: [],
  });
  const [hearRate, setHeartRate] = useState<any>({
    MaxHeartRate: [],
    MinHeartRate: [],
  });
  const withingsSyncError = (message: string) => {
    toast.dismiss();
    setLoading(false);
    toast.error(message, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const optiondata: any = {
    chart: {
      height: 350,
      toolbar: {
        show: true,
      },
      scrollablePlotArea: {
        minWidth: 1600,
        scrollPositionX: 0,
      },
      type: feildType === "heartrate" ? "line" : "column",
    },
    title: {
      text: "",
    },
    tooltip: {
      useHTML: true,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return (
          this.x +
          `</br> ${feildType === "sleep" ? this.series.name : feildType
          } : <b>  ${this.y} ${feildType === 'sleep' ? 'hours' : feildType === 'calories' ? '(kcal)' : feildType === 'heartrate' ? 'beats/minute' : 'steps'} </b>`
        );
      },
    },
    dataLabels: {
      enabled: false,
    },
    xAxis: {
      scrollbar: {
        enabled: true,
      },
      categories: xValues,
      labels: {
        show: true,
        // rotation: 270,
        // maxHeight: 150,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: ["var(--font-body)"],
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-label",
          padding: "100px",
        },
      },
    },
    plotOptions: {
      column: {
        pointPadding:
          feildType === "steps"
            ? 0.4
            : chartType === "day" && feildType === "sleep"
              ? 1
              : 0,
        stacking: "normal",
        grouping: false,
        shadow: false,
        cropThreshold: 100,
        pointInterval: undefined,
        pointStart: undefined,
      },
    },
    yAxis: {
      title: {
        text: feildType === "sleep"
          ? "Sleep - ( hours )"
          : feildType === "heartrate"
            ? "Heart Rate - ( beats/minute )"
            : feildType === 'calories'
              ? "Calories - ( kcal )"
              : "Steps - ( steps )",
      },
      min: 0,
      // tickInterval: feildType === "steps" ? 500 : feildType === "sleep" ? 1: 25,
      tickInterval: 1,
    },
    // feildType !== "sleep"
    //   ? ["var(--primary-100)"]
    //   : [ "#CDCACA","#B5D4ED","#285FA9"],
    // : ["#CDCACA", "#B5D4ED", "#285FA9"],
    credits: {
      enabled: false,
    },
    series: yValues,
    exporting: {
      enabled: false,
    }
  };

  useEffect(() => {
    getChartValues(startDate, feildType, chartType);
  }, [startDate, feildType, chartType]);
  //Get chart Datas
  const getChartValues = async (
    date?: any,
    feildType?: string,
    chartType?: string
  ) => {
    try {

      let URL: any;
      if (
        (feildType === "steps" || feildType === "calories") &&
        chartType === "day"
      ) {
        URL =

          `/api/withings/v2/getintradayactivity?charttype=${chartType}&fieldtype=${feildType}&date=${moment(
            date
          ).format("YYYY-MM-DD")}`;
      } else if (
        (feildType === "steps" || feildType === "calories") &&
        chartType !== "day"
      ) {
        URL =

          `/api/withings/v2/getactivity?charttype=${chartType}&fieldtype=${feildType}&date=${moment(
            date
          ).format("YYYY-MM-DD")}`;
      } else if (feildType === "heartrate") {
        URL =

          `/api/withings/v2/get-heart-rate?charttype=${chartType}&fieldtype=heart_rate&date=${moment(date).format(
            "YYYY-MM-DD"
          )}`;
      } else {
        URL =

          `/api/withings/v2/sleepsummary?type=${chartType}&date=${moment(
            date
          ).format("YYYY-MM-DD")}`;
      }
      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `&service_user_id=${searchParams.get("patientId")}`;
      }

      await apiFunctions
        .post(URL, {})
        .then((_result) => {
          const dataValues = _result.data.result;
          console.log("dataValues.......", dataValues);
          const colors = ["#CDCACA", "#B5D4ED", "#285FA9"];

          if ((feildType === "steps" || feildType === "calories") && chartType === "day" && dataValues.valuesLength <= 0) {
            setxValues(timeValues);
            setyValues([{ data: dayValues, showInLegend: false }]);
            setFeildValues(dayValues);
          } else if (feildType === "steps" || feildType === "calories") {
            setxValues(dataValues.xValues);
            setyValues([
              {
                data: dataValues.yValues,
                showInLegend: false,
                color: colors[2],
              },
            ]);
            setFeildValues(dataValues.yValues);
          } else if (feildType === "heartrate") {
            if (chartType === "day" && dataValues.dateGroup.length === 0) {
              setxValues(timeValues);
              setyValues([{ data: dayValues, showInLegend: false }]);
              setFeildValues(dayValues);
            } else {
              setxValues(dataValues.xValues);
              setyValues([
                {
                  data: dataValues.yValues,
                  showInLegend: false,
                  color: colors[2],
                },
              ]);
              setFeildValues(dataValues.yValues);
              if (chartType !== "day") {
                // setFeildValues(dataValues.yValues);
                setHeartRate({
                  MaxHeartRate: dataValues.maxHeartRate,
                  MinHeartRate: dataValues.minHeartRate,
                });
              }
            }
          } else {
            if (dataValues.xValues.length > 1 || (chartType === "day" && dataValues.xValues[0] !== 0)) {
              setxValues(dataValues.xValues);
              setyValues([
                {
                  data: dataValues.wakeupdurationValues,
                  name: "Awake",
                  showInLegend: dataValues.wakeupdurationValues.filter(
                    (res: any) => {
                      return res !== 0;
                    }
                  ).length
                    ? true
                    : false,
                  color: colors[0],
                },
                {
                  data: dataValues.lightsleepyValues,
                  name: "Light",
                  showInLegend: dataValues.lightsleepyValues.filter(
                    (res: any) => {
                      return res !== 0;
                    }
                  ).length
                    ? true
                    : false,
                  color: colors[1],

                },
                {
                  data: dataValues.deepsleepyValues,
                  name: "Deep or REM",
                  showInLegend: dataValues.deepsleepyValues.filter(
                    (res: any) => {
                      return res !== 0;
                    }
                  ).length
                    ? true
                    : false,
                  color: colors[2],

                },


              ]);
              setFeildValues(dataValues.wakeupdurationValues);
              setChartName({
                LightSleep: dataValues.lightsleepyValues,
                DeepSleep: dataValues.deepsleepyValues,
              });
            } else {
              setSleepDay(true)
              setxValues(timeValues);
              setyValues([{ data: dayValues, showInLegend: false, color: colors[2] }]);
              setFeildValues(dayValues);
              setChartName({
                LightSleep: dayValues,
                DeepSleep: dayValues,
              });
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data && err.response.data.result && !err.response.data.result.withingsSync) {
            withingsSyncError(err.response.data.msg);
            setTimeout(() => {
              if (!searchParams.get("patientId") || searchParams.get("patientId") === 'null') {
                navigate(`/withings`)
              }
            }, 3000);
          }
          console.log("error", err);
        });
    } catch (error: any) {
      console.log(error, "errorresposne");
    }
  };

  const typeOfCharts = [
    {
      name: "Calories burned",
      id: 11,
      value: "calories",
    },
    {
      name: "Heart Rate",
      id: 12,
      value: "heartrate",
    },
    {
      name: "Steps",
      id: 13,
      value: "steps",
    },
    {
      name: "Sleep",
      id: 14,
      value: "sleep",
    },
  ];

  const btnWeek = [
    {
      name: "1 D",
      type: "day",
    },
    {
      name: "1 W",
      type: "week",
    },
    {
      name: "1 M",
      type: "month",
    },
    {
      name: "12 M",
      type: "year",
    },
  ];
  const sroolStart = () => {
    document.getElementsByClassName('highcharts-scrolling')[0].scrollLeft = 0;
    document.getElementsByClassName('scroll-date')[0].scrollLeft = 0;
  }

  return (
    <Layout>
      <Loading status={loading} />
      <ToastContainer />
      <main className="main-container">
        <section className=" outer-space outer-container">
          <div>
            <br />
            <div className="warpper-date-chart">
              <div>
                <p tabIndex={0} className="header-p">
                  Selected date:
                </p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  className="form-control text-field-date"
                  name="startDate"
                  dropdownMode="select"
                  onChange={(e: Date) => {
                    sroolStart();
                    setLoading(true);
                    setStartDate(e);
                  }}
                  isIconVisible="true"
                  autoComplete="off"
                  placeholderText="Please select a date"
                  maxDate={new Date()}
                />
              </div>
              {searchParams.get("patientId") &&
                searchParams.get("patientId") !== "null" && (
                  <div className="wrapper-date-clinic">
                    <h5 tabIndex={0} className="particitant-w">
                      Participant:{" "}
                      {participant &&
                        `${participant?.name || participant?.label}`}
                    </h5>
                  </div>
                )}
            </div>
            <br />
            <section className="btn-collection-group">
              {typeOfCharts.map((item: any) => {
                return (
                  <Button
                    btntype="button"
                    id={item.type}
                    text={item.name}
                    addClass={`btn-passive btn-percent  ${feildType === item.value ? "default-btn-active" : ""
                      }`}
                    onClick={(e: any) => {
                      sroolStart();
                      setLoading(true);
                      setFeildType(item.value);
                      if (item.value === "sleep") {
                        setChartType('week');
                      }
                      if (item.value === "heartrate") {
                        setChartType('day');
                      }
                      // document.getElementsByClassName('highcharts-scrolling')[0].scrollLeft = 0;
                    }}
                    disabled={feildType === item.value ? true : false}
                  />
                );
              })}
              {/* default-btn-active */}
            </section>
            <br />
            <br />
            <section className="inner-chart-container">
              {/* Render Chart here */}
              <BarChart
                // getRef={parentChartRef}
                chartOption={optiondata}
                chartType=""
              />
              <div className="x-text">
                <>
                  {chartType === "day" && 'Hours of the day'}
                  {(chartType === "week" || chartType === "month") && `Days of the ${chartType}`}
                  {chartType === "year" && 'Weeks of the year'}
                </>
              </div>

              <div className="warpper-chat-btn">
                {feildType === 'sleep' ?
                  (btnWeek.filter((res: any) => res.type !== 'day').map((item: any) => {
                    return (
                      <Button
                        btntype="button"
                        id="button"
                        text={item.name}
                        addClass={`graph-btn btn-percent  ${chartType === item.type ? "default-btn-active" : ""
                          }`}
                        onClick={(e: any) => {
                          setLoading(true);
                          setChartType(item.type);
                        }}
                        disabled={chartType === item.type ? true : false}
                      />
                    );
                  })
                  )
                  : feildType === "heartrate" ?
                    (btnWeek.filter((res: any) => res.type !== 'year').map((item: any) => {
                      return (
                        <Button
                          btntype="button"
                          id="button"
                          text={item.name}
                          addClass={`graph-btn btn-percent  ${chartType === item.type ? "default-btn-active" : ""
                            }`}
                          onClick={(e: any) => {
                            setLoading(true);
                            setChartType(item.type);
                          }}
                          disabled={chartType === item.type ? true : false}
                        />
                      );
                    })
                    )

                    : (btnWeek.map((item: any) => {
                      return (
                        <Button
                          btntype="button"
                          id="button"
                          text={item.name}
                          addClass={`graph-btn btn-percent  ${chartType === item.type ? "default-btn-active" : ""
                            }`}
                          onClick={(e: any) => {
                            setLoading(true);
                            setChartType(item.type);
                          }}
                          disabled={chartType === item.type ? true : false}
                        />
                      );
                    })
                    )}
              </div>
            </section>
            <br />
            <br /> <br />
            {/* Start Data display  */}
            <div className="scroll-date">
              <section className="wrapper-date-group">
                <Button
                  btntype="button"
                  id="button"
                  text={
                    chartType === "day"
                      ? "Time"
                      : chartType === "year"
                        ? "Week"
                        : "Date"
                  }
                  addClass="btn-withing cursor-set"
                />
                {xValues.map((res: any) => {
                  return (
                    <Button
                      btntype="button"
                      id="button"
                      text={res}
                      addClass="btn-withing cursor-set btn-active"
                    />
                  );
                })}
              </section>
              {(feildType !== "heartrate" ||
                (feildType === "heartrate" && chartType === "day")) && (
                  <section className="wrapper-date-group">
                    <Button
                      btntype="button"
                      id="button"
                      text={
                        feildType === "sleep"
                          ? "Awake - (hours)"
                          : feildType === "heartrate"
                            ? "Average Heart Rate (beats/minute)"
                            : feildType === 'calories'
                              ? "Calories (kcal)"
                              : "Steps (steps)"
                      }
                      addClass="btn-withing cursor-set btn-percent btn-max-width"
                    />
                    {feildyValues.map((res: any) => {
                      return (
                        <Button
                          btntype="button"
                          id="button2"
                          text={res}
                          addClass="btn-withing cursor-set btn-percent"
                        />
                      );
                    })}
                  </section>
                )}

              {feildType === "sleep" && (
                <>
                  <section className="wrapper-date-group">
                    <Button
                      btntype="button"
                      id="button2"
                      text="Light-(hours)"
                      addClass="btn-withing cursor-set btn-percent"
                    />
                    {chartName.LightSleep.map((res: any) => {
                      return (
                        <Button
                          btntype="button"
                          id="button3"
                          text={res}
                          addClass="btn-withing cursor-set btn-percent"
                        />
                      );
                    })}
                  </section>

                  <section className="wrapper-date-group">
                    <Button
                      btntype="button"
                      id="button"
                      text="Deep/REM -(hours)"
                      addClass="btn-withing cursor-set btn-percent"
                    />
                    {chartName.DeepSleep.map((res: any) => {
                      return (
                        <Button
                          btntype="button"
                          id="button"
                          text={res}
                          addClass="btn-withing cursor-set btn-percent"
                        />
                      );
                    })}
                  </section>
                </>
              )}

              {feildType === "heartrate" && chartType !== "day" && (
                <>
                  <section className="wrapper-date-group">
                    <Button
                      btntype="button"
                      id="button2"
                      text="Max HR - (beats)"
                      addClass="btn-withing cursor-set btn-percent"
                    />
                    {hearRate.MaxHeartRate.map((res: any) => {
                      return (
                        <Button
                          btntype="button"
                          id="button3"
                          text={res}
                          addClass="btn-withing cursor-set btn-percent"
                        />
                      );
                    })}
                  </section>

                  <section className="wrapper-date-group">
                    <Button
                      btntype="button"
                      id="button"
                      text="Min HR -(beats)"
                      addClass="btn-withing cursor-set btn-percent"
                    />
                    {hearRate.MinHeartRate.map((res: any) => {
                      return (
                        <Button
                          btntype="button"
                          id="button"
                          text={res}
                          addClass="btn-withing cursor-set btn-percent"
                        />
                      );
                    })}
                  </section>
                </>
              )}
            </div>
            {/* <div className="h-12" /> */}
            {/* <section className="btn-wrap-arrow">
              <div className="btn-arrow-blk">
                <div className="previous-btn">
                  <Button
                    btntype="button"
                    id="draft"
                    text="Previous week"
                    addClass="primary-btn btn-padding btn-diary left-side-arrow"
                    onClick={console.log("button")}
                    rightarrow={true}
                  />
                </div>
                <div className="next-btn">
                  <Button
                    btntype="button"
                    id="draft"
                    text="Next week"
                    addClass="primary-btn btn-padding btn-diary right-side-arrow"
                    onClick={console.log("button")}
                    rightarrow={true}
                  />
                </div>
              </div>
            </section> */}
          </div>
        </section>
      </main>
    </Layout>
  );
};
