import React,{useEffect, useState} from 'react';
import "../styles/PrimaryBar.css";
// import NavBar from "../../Data/PrimaryBarData";
import { Link, useLocation } from 'react-router-dom';
import { useSearchParams ,useNavigate} from 'react-router-dom';
import useAuthContext from '../../context/providers/AuthContext';


interface PrimaryBarProps {

}

export const PrimaryBar: React.FC<PrimaryBarProps> = () => {
    const [path, setPath] = useState('');
    let [searchParams, setSearchParams] = useSearchParams();
    const { state: { user ,participant} } = useAuthContext();
 
    let patientId:any= searchParams.get('patientId');
    const tabIndex = 0;
    const location = useLocation();



  useEffect(() => {
    if(location.pathname.includes('/withings/ecg')){
        setPath('/withings/ecg')
    } else if (location.pathname.includes('/withings/spo')){
        setPath('/withings/spo')
    }
    else if (location.pathname.includes('/withings/passive')){
        setPath('/withings/passive')
    } else {
        setPath('/withings')
    }
  }, []);

  
    let NavBar = [
        {
            title: "Dashboard",
            link: user&&user.userRole.roleInfo.id === 3 ? '/dashboard':'/cliniciandashboard',
            // link: '/cliniciandashboard',
            isActive: false,
        },
        {
            title: "Food Diary",
            link: patientId ?'/fooddiary/question/overview' : '/fooddiary',
            isActive: false,
        },
        {
            title: "Questionnaires",
            link: '/questionnaires',
            isActive: false,
        },
        {
            title: "Withings",
            link: path,
            isActive: false,
        },      
    ];

        if(patientId && patientId !=='null'){
             NavBar.map((list:any)=>{
                list.newlink = list.link+'?patientId='+ ( (participant && participant.value) ? (participant && participant.value) :patientId);
                return list;
            })
        }
   
        if(location.pathname.includes('/cliniciandashboard') && !location.pathname.includes('/cliniciandashboard/patient')){
                NavBar= NavBar.filter((res:any)=>res.link ==='/cliniciandashboard')
        }else if((!location.pathname.includes('/cliniciandashboard')) && (!searchParams.get('patientId') || searchParams.get('patientId') === 'null')){
                  NavBar=  NavBar.filter((res:any)=>res.link !=='/cliniciandashboard')
        }
   
       if(user?.userRole.roleInfo.id === 1){
           NavBar = [
              {
                  title: "Admin Dashboard",
                  link: '/admindashboard',
                  isActive: true,
              },
          ];
       }
    return (
        <nav className='primary-nav'>
            <div className='nav-list'>
                {NavBar && NavBar.map((item: any, idx: number) => {
                    return (
                        <React.Fragment key={idx}>
                            <Link
                                tabIndex={tabIndex}
                                key={item.link}
                                className={`nav-item ${location.pathname.includes(item.link) ? 'active' : ''
                                    }`}
                                to={item.newlink ? item.newlink : item.link}
                            >
                                {item.title}
                            </Link>
                        </React.Fragment>
                    );
                })}
            </div>
        </nav>
    );
}