import React, { useEffect, useState } from "react";
import "../styles/Accordion.css";
import moment from "moment";

import { ReactComponent as Dropdownicon } from "../../assets/svg/Dropdown.svg";

interface AccordionProps {
  head: string;
  content?: string;
  children: any;
  addclass?: string;
  SelectSession?:any;
  list?:number;
  showAccordion?:boolean;
  NT_CMP_Session?:any;
  CMP_Session?:any;
  showPopup?:any
  type?:string;
  show_accordion?:boolean
  name?:string
}

export const Accordion: React.FC<AccordionProps> = ({
  head,
  content,
  children,
  addclass,
  SelectSession,
  list,
  showAccordion,
  NT_CMP_Session,
  CMP_Session,
  showPopup,
  type,show_accordion,name
}) => {
  let tabIndex = 0;
  if(addclass && addclass.includes('accordion-disabale')){
  tabIndex = -1
  }
  let time:any = process.env.REACT_APP_FD_NEXT_START_TIME_QB || 1;
  let types:any= process.env.REACT_APP_FD_NEXT_START_TYPE || 'month'; 
   const [showAccordions, setAccordion] = useState(showAccordion);
   useEffect(()=>{
    console.log('showAccordion--',showAccordion);

    if(name && name === 'import'){
      displayAccordion()
    }
   },[showAccordion])
  const displayAccordion = () => {
    showAccordions=== true ? setAccordion(false) : setAccordion(true)
  }
  return (
    <div className="accordion-wrap">
      <button
        type="button"
        tabIndex={tabIndex}
        className={`accordion-head ${addclass}`}
        aria-expanded={showAccordions ? "true" : "false"}
        onClick={() =>{
          if(type ==='questionnaire'){
            const last:any = Math.max(...CMP_Session.map((o:any) => o.questionnaire_session));
            const lastEntry = CMP_Session.find((element:any) => parseInt(element.questionnaire_session) === last);
            if(NT_CMP_Session.length <=0 && CMP_Session.filter((res:any)=>parseInt(res.questionnaire_session) === list).length === 0){
              console.log('if show popup');
  
              let date = lastEntry.session_completed_day;
              const startDate = moment(date).add(time,types).toDate();
              console.log(new Date(startDate).getTime() >= new Date().getTime(),'------------get diffferent');
              
              if( new Date(startDate).getTime() >= new Date().getTime() ){
                showPopup(startDate, date, list)
                // SelectSession(list)
              }else{
                if(list){
                  SelectSession(list)
               }
                 displayAccordion()
              }
            }else{
                console.log('else show popup');
              if(list){
                SelectSession(list)
             }
               displayAccordion()
            }
          }else{
            displayAccordion()
          }  
        }
        }
      >
        {head}

        {showAccordions ? (      
          <div className="acc-up-arrow">
            <Dropdownicon />
          </div>
        ) : (      
          <div className="acc-down-arrow">
            <Dropdownicon />
          </div>
        )}
      </button>
      {showAccordions && (
        <div>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
};
