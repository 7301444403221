import React from 'react';
import "../styles/CheckBox.css";

interface CheckBoxProps {
    disabled?: boolean;
    label?: string;
    name?: string;
    type: string;
    // onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onChange?: any;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    errors?: string | null;
    addClass?: string;
    onlycheckbox?: boolean;
    selectedValue?: boolean;
    children?: any;
    id?: string;
    value?:any;
    multichoice?:any;
    answer_uuid?:any,
    arrayindex?:any
    checked?:any
}

export const CheckBox: React.FC<CheckBoxProps> = (
    {id,
        checked,
        label,
        onChange,
        name,
        type,
        errors,
        onBlur,
        addClass,
        onlycheckbox,
        selectedValue,
        children,
        disabled,
        value,
        multichoice,
        answer_uuid,
        arrayindex,
        
    }) => {
        const tabIndex = 0;

    return (
        <div>
            <label           
                className={`checkbox-group text-left ${addClass || ''} ${checked === true ? 'active ' : ''
                    }${onlycheckbox && 'onlycheckbox'}`}
            >
                {label}
                <input 
                    tabIndex={tabIndex}
                    type={type}
                    name={name}
                    onChange={()=>multichoice(value, id, answer_uuid,arrayindex)}
                    onBlur={onBlur}
                    value={value}
                    checked={checked}
                    disabled={disabled}
                />
                <span className="checkmark" />
                {children}
            </label>
            {errors && <div className="form-error-msg">{errors}</div>}
        </div>
    );
}