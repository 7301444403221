import axios, { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode';

// import { history } from '../utils/history';
import appUrls from './AppURLs';

// axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response:any) =>
    new Promise((resolve, reject) => {
      resolve(response);     
    }),
  (error:any) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }    
    if (error.response.status === 401 && (error.response.config && error.response.config.url && error.response.config.url.split('/').pop() !=='login')) {
      localStorage.clear();
        window.location.href = `${appUrls.user.login}?refresh=true`;
    } 
    else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export function setToken(token: string | null, refreshToken: string | null) {
  if (token) {
    // localStorage.setItem('access-token', token);   
    // && refreshToken localStorage.setItem('refresh-token', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

export function removeToken(token: string | null, refreshToken: string | null) {
  if (token) {
    localStorage.removeItem('access-token');   
  } else {
    localStorage.clear();
    }
}
function getHeaders() {
  const accessToken = localStorage.getItem('access-token')
  const cookies = document.cookie;
  console.log('Cookies ', cookies)

  let headers: any = {
    withCredentials: true,
    headers: {}
  }
  if (accessToken) {
    headers = {
      headers: {
        Authorization: 'Bearer ' + accessToken,        
      }, 
    }
  }
  // if (cookies) {
  
  //     // @ts-ignore
  //     headers = {
  //       headers: {
  //         Cookie: cookies,        
  //       }
  //   };
  // }
  
  return headers;
}
// Promise<AxiosResponse['data']>

const apiFunctions = {
  get: async (url: string): Promise<AxiosResponse['data']> => {
    return await axiosInstance.get(url, {withCredentials: true})
  },
  post: async (url: string, data: object): Promise<AxiosResponse['data']> => {    
    return await axiosInstance.post(url, data, {withCredentials: true})
  },
  put: async (url: string, data?: object): Promise<AxiosResponse['data']> => {
    return axiosInstance.put(url, data, {withCredentials: true});
  },
  delete: async (url: string) : Promise<AxiosResponse['data']> => {
    return await axiosInstance.delete(url, {withCredentials: true})
  }
}

export function isTokenValid(token: string) {
  try {
    const decoded_jwt: any = jwtDecode(token)
    return decoded_jwt ? decoded_jwt : false // unlimited expiry
  } catch (error) {
    return false
  }
}

// export default axios;
export default apiFunctions
