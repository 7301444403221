import React ,{useEffect,useState} from 'react';

import {Navigate ,Outlet} from 'react-router-dom';
import {  checkUserAuthentication,} from '../services/api/AuthAPI';

export default function ProtectedRoutes() {

    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData]=useState<any>();
    useEffect(() => {
        fetchData();
      }, []);

      async function fetchData() {
        try {
          const response = await checkUserAuthentication();    
          console.log(response.data.result,'======response.data.result');
          
          setUserData(response.data.result);
          setIsLoading(false);

        } catch (error) {
          setIsLoading(false);
          console.error(error);
        }
      };

      if (isLoading) {
        // Display a loading indicator while waiting for the API response
        return <div>Loading...</div>;
      }else{    
          const auth = userData && userData.accessToken ? true : false; // determine if authorized, from context or however you're doing it
        // If authorized, return an outlet that will render child elements
        // If not, return element that will navigate to login page
        return (userData != undefined && auth) ? <Outlet /> : <Navigate to="/user/login" />;
      }

}

