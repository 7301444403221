import React, { Fragment, useState, useEffect } from "react";
import { ReactComponent as SalfordLogo } from "../assets/svg/SalfordLogo.svg";
import { ReactComponent as NCAlliance } from "../assets/svg/NorthernCareAlliance.svg";
import { Button } from "../components/Button/Button";
import { TextInput } from "../components/TextInput/TextInput";
import "../styles/Login.css";
import apiFunctions from "../services/api-service";
import appUrls from "../services/AppURLs";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import Loading from "../components/Loading/Loading";
// import {useParams} from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import validationErrors from "../services/ValidationSchema";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";

interface ResetPasswordProps {}

const defaultFormVal: any = {
  password: "",
  confirm_password: "",
};

const rejectReasonSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, validationErrors[1005])
    .required(validationErrors[1030]),
  confirm_password: Yup.string()
    .required(validationErrors[1031])
    .oneOf([Yup.ref("password")], validationErrors[1006]),
});

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const [changeType, setChangeType] = useState("password");
  const [showPassword, setShowPassword] = useState("password");

  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  useEffect(() => {}, []);

  const formik = useFormik({
    initialValues: defaultFormVal,
    validationSchema: rejectReasonSchema,

    onSubmit: async (values: any) => {
      const payload: any = { ...values };
      ResetUser(payload);
    },
  });

  const ResetUser = (payload: any) => {
    setLoading(true);
    payload.token = token;
    try {
      apiFunctions
        .post(
          "/api/auth/reset-password",
          payload
        )
        .then(async (_result) => {
          setLoading(false);
          SetPopupcontent({
            status: "Success!",
            message: "Your password was reset successfully.",
          });
          setPopup(true);
          setHidepopup(false);
          setDialogOpen(true);
          //   window.location.href = appUrls.user.home;
        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
          SetPopupcontent({
            status: "Failed!",
            message: err.response.data.msg
              ? err.response.data.msg
              : err.response.data.error.message,
          });
          setPopup(true);
          setHidepopup(true);
          setDialogOpen(true);
        });
    } catch (error: any) {
      console.log(error.response, "errorresposne");
      setLoading(false);
      SetPopupcontent({
        status: "Failed!",
        message: error.response.data.error.message,
      });
      setPopup(true);
      setHidepopup(true);
      setDialogOpen(true);
    }
  };

  return (
    <Fragment>
      <div className="main-section">
        <div className="block-center">
          <form action="submit" className="" onSubmit={formik.handleSubmit}>
            <span>
              <SalfordLogo />
            </span>
            <br />
            <br />
            <TextInput
              id="password"
              name="password"
              type={changeType}
              placeholder="Enter your password"
              icon="password"
              onIconClick={(e) =>
                changeType === "password"
                  ? setChangeType("text")
                  : setChangeType("password")
              }
              //   onChange={handleChange}
              onChange={formik.handleChange}
              errors={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
              values={formik.values.password}
            />
            <br />
            <TextInput
              id="confirm_password"
              name="confirm_password"
              type={showPassword}
              //   onChange={handleChange}
              placeholder="Re-enter your password"
              icon="password"
              onIconClick={(e) =>
                showPassword === "password"
                  ? setShowPassword("text")
                  : setShowPassword("password")
              }
              onChange={formik.handleChange}
              values={formik.values.confirm_password}
              errors={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
                  ? formik.errors.confirm_password
                  : null
              }
            />
            <br />
            <div className="wrap-content-center">
              <Button
                btntype="submit"
                text="Submit"
                addClass="primary-btn btn-login"
                // onClick={ResetUser}
                onClick={() => {}}
              />
            </div>
          </form>
        </div>

        <Loading status={loading} />
      </div>
      <div className="block-bottom">
        <NCAlliance tabIndex={0} aria-label="NHS FOOTER LOGO"/>
      </div>

      <div>
        <DialogPopup
          open={dialogOpen}
          onRequestClose={() => {
            setDialogOpen(!dialogOpen);
          }}
          showIcon
          closeOnOutsideClick
        >
          <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            redirectTo={appUrls.user.login}
            hidePopup={hidepopup}
            setPopup={setPopup}
            onRequestClose={() => {
              setDialogOpen(!dialogOpen);
            }}
          />
        </DialogPopup>
      </div>
    </Fragment>
  );
};
