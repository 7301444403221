import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/SecondaryBar.css";
import { useSearchParams ,useNavigate} from 'react-router-dom';

interface SecondaryBarProps {}

export const SecondaryBar: React.FC<SecondaryBarProps> = () => {
let [searchParams, setSearchParams] = useSearchParams();
let patientId:any= searchParams.get('patientId');
  const tabIndex = 0;
  const location = useLocation();
  const NavBar = [
        {
            title: "ECG",
            link: '/withings/ecg',
            isActive: false,
        },
        {
            title: "SpO2",
            link: '/withings/spo',
            isActive: false,
        },
        {
            title: "Passive data",
            link: '/withings/passive',
            isActive: false,
        },
    ];
    
    const [NavBarList, setNavBarList] = useState<any>(NavBar);
    if(patientId && patientId !=='null'){
        NavBar.map((list:any)=>{
           list.newlink = list.link+'?patientId='+patientId
           return list;
       })
   }
    
  return (
    <nav className='secondary-nav'>
            <div className='sec-nav-list'>
                {NavBarList && NavBarList.map((item: any, idx: number) => {
                    return (
                        <React.Fragment key={idx}>
                            <Link
                                tabIndex={tabIndex}
                                key={item.link}
                                className={`sec-nav-item ${location.pathname.includes(item.link) ? 'active' : ''
                                    }`}
                                to={item.newlink ? item.newlink : item.link}
                            >
                                {item.title}
                            </Link>
                        </React.Fragment>
                    );
                })}
            </div>
        </nav>
  );
};
