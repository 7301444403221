import React from 'react'
import { Layout } from '../components/Layout/Layout';

interface CookiesProps {

}

export const Cookies: React.FC<CookiesProps> = ({ }) => {
    return (
        <Layout>
            <div >Cookies </div>
        </Layout>
    );
}