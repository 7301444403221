import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../assets/svg/backarrow.svg";

interface BackArrowIconProps {
  title?: string;
  toUrl?: any
}

export const BackArrowIcon: React.FC<BackArrowIconProps> = ({ title ,toUrl}) => {
  return (
    <section>
      <Link to={toUrl} className="back-arrow">
        <div>
          <BackArrow />
        </div>
        <p>{title}</p>
      </Link>
    </section>
  );
};
