import React from 'react';
import "../styles/QbCard.css";
import appUrls from '../../services/AppURLs'
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';

interface PatientCardProps {
    questions?: any
}

export const PatientCard: React.FC<PatientCardProps> = ({
    questions,
}) => {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

const goToDashboard = (data:any)=>{
    if(data.title === 'food-diary'){
        navigate(appUrls.fooddiaryoverview +'?patientId='+data.uuid);
    }
    if(data.title === 'questionnaire'){
        navigate(appUrls.questionnaires +'?patientId='+data.uuid);
    }
    if(data.title === 'withings'){
        navigate(appUrls.withings +'?patientId='+data.uuid);
    }
}

    return (
        <main>
            <section>
                
                <div className='qb-card-group'>
                    {questions && questions.map((qs: any, index: any) => {
                        return (
                            <button tabIndex={0} className='qb-card' key={index} onClick={() => goToDashboard(qs)} id={index}>
                                <div className='card-info'>
                                    <div className='card-head-block'>
                                        <h5>{qs.questionName}</h5>
                                    </div>
                                    <div className='output-group'>
                                        <p>Category:</p>
                                        <p>{qs.questionSetCategory}</p>
                                    </div>
                                </div>
                            </button>
                        )
                    })
                    }
                </div>

            </section>
        </main>
    );
}