import React from 'react';
import { AuthContextProvider } from './context/providers/AuthContext';
import AppRoute from './routes';

function App() {
  return (
    <div>
      <AuthContextProvider>
        <AppRoute />
      </AuthContextProvider>
    </div>
  );
}

export default App;
