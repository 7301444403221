import React, { useState, useEffect } from "react";
import "../styles/FoodDiaryQuestion.css";
// import { Accordion } from "../components/Accordion/Accordion";
import moment from "moment";
import { Layout } from "../components/Layout/Layout";
import { SelectDropdown } from "../components/SelectDropdown/SelectDropdown";
import TextArea from "../components/TextInput/TextArea";
import { Button } from "../components/Button/Button";
// import { ReactComponent as Icon } from "../assets/svg/left-icon.svg";
import { useSearchParams, useNavigate, } from "react-router-dom";
import apiFunctions from "../services/api-service";
import appUrls from "../services/AppURLs";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import Loading from "../components/Loading/Loading";
// import { FoodDiary } from "./FoodDiary";
import { BackArrowIcon } from "../components/BackArrow/BackArrowIcon";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";

interface FoodDiaryQuestionProps {}

export const FoodDiaryQuestion: React.FC<FoodDiaryQuestionProps> = ({}) => {
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [Popup, setPopup] = useState(false)
  const [loading, setLoading] = useState(true)
  const [hidepopup, setHidepopup] = useState(false)
  const [PopContent, SetPopupcontent] = useState<any>([])
  const [sessionInfo, setSessionInfo] = useState<any>({});
  const [popupType, setPopupType]=useState('')
  const [dialogOpen, setDialogOpen] = useState(false);

  const [FoodDiaryData, setFoodDiaryData] = useState<any>({
    fooddrinksupplement: '',
    when: '',
    what: '',
    quantity: '',
    description: ''
  })

const [redirectUrl, setRedirectUrl] = useState('')
  const FoodList = [
    {
      name: "Food",
      id: 0,
    },
    {
      name: "Drink",
      id: 1,
    },
    {
      name: "Supplement",
      id: 2,
    },
  ];
  const BreakTime = [
    {
      name: "Breakfast",
      id: 0,
    },
    {
      name: "Mid-Morning Snack",
      id: 1,
    },
    {
      name: "Lunch",
      id: 2,
    },
    {
      name: "Mid-afternoon Snack",
      id: 3,
    },
    {
      name: "Dinner",
      id: 4,
    },
    {
      name: "Snack anytime",
      id: 5,
    },
  ];
  const placeholder = [
    {
      name: `Please provide a description of what you ate, for example:
        -  corn flakes with oat milk and sugar
        -  toast with butter and jam
        -  a banana`,
      value: "Breakfast",
    },
    {
      name: `Please provide a description of what you ate, for example:
        -  corn flakes with oat milk and sugar
        -  toast with butter and jam
        -  a banana`,
      value: "Mid-Morning Snack",
    },
    {
      name: `Please provide a description of what you ate, for example:
        -  Tuna sandwich
        -  Tomato soup with buttered bread`,
      value: "Lunch",
    },
    {
      name: `Please provide a description of what you ate, for example:
        -  Tuna sandwich
        -  Tomato soup with buttered bread`,
      value: "Mid-afternoon Snack",
    },
    {
      name: `Please provide a description of what you ate, for example:
        -  Pepperoni pizza and salad
        -  Roast chicken, roast potatoes, steamed broccoli`,
      value: "Dinner",
    },
    {
      name: `Please provide a description of what you ate, for example:
        -  Pepperoni pizza and salad
        -  Roast chicken, roast potatoes, steamed broccoli`,
      value: "Snack anytime",
    },
  ];
  const quantityPlaceholder = [
    {
      name: `Please provide a description of how much you ate, for example:
        -  2 handfuls of cornflakes and 2 cups of oat milk
        -  a slice of bread
        -  a large banana`,
      value: "Breakfast",
    },
    {
      name: `Please provide a description of how much you ate, for example:
        -  2 handfuls of cornflakes and 2 cups of oat milk
        -  a slice of bread
        -  a large banana`,
      value: "Mid-Morning Snack",
    },
    {
      name: `Please provide a description of how much you ate, for example:
        -  2 slices of bread, 4 spoonfuls of tuna, 2 lettuce leaves
        -  3 cups of soup, 2 slices of bread, 1 spoonful of butter`,
      value: "Lunch",
    },
    {
      name: `Please provide a description of how much you ate, for example:
        -  2 slices of bread, 4 spoonfuls of tuna, 2 lettuce leaves
        -  3 cups of soup, 2 slices of bread, 1 spoonful of butter`,
      value: "Mid-afternoon Snack",
    },
    {
      name: `Please provide a description of how much you ate, for example:
        -  3 medium slices of pizza and a bowl of salad (about 3 handfuls)
        -  2 slices of chicken breast, 4 handfuls of potatoes, 1 handful of broccoli`,
      value: "Dinner",
    },
    {
      name: `Please provide a description of how much you ate, for example:
        -  3 medium slices of pizza and a bowl of salad (about 3 handfuls)
        -  2 slices of chicken breast, 4 handfuls of potatoes, 1 handful of broccoli`,
      value: "Snack anytime",
    },
  ];
  const descriptionPlaceholder = [
    {
      name: `Please provide a description of what you ate, the more detail the better! For example:
        -  Kellogs multigrain corn flakes with one spoonful of sugar and Alpro oat milk
        -  Hovis seeded whole grain bread with dairy free butter, and low sugar Sainsbury’s strawberry jam`,
      value: "Breakfast",
    },
    {
      name: `Please provide a description of what you ate, the more detail the better! For example:
        -  Kellogs multigrain corn flakes with one spoonful of sugar and Alpro oat milk
        -  Hovis seeded whole grain bread with dairy free butter, and low sugar Sainsbury’s strawberry jam`,
      value: "Mid-Morning Snack",
    },
    {
      name: `Please provide a description of what you ate, the more detail the better! For example:
        -  Waitrose wholemeal loaf, waitrose deli sandwich filler tuna spread (with mayonnaise and corn) and Roman lettuce
        -  Sainsbury’s ready made cherry tomato soup, Waitrose wholemeal loaf, Lurpak butter`,
      value: "Lunch",
    },
    {
      name: `Please provide a description of what you ate, the more detail the better! For example:
        -  Waitrose wholemeal loaf, waitrose deli sandwich filler tuna spread (with mayonnaise and corn) and Roman lettuce
        -  Sainsbury’s ready made cherry tomato soup, Waitrose wholemeal loaf, Lurpak butter`,
      value: "Mid-afternoon Snack",
    },
    {
      name: `Please provide a description of what you ate, the more detail the better! For example:
        -  Papa John’s Pepperoni pizza with mozzarella cheese and tomato sauce, with Roman lettuce with olive oil and balsamic vinegar dressing
        -  Sainsbury’s roasted chicken, new potatoes and steamed broccoli `,
      value: "Dinner",
    },
    {
      name: `Please provide a description of what you ate, the more detail the better! For example:
        -  Papa John’s Pepperoni pizza with mozzarella cheese and tomato sauce, with Roman lettuce with olive oil and balsamic vinegar dressing
        -  Sainsbury’s roasted chicken, new potatoes and steamed broccoli `,
      value: "Snack anytime",
    },
  ];
  // Get selected day

  const btnGroup = [
    {
      name: "Day 1 - Friday",
      day: 1,
    },
    {
      name: "Day 2 - Saturday",
      day: 2,
    },
    {
      name: "Day 3 - Sunday",
      day: 3,
    },
    {
      name: "Day 4 - Monday",
      day: 4,
    },
    {
      name: "Day 5 - Tuesday",
      day: 5,
    },
  ];
  let day = btnGroup.filter((res: any) => res.day == searchParams.get("day"));

  // Here change and get place holder text
  const getPlacevalue = (id: any) => {
    let val = placeholder.filter((res: any) => res.value === id);
    return val[0] ? val[0].name : "What food did you have?";
  };
  const getQuantityPlacevalue = (id: any) => {
    let val = quantityPlaceholder.filter((res: any) => res.value === id);
    return val[0] ? val[0].name : "In what quantity did you have the food?";
  };
  const descriptionPlacevalue = (id: any) => {
    let val = descriptionPlaceholder.filter((res: any) => res.value === id);
    return val[0] ? val[0].name : "Please provide the details of the food you had.";
  };
  useEffect(() => {
    if (searchParams.get('uuid')) {
      getDiaryById();
    }
  }, [])
  async function getDiaryById() {
    setLoading(true);
    try {
      let URL =
        
        `/api/fooddiary/get-record/${searchParams.get("uuid")}`;
      await apiFunctions
        .get(URL,)
        .then((_result) => {
          let result = _result.data.result[0]
          setFoodDiaryData({
            fooddrinksupplement: result.food_drink_supplement,
            when: result.when,
            what: result.what,
            quantity: result.quantity,
            description: result.discription
          })
          setLoading(false);

        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        })
    } catch (error: any) {
      setLoading(false);
      console.log(error.response, 'errorresposne')
    }
  }
  useEffect(() => {
    getUserSession();
  }, []);
  async function getUserSession() {
    try {
      let URL =  `/api/fooddiary/session`;
      await apiFunctions
        .get(URL)
        .then((_result) => {
          let result = _result.data.result;
              setSessionInfo({ ...result });
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)

          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false)

      console.log(error.response, "errorresposne");
    }
  }
  //Save record and add another entry
  async function saveAndAddEntry(type: any) {
    setLoading(true);
    // setDialogOpen(!dialogOpen)
    let URL;
    let method;
    

    let diaryPayload:any = {
      food_drink_supplement: FoodDiaryData.fooddrinksupplement,
      when: FoodDiaryData.when,
      what: FoodDiaryData.what,
      quantity: FoodDiaryData.quantity,
      discription: FoodDiaryData.description,
      day: searchParams.get('day'),
      session: searchParams.get('session'),
      session_status: type === "session-submited" ? 'completed' : 'not-completed',
    }
    
    if (searchParams.get('uuid')) {
      URL = `/api/fooddiary/update/${searchParams.get('uuid')}`;
      method = apiFunctions.put;
    } else {
      URL = `/api/fooddiary/add`;
      method = apiFunctions.post;
    }
    try {
      await method(URL, diaryPayload)
        .then((_result:any) => {
          if (type === 'goToOverview') {
            navigate(appUrls.fooddiaryoverview +`?session=${diaryPayload.session}&day=${diaryPayload.day}`)
          } else if (type === 'session-submited') {
            // navigate(appUrls.fooddiary)
            const dataEntry =(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what.length > 1 && FoodDiaryData.quantity.length  && FoodDiaryData.description.length > 1) ? true :false;
            setDialogOpen(dialogOpen)
            let Message1= 'You have completed all your Food Diary sessions';
            let Message2= `Your session has been ${dataEntry ? 'saved and ':''}completed successfully.`;
            SetPopupcontent({ status: diaryPayload.session === 3 ?'Congratulations!!' : 'Success!', 
            message: diaryPayload.session === 3 ? Message1 : Message2
           })
            setPopup(true)
            setHidepopup(false)
            setRedirectUrl(appUrls.fooddiary)
            setPopupType("saved")
            setDialogOpen(true)
          } else {
            getUserSession();
            setFoodDiaryData({
              fooddrinksupplement: '',
              when: '',
              what: '',
              quantity: '',
              description: ''
            })
            setDialogOpen(dialogOpen)
            SetPopupcontent({ status: 'Success!', message: `Your entry has been ${searchParams.get('uuid')?'updated.' :'saved.'}` })
            setPopup(true)
            setDialogOpen(true)

            setRedirectUrl( searchParams.get('uuid')? appUrls.diaryquestions + `?session=${diaryPayload.session}&day=${diaryPayload.day}&date=${searchParams.get('date')}`:"")
            setHidepopup(false)
            setPopupType("")
          }
          setLoading(false);
        })
        .catch((err:any) => {
          setDialogOpen(dialogOpen)
          setLoading(false);
          SetPopupcontent({ status: "Failed", message: err.msg });
          setPopup(true);
          setHidepopup(true);
          setDialogOpen(true)
          console.log("error", err);
        });
    } catch (error: any) {
      setDialogOpen(dialogOpen)
      setLoading(false);
      SetPopupcontent({ status: "Failed", message: error.msg });
      setPopup(true);
      setDialogOpen(true)
      setHidepopup(true);
      console.log(error.response, "errorresposne");
    }
  }
  // Session completed popup
  const submitPopup = () => {
   let time:any = process.env.REACT_APP_FD_NEXT_START_TIME || 1;
   let types:any= process.env.REACT_APP_FD_NEXT_START_TYPE || 'days';  
   const startDate = moment().add(time,types).format("DD/MM/YYYY");
    // FoodDiaryData.fooddrinksupplement
   const dataEntry =(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what.length > 1 && FoodDiaryData.quantity.length  && FoodDiaryData.description.length > 1) ? true :false;
    // const startDate = moment(searchParams.get("date")).add(1, 'months').format("DD/MM/YYYY")
    let Message1=`You cannot edit or view the data after submitting the session. Do you still want to complete this session?`;
    // let Message2 = `You cannot edit or view the data after submitting the session. Do you still want to complete this session?`;
    let Message2=`You haven't filled in any data. Do you still want to complete this session?`;
    // let Message4 = `You haven't filled in any data. Do you still want to complete this session?`
    let Message3= `Your next session starts after 3 months (${startDate}).`;
    let Message4=`This your last Food Diary session.`
    setLoading(false);
    SetPopupcontent({ status: 'Note!',
     message: ((sessionInfo && sessionInfo.diaryRes && sessionInfo.diaryRes.count > 0)|| dataEntry)? Message1 : Message2,
     message1: (searchParams.get('session') !== '3')? Message3: Message4})
    setPopup(true)
    setDialogOpen(true)
    setHidepopup(true)
    setPopupType("session-submited")
  }
  
  return (
    <Layout>
      <main className="main-container" id="save_entry">
        <section className="outer-container">
          <div className="inner-container">
            <section>
             <BackArrowIcon title="Back to food entries" toUrl={appUrls.fooddiary} />           
            </section>
            <br />
            <section>
              <h5 tabIndex={0}>Session {searchParams.get("session")}</h5>
              <br />
              <div className="btn-group-item">
                <div>
                  <h5 tabIndex={0}>{day && day[0] && day[0].name} - {searchParams.get('date')}</h5>
                </div>
                <div>
                </div>
              </div>
            </section>
            <br />
            <section className="food-dropdown">
              <div className="diary-header">
                <h5 tabIndex={0}>Food, drink and/or supplement</h5>
              </div>
              <SelectDropdown
                type="text"
                name="center"
                label=""
                placeholder="Select food type"
                optionsArray={FoodList.map((item: any) => {
                  return { value: item.name, label: item.name };
                })}
                onChange={(e: any) => {
                  setFoodDiaryData((prevState: any) => ({
                    ...prevState,
                    fooddrinksupplement: e.value,
                  }));
                }}
                value={FoodDiaryData.fooddrinksupplement}
              />
            </section>
            <br />
            <br />
            <section className="food-dropdown">
              <div className="diary-header">
                <h5 tabIndex={0}>When</h5>
              </div>
              <SelectDropdown
                type="text"
                name="center"
                label=""
                placeholder="Select food time"
                optionsArray={BreakTime.map((item: any) => {
                  return { value: item.name, label: item.name };
                })}
                onChange={(e: any) => {
                  setFoodDiaryData((prevState: any) => ({
                    ...prevState,
                    when: e.value,
                  }));
                }}
                value={FoodDiaryData.when}
              />
            </section>
            <br />
            <br />
            <section>
              <div className="diary-header">
                <h5 tabIndex={0}>What </h5>
              </div>
              <TextArea
                placeholder={getPlacevalue(FoodDiaryData.when)}
                onChange={(e: any) => {
                setFoodDiaryData((prevState: any) => ({
                    ...prevState,
                    what: e.target.value
                  }));
                }}
                values={FoodDiaryData.what}
              />
            </section>
            <br />
            <br />

            <section>
              {/* <p>Note: Here you can enter 1000 characters only.</p> */}
              <div className="diary-header">
                <h5 tabIndex={0}>Quantity</h5>
              </div>
              <TextArea
                placeholder={getQuantityPlacevalue(FoodDiaryData.when)}
                onChange={(e: any) => {
                  setFoodDiaryData((prevState: any) => ({
                    ...prevState,
                    quantity: e.target.value
                  }));
                }}
                values={FoodDiaryData.quantity}
              />
            </section>
            <br />
            <br />

            <section>
              <div className="diary-header">
                <h5 tabIndex={0}>Description</h5>
              </div>

              <TextArea
                placeholder={descriptionPlacevalue(FoodDiaryData.when)}
                onChange={(e: any) => {
                  setFoodDiaryData((prevState: any) => ({
                    ...prevState,
                    description: e.target.value
                  }));
                }}
                values={FoodDiaryData.description}
              />
            </section>
            <br />
            <br />

            <section>
              <div className="inner-container">
                <a href='#save_entry'>
                  <Button
                    btntype="button"
                    id="draft"
                    text={`${searchParams.get('uuid') ? 'Update' : 'Save'} and add another entry`}
                    addClass={`primary-btn btn-padding w-full ${(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what.length > 1 && FoodDiaryData.quantity.length  && FoodDiaryData.description.length > 1) ? '' : 'disabled'}`}
                    onClick={() => saveAndAddEntry('addNew')}
                    disabled={(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what.length > 1 && FoodDiaryData.quantity.length  && FoodDiaryData.description.length > 1) ? false : true}
                  />
                </a>
                <br />
                <Button
                  btntype="button"
                  id="draft"
                  text={`${searchParams.get('uuid') ? 'Update' : 'Save'} and go to overview`}
                  addClass={`primary-btn btn-padding w-full ${(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what.length > 1 && FoodDiaryData.quantity.length  && FoodDiaryData.description.length > 1) ? '' : 'disabled'}`}
                  onClick={() => saveAndAddEntry('goToOverview')}
                  disabled={(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what.length > 1 && FoodDiaryData.quantity.length  && FoodDiaryData.description.length > 1) ? false : true}
                />
              
                <div>
                  <br />
                  <Button
                    btntype="button"
                    id="draft"
                    text={`${(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what.length > 1 && FoodDiaryData.quantity.length  && FoodDiaryData.description.length > 1) ? 'Save and Complete your session' :'Complete your session'}`}
                    addClass={`primary-btn btn-padding w-full `}
                    // disabled={(FoodDiaryData.fooddrinksupplement && FoodDiaryData.when && FoodDiaryData.what && FoodDiaryData.quantity && FoodDiaryData.description) ? false : true}
                    onClick={() => submitPopup()}
                  />
                </div>
                
              
                <br />
                <Button
                  btntype="button"
                  id="draft"
                  text="Cancel"
                  addClass="primary-btn btn-padding w-full btn-diary"
                  onClick={() => navigate(appUrls.fooddiary)}
                />
              </div>
            </section>
            <br />
            <br />
            <br />

          </div>
        </section>

        <DialogPopup
           open={dialogOpen}
           onRequestClose={() => {
             setDialogOpen(!dialogOpen);          
           }}
           showIcon
           closeOnOutsideClick
           redirectTo={redirectUrl}
           hidePopup={hidepopup}
         > 
         <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            redirectTo={redirectUrl}
            hidePopup={hidepopup}
            setPopup={setPopup}
            saveAndAddEntry={saveAndAddEntry}
            type={popupType}
            onRequestClose={() => {
              setDialogOpen(!dialogOpen);          
            }}
          />
         </DialogPopup>
       
        <Loading status={loading} />
      </main>
    </Layout>
  );
};
