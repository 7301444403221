import React from "react";
import { Layout } from "../components/Layout/Layout";
import "../styles/Term.css";
interface TermsAndConditionProps {}

export const TermsAndCondition: React.FC<TermsAndConditionProps> = ({}) => {
  return (
    <Layout>
      <main className="main-container">
        <section className="outer-container">
          <div className="terms-condition">
            <h4 tabIndex={0}>Terms of Service</h4>
            <br />
            <p tabIndex={0}>
              {" "}
              This statement governs the terms of service between you, the
              patient and Northern Care Alliance NHS Foundation Trust Metabolic
              Medicine platform. By using Northern Care Alliance NHS Foundation
              Trust Metabolic Medicine platform you agree to the following:
            </p>
            <br />
            <h5 tabIndex={0}>Privacy</h5>
            <p tabIndex={0}>
              Your privacy is important to us, for this reason we have designed
              a privacy statement which will outline how we protect your
              privacy. We ask you to read this statement so that you can make an
              informed decision about using Northern Care Alliance NHS
              Foundation Trust Metabolic Medicine platform.
            </p>
            <br />
            <h5 tabIndex={0}>Sharing of Information</h5>
            <p tabIndex={0}>
              Northern Care Alliance NHS Foundation Trust Metabolic Medicine
              platform is designed so you, as the patient, have complete control
              over who you connect with and what information you share with
              them.{" "}
            </p>
            <br />
            <h5 tabIndex={0}>Correspondence</h5>
            <p tabIndex={0}>
              By registering for a Northern Care Alliance NHS Foundation Trust
              Metabolic Medicine platform account and allowing your care team/s
              to be part of your community, you are agreeing that all
              correspondence received from Northern Care Alliance NHS Foundation
              Trust will now be available for you to view within the Northern
              Care Alliance NHS Foundation Trust Metabolic Medicine platform and
              will no longer be sent to you in a paper format. This includes but
              is not limited to appointment letters, care plans and clinic
              letters.{" "}
            </p>
            <br />
            <h5 tabIndex={0}>Safety</h5>
            <p tabIndex={0}>
              Whilst the Northern Care Alliance NHS Foundation Trust Metabolic
              Medicine platform sits on a secure platform and the chance of a
              data breach is very unlikely, but we cannot absolutely guarantee
              the safety of the Northern Care Alliance NHS Foundation Trust
              Metabolic Medicine platform. As the user you will need to make
              sure your login details are kept confidential and not shared with
              anyone. You will also need to be aware of your surroundings, and
              we advise you to only login to your account when you are in a
              private space.
            </p>
            <br />
            <h5 tabIndex={0}>Setting up your account</h5>
            <p tabIndex={0}>
              When setting up your account you must agree to the following
              information: <br />
              <span tabIndex={0} className="ml-dot"> &#9679; that you will not provide false information;</span>
              <br />
              <span tabIndex={0} className="ml-dot">
                {" "}
                &#9679; that you will keep all your information updated;
              </span>
              <br />
              <span tabIndex={0} className="ml-dot">
                {" "}
                &#9679; that you will not share your login details with anyone;
              </span>
              <br />
              <span tabIndex={0} className="ml-dot">
                {" "}
                &#9679; that you will respect other users, including NHS staff,
                at all times.
              </span>
            </p>
            <br />
            <h5 tabIndex={0}>Terminations</h5>
            <p tabIndex={0}>
              If you violate the spirit of this agreement, Northern Care
              Alliance NHS Foundation Trust can suspend or terminate your
              account. This decision will be sent to you via email or you will
              receive a notification the next time you try to login to your
              account.
            </p>
            <br />
            <h5 tabIndex={0}>Other Applications</h5>
            <p tabIndex={0}>
              Northern Care Alliance NHS Foundation Trust does contain links to
              other applications which are not owned or controlled by Northern
              Care Alliance NHS Foundation Trust. The trust cannot take
              responsibility for the content or general practices of these
              applications.
            </p>
            <br />
            <h5 tabIndex={0}>Changes to Agreement</h5>
            <p tabIndex={0}>
              This agreement may be updated from time to time. In this event,
              the Northern Care Alliance NHS Foundation Trust Metabolic Medicine
              platform will always notify users of the changes and ask for your
              ongoing consent.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};
