import React, { useRef } from "react";
// import Chart from "react-apexcharts";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface BarChartProps {
  chartType?: any;
  chartOption?: any;
}

export const BarChart: React.FC<BarChartProps> = ({
  chartType,
  chartOption,

}) => {

  return (
    <>
      {/* <Chart options={chartOption} series={chartOption.series} type={chartType} /> */}
      <HighchartsReact
        containerProps={{
          className: "bar-chart-class",
          id: "bar-chart-container",
        }}
        highcharts={Highcharts}
        options={chartOption}
        type={chartType}
      
      />
    </>
  );
};
