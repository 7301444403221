import React, { useState ,useEffect} from "react";
import TagManager from 'react-gtm-module';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { EcgChart } from '../components/Charts/EcgChart';
import { PassiveChart } from '../components/Charts/PassiveChart';
import { SpoChart } from '../components/Charts/SpoChart';
import { AboutMetabolic } from "../pages/AboutMetabolic";
import { ClinicianDashboard } from "../pages/ClinicianDashboard";
import { ClinicianOverView } from "../pages/ClinicianOverView";
import { ClinicianPatient } from "../pages/ClinicianPatient";
import { AdminDashboard } from "../pages/AdminDashboard";

import { Cookies } from "../pages/Cookies";
import { Dashboard } from "../pages/Dashboard";
import { FoodDiary } from "../pages/FoodDiary";
import { FoodDiaryOverview } from "../pages/FoodDiaryOverview";
import { FoodDiaryQuestion } from "../pages/FoodDiaryQuestion";
import { ForgotPassword } from "../pages/ForgotPassword";
import { FromQuestionnaire } from "../pages/FromQuestionnaire";
import { HomePage } from "../pages/Home";
import { PrivacyStatement } from "../pages/PrivacyStatement";
import { Questionnaires } from "../pages/Questionnaires";
import { ResetPassword } from "../pages/ResetPassword";
import { SignIn } from "../pages/SignIn";
import { TermsAndCondition } from "../pages/TermsAndCondition";
import { Withings } from "../pages/Withings";

import appUrls from "../services/AppURLs";
import ScrollToTop from "../utils/ScrollToTop";
import PrivateRoute from "./ProtectedRoutes";
import PrivateRoute_1 from "./ProtectedRoutes_1";
import { Logout } from '../pages/Logout';
import {  checkUserAuthentication,} from '../services/api/AuthAPI';

interface indexProps {}


export const AppRoute: React.FC<indexProps> = () => {
  if (process.env.REACT_APP_GA_TAGMANAGER) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GA_TAGMANAGER,
      dataLayerName: 'PageDataLayer',
    };
    TagManager.initialize(tagManagerArgs);
  }
  
  return (
    <BrowserRouter>
        <ScrollToTop />
      <Routes>
       <Route  path='/' element={<PrivateRoute_1/>}>
        <Route path={appUrls.user.home} element={<HomePage />} />
        <Route path={appUrls.user.login} element={<SignIn />} />
        <Route path={appUrls.user.forgot} element={<ForgotPassword />} />
        <Route path={appUrls.user.reset} element={<ResetPassword />} />
        </Route>  
        <Route  path='/' element={<PrivateRoute/>}>
          <Route path={appUrls.dashboard} element={<Dashboard />} />
          <Route path={appUrls.questionnaires} element={<Questionnaires />} />
          <Route path={appUrls.diaryquestions} element={<FoodDiaryQuestion />} />
          <Route path={appUrls.fooddiary} element={<FoodDiary />} />
          <Route path={appUrls.fooddiaryoverview} element={<FoodDiaryOverview />}/>
          <Route path={appUrls.terms} element={<TermsAndCondition />} />
          <Route path={appUrls.privacy} element={<PrivacyStatement />} />
          <Route path={appUrls.cookies} element={<Cookies />} />
          <Route path={appUrls.aboutmetabolic} element={<AboutMetabolic />} />
          <Route path={appUrls.question} element={<FromQuestionnaire />} />
          <Route path={appUrls.ecg} element={<EcgChart />} />
          <Route path={appUrls.spo} element={<SpoChart />} />
          <Route path={appUrls.passive} element={<PassiveChart />} />
          <Route path={appUrls.cliniciandashboard+'/:string'} element={<ClinicianDashboard />}/>
          <Route path={appUrls.cliniciandashboard} element={<ClinicianDashboard />}/>
          <Route path={appUrls.clinicianpatient} element={<ClinicianPatient />} />
          <Route path={appUrls.admindashboard} element={<AdminDashboard />} />
          <Route path={appUrls.logout} element={<Logout />} />
          <Route path={appUrls.withings} element={<Withings />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
