import React,{useEffect,useState} from 'react';
import { Button } from '../components/Button/Button';
import { Layout } from '../components/Layout/Layout';
import "../styles/Dashboard.css";

import appUrls from '../services/AppURLs'

import  TakedaLogo  from "../assets/svg/Take_da.svg";
import useAuthContext from '../context/providers/AuthContext';
import apiFunctions from "../services/api-service";
import { useSearchParams, useNavigate } from "react-router-dom";
import Loading from '../components/Loading/Loading';




interface DashboardProps {

}

export const Dashboard: React.FC<DashboardProps> = ({ }) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();

    const { state: { user } } = useAuthContext();
    const foodDiaryPath = () => {
        navigate(appUrls.fooddiary);
    };
    const questionnairesPath = () => {
        navigate(appUrls.questionnaires);
    };
    const withigPath = () => {
        navigate((user?.userRole.roleInfo.id === 3 && user?.withingsSync === false) ?appUrls.withings : appUrls.ecg);
    };

useEffect(()=>{
    if (searchParams.get("email")) {
    withingsUnsync()
    }
},[])

const withingsUnsync = async() =>{
    setLoading(true)
    try {
        await apiFunctions
          .post(
           
              "/api/withings/user-unsync",{}
          )
          .then((_result) => {
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
            console.log("error", err);
          });
      } catch (error: any) {
        setLoading(false)
        console.log(error.response, "errorresposne");
      }
}
    return (
        <Layout>
            <Loading status={loading} />
            <main className='main-dashboard'>
                <section className='dashboard-container'>
                    <div className='dashboard-block'>
                        <h5 tabIndex={0}>Welcome to Metabolic Medicine, a Northern Care Alliance Foundation Trust study.</h5>
                        <br />
                        <p tabIndex={0}>In this study, you will be asked about:</p>
                        <br />
                        <p tabIndex={0}>- Your food consumption</p>
                        <p tabIndex={0}>- Your Withings data</p>
                        <p tabIndex={0}>- To answer some questionnaires</p>
                        <br />
                        <br /><br />
                        <div className='dash-content' tabIndex={0}>Please select the page you would like to go.</div>
                        {/* <p tabIndex={0} className='font-red'>  TEXT TO BE CONFIRMED BY NORTHERN CARE ALLIANCE</p> */}
                        <div className='dashboard-bt-group'>
                            <Button
                                btntype="button"
                                text="Food Diary"
                                addClass="primary-btn w-full"
                                onClick={foodDiaryPath} />
                            <br /><br />
                            <Button
                                btntype="button"
                                text="Questionnaires"
                                addClass="primary-btn w-full"
                                onClick={questionnairesPath} />
                            <br /><br />
                            <Button
                                btntype="button"
                                text="Withings"
                                addClass="primary-btn w-full"
                                onClick={withigPath} />
                        </div>
                        <div className='takeda-center'>
                            <p tabIndex={0}> Sponsored by Takeda UK Limited  </p>
                            <br /><br />
                            <span>
                                <img tabIndex={0} src={TakedaLogo} alt='Takeda'/>
                            </span>

                        </div>
                    </div>
                </section>
            </main>
        </Layout >
    );
}