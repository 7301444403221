// import { useEffect } from 'react';

// import { useLocation } from 'react-router-dom';

// export default function ScrollToTop() {
//   const { pathname } = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

import { useEffect } from 'react';

import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });

    if (process.env.REACT_APP_GA_TAGMANAGER) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pageUrl: pathname,
        },
      });
    }

    console.log('pathname', pathname);
  }, [pathname]);

  return null;
}