import React from "react";
import { Layout } from "../components/Layout/Layout";

interface PrivacyStatementProps {}

export const PrivacyStatement: React.FC<PrivacyStatementProps> = ({}) => {
  return (
    <Layout>
      <main className="main-container">
        <section className="outer-container">
          <div className="terms-condition">
            <h4 tabIndex={0}>Privacy statement </h4>
            <br />
            <h5 tabIndex={0}>Your Personal Information</h5>
            <p tabIndex={0}>
              {" "}
              We aim to provide you with the highest quality care. To do this,
              we must keep records about you and the care we provide for
              you.  Health Records are held on paper and electronically and we
              have a legal duty to keep these confidential, accurate and secure
              at all times in line with Data Protection Laws.
            </p>
            <br />
            <p tabIndex={0}>
              Our staff are trained to handle your information correctly and
              protect your privacy. We aim to maintain high standards, adopt
              best practices for our record keeping and regularly check and
              report on how we are doing. Your information is never collected
              for direct marketing purposes and is never sold on to any third
              parties.
            </p>
            <br />
            <p tabIndex={0}>
              Sometimes your care may be provided by members of a care team,
              which may include people from other organisations such as health,
              social care, education or other care organisations.{" "}
            </p>
            <br />
            <p tabIndex={0}>
              Information is held for specified periods of time as set out in
              the Records Management Code of Practice for Health and Social Care
              for more information visit:{" "}
              <a
                className="link-color"
                href="https://transform.england.nhs.uk/information-governance/guidance/records-management-code/"
                target="_blank"
              >
                https://transform.england.nhs.uk/information-governance/guidance/records-management-code/{" "}
              </a>
            </p>
            <br />
            <p tabIndex={0}>
              Information collected about you to deliver your health care is
              also used to assist with: <br />
              <div className="ml-dot">
                <span tabIndex={0}>
                  {" "}
                  &#9679; Making sure your care is of a high standard
                </span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; Using statistical information to look after the health
                  and wellbeing of the general public and planning services to
                  meet the needs of the population
                </span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; Assessing your condition against a set of risk
                  criteria to ensure you are receiving the best possible care
                </span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; Preparing statistics on our performance for the
                  Department of Health and other regulatory bodies
                </span>
                <br />
                <span tabIndex={0}> &#9679; Helping train staff and support research</span>
                <br />
                <span tabIndex={0}> &#9679; Supporting the funding of your care</span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; Reporting and investigation of complaints, claims and
                  untoward incidents
                </span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; Reporting events to the appropriate authorities when
                  we are required to do so by law.
                </span>
              </div>
            </p>
            <br />
            <p tabIndex={0}>
              The legal basis for the processing of data for these purposes is
              that the NHS is an official authority with a public duty to care
              for its patients. The NHS is guided by the Department of Health
              and Data Protection law which says it is appropriate to process
              data for health and social care treatment of patients and for the
              management of health or social care systems and services.
            </p>
            <br />
            <p tabIndex={0}>
              If we need to use your personal information for any reason beyond
              those stated above, we will discuss this with you. You have the
              right to ask us not to use your information in this way. However,
              there are exceptions to this which are listed below.
              <br />
              <div className="ml-dot">
                <span tabIndex={0}>
                  {" "}
                  &#9679; The public interest is thought to be of greater
                  importance, e.g.:
                </span>
                <br />
                <span tabIndex={0}> &#9679; If a serious crime has been committed</span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; If there are risks to the public or our staff
                </span>
                <br />
                <span tabIndex={0}> &#9679; To protect vulnerable children or adults</span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; We have a legal duty, for example registering births,
                  reporting some infectious diseases, wounding by firearms and
                  court orders
                </span>
                <br />
                <span tabIndex={0}>
                  {" "}
                  &#9679; We need to use the information for medical research.
                  We have to ask permission from the Confidentiality Advisory
                  Group (appointed by the NHS Health Research Authority).
                </span>
              </div>
            </p>
            <br />
            <h5 tabIndex={0}>How is your information shared?</h5>
            <p tabIndex={0}>
              Northern Care Alliance NHS Foundation Trust, as Data Controller,
              will not share your data with anyone without your explicit
              consent, unless the law permits us to do so.
            </p>
            <br />
            <p tabIndex={0}>
              We share data only with our authorised Data Processors, who must
              act at all times on our instructions as the Data Controller under
              the Data Protection Act 2018. Our data processors are outlined
              below:
            </p>
            <br />
            <h5 tabIndex={0}>Data Processor - Microsoft Azure</h5>
            <p tabIndex={0}>
              Microsoft Azure is a cloud computing service  by Microsoft
              for managing applications and services.  We use Azure to manage
              the Northern Care Alliance NHS Foundation Trust Metabolic Medicine
              platform and store its contents.  Microsoft only stored data
              within the UK To view their privacy policy please visit:
              <a
                className="link-color"
                href="https://www.microsoft.com/en-gb/trust-center/privacy/"
                target="_blank"
              >
                https://www.microsoft.com/en-gb/trust-center/privacy{" "}
              </a>
            </p>
            <br />
            <h5 tabIndex={0}>Data Processor – Mindwave Ventures</h5>
            <p tabIndex={0}>
              Mindwave Ventures Limited are our design and development partner
              for the Northern Care Alliance NHS Foundation Trust Metabolic
              Medicine platform. They maintain the platform and undertake bug
              fixing where needed and are also working with us on further
              feature development for the platform. Their staff do not have
              access to any data within the platform except in very rare cases
              where it is essential for bug fixing or one of the reasons stated
              above. In the unlikely event that Mindwave Ventures staff are
              required to access personal data, they will only do so with
              written consent from Northern Care Alliance NHS Foundation Trust.
              To view their privacy policy please visit:
              <a
                className="link-color"
                href="https://mindwaveventures.com/privacy-policy/"
                target="_blank"
              >
                https://mindwaveventures.com/privacy-policy/{" "}
              </a>
            </p>
            <br />
            <h5 tabIndex={0}>Your Rights:</h5>
            <p tabIndex={0}>
              Data Protection laws gives individuals rights in respect of the
              personal information that we hold about you. These are:
              <br />
              <div className="ml-dot">
                <span tabIndex={0}>
                  {" "}
                  1. To be informed why, where and how we use your information.
                </span>
                <br />
                <span tabIndex={0}>
                  2. To ask for access to your information <br />
                  <span tabIndex={0} className="norther-left">
                    a. Note. Northern Care Alliance NHS Foundation Trust
                    Metabolic Medicine platform does not hold any data about you
                    that you cannot access on the platform.
                  </span>
                </span>
                <br />
                <span tabIndex={0}>
                  3. To ask for information to be corrected if inaccurate or
                  incomplete.
                </span>
                <br />
                <span tabIndex={0}>
                  4. To ask for your information to be deleted or removed where
                  there is no need for us to continue processing it.
                </span>
                <br />
                <span tabIndex={0}>
                  5. To ask us to restrict the use of your information.
                </span>
                <br />
                <span tabIndex={0}>
                  6. To ask us to copy or transfer your information from one IT
                  system to another in a safe and secure way, without impacting
                  the quality of the information.
                </span>
                <br />
                <span tabIndex={0}>
                  7. To object to how your information is used.
                </span>
                <br />
                <span tabIndex={0}>
                  8. To challenge any decisions made without human intervention
                  (automated decision making).
                </span>
              </div>
            </p>
            <br />
            <p tabIndex={0}>
              To request copies of your personal information, please contact the
              health records team at the Trust site where you are receiving
              treatment.
            </p>
            <br />
            <p tabIndex={0}>For more information please contact us at: </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};
