import React, { useEffect, useRef, useState } from "react";
import { FooterMenu } from "../FooterMenu/FooterMenu";
import { HeaderNav } from "../HeaderNav/HeaderNav";
import { PrimaryBar } from "../PrimaryBar/PrimaryBar";
import { SecondaryBar } from "../PrimaryBar/SecondaryBar";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children?: React.ReactNode;
  name?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const pathname = location.pathname;

  const [headerHeight, setHeaderHeight] = useState<any>(0);
  const [footerHeight, setFooterHeight] = useState<any>(0);

  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    setHeaderHeight(headerRef?.current?.clientHeight);
    setFooterHeight(footerRef?.current?.clientHeight);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [headerHeight, footerHeight]);

  const totalHeight: any = headerHeight + footerHeight;
  const customHeight = `calc(100vh - ${totalHeight.toString()}px)`;
  // let navBodyHeight = window.innerHeight;
  // navBodyHeight = navBodyHeight - totalHeight;
  let minBodyHeight = 350 || customHeight;

  return (
    <section>
      <div className="header-section" ref={headerRef}>
        <HeaderNav />
        <PrimaryBar />
        {pathname === "/withings/ecg" && <SecondaryBar />}
        {pathname === "/withings/spo" && <SecondaryBar />}
        {pathname === "/withings/passive" && <SecondaryBar />}

      </div>

      {/* <div style={{ minHeight: navBodyHeight }}> */}
      <div className="background-wrapper" style={{ minHeight: minBodyHeight }}>
        {children}
      </div>
      {/* </div> */}

      <div className="footer" ref={footerRef}>
        <FooterMenu />
      </div>
    </section>
  );
};
