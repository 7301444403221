import React, { createContext, useContext, useEffect, useReducer } from 'react';
// import { isTokenValid } from '../../services/api-service';

import { getUserInfo , checkUserAuthentication} from '../../services/api/AuthAPI';
import { AuthAction, authReducer, AuthState, initialState } from '../reducers/AuthReducer';

// a authDispatch can only do action types set in reducer
type AuthContextProps = {
  state: AuthState;
  authDispatch: React.Dispatch<AuthAction>;
  checkAuth?: any;
};

const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  authDispatch: () => initialState
});

export function AuthContextProvider(props: React.PropsWithChildren<{}>) {
  const [state, authDispatch] = useReducer(authReducer, initialState);

  async function checkAuth() {
    const token = await checkUserAuthentication();

    if (!token) return;    
    // if (isTokenValid(token)) {
    if (token.data && token.data.result && token.data.result.accessToken) {
      try {
        const userInfo = await getUserInfo();
        if (userInfo) {
          authDispatch({ type: 'LOGIN' });
          const responseModified = { ...userInfo.data.result };
          responseModified.role =
            responseModified.userRole.roleInfo.name.toLowerCase();
          authDispatch({ type: 'LOAD_USER', user: responseModified });
        } else {
          authDispatch({ type: 'LOGOUT' });
        }
      } catch (error:any) {
        console.log('error', error);
      }
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return <AuthContext.Provider value={{ state, authDispatch, checkAuth }} {...props} />;
}

export default function useAuthContext() {
  return useContext(AuthContext);
}