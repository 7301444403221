import React from 'react';
import '../styles/TextArea.css';


export interface TextAreaProps {
  style?: string;
  text?: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  name?: string;
  addClass?: string;
  values?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  errors?: string | Array<string> | any;
  id?: string;
}

const TextArea = ({
  label,
  placeholder,
  disabled,
  onChange,
  addClass,
  name,
  errors,
  onBlur,
  values,

}: TextAreaProps) => {
  const tabIndex = 0;
  return (
    <div className="textarea-wrap">
      <div className="input-group">
        {label && (
          <label tabIndex={tabIndex} >
            {label}
          </label>
        )}
        <textarea
          tabIndex={tabIndex}     
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          name={name}
          className={addClass}
          onBlur={onBlur}
          value={values}
          // maxLength={1000}
        />
        {errors && <div className="form-error-msg">{errors}</div>}
      </div>
    </div>
  );
};

export default React.memo(TextArea);
