import React,{useState, useEffect} from "react";
import { Button } from "../components/Button/Button";
import { Layout } from "../components/Layout/Layout";

import apiFunctions , { removeToken }from "../services/api-service";
import appUrls from "../services/AppURLs";
import Loading from "../components/Loading/Loading";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import useAuthContext from '../context/providers/AuthContext';

interface LogoutProps {}

export const Logout: React.FC<LogoutProps> = ({}) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  
  const { state: { user } } = useAuthContext();

  const [searchParams] = useSearchParams();


  useEffect(() => {
    Logout();
  }, []);

  const Logout =  ()=>{
    setLoading(true)
    try {
      apiFunctions
        .delete('/api/account/logout',)
        .then(async (_result) => {
          // localStorage.setItem('role', _result.data.result.userRole.roleInfo.id)
          removeToken(
            _result && _result.data && _result.data && _result.data.result.accessToken ? _result.data.result.accessToken : null,
            null
          )
          setLoading(false)
          window.location.href = appUrls.user.login;
        })
        .catch((err) => {
            setLoading(false)
          console.log("error",err);
        })
    } catch (error: any) {
      console.log(error.response, 'errorresposne')
    }
}



  return (
    <Layout>
      <main className="main-container">
    
         <Loading status={loading} />
      </main>
    </Layout>
  );
};
