
const appUrlsJson = {
  ourAppUrls: {
    user: {
      home: '/',
      login: '/user/login',
      forgot: '/user/forgot',
      reset: '/user/reset',
    },
    questionnaires: '/questionnaires',
    question: '/questionnaires/question',
    diaryquestions:'/fooddiary/question',
    fooddiary:'/fooddiary',
    fooddiaryoverview:'/fooddiary/question/overview',
    withings:'/withings',
    ecg:'/withings/ecg',
    spo:'/withings/spo',
    passive:'/withings/passive',
    terms:'/terms',
    privacy:'/privacy',
    cookies:'/cookies',
    aboutmetabolic:'/aboutmetabolic',
    dashboard:'/dashboard',
    cliniciandashboard:'/cliniciandashboard',
    clinicianpatient:'/cliniciandashboard/patient',
    clinicianoverview:'/clinicianoverview',
    admindashboard:'/admindashboard',
    logout:'/logout'

  },

}

function checkInnerJson(jsonData: any) {
  if (jsonData) {
    for (const key in jsonData) {
      if (typeof jsonData[key] === 'string') {
        jsonData[key] = `${jsonData[key]}`;
      } else {
        jsonData[key] = checkInnerJson(jsonData[key]);
      }
    }
  }
  return jsonData as typeof appUrlsJson.ourAppUrls;
}


const appUrls = {
  ...checkInnerJson(appUrlsJson.ourAppUrls),
  // outerDomainUrls: appUrlsJson.outerDomainUrls,
}

export default appUrls
