import React,{useState, useEffect} from "react";
import { Button } from "../components/Button/Button";
import { Layout } from "../components/Layout/Layout";

import apiFunctions from "../services/api-service";
import appUrls from "../services/AppURLs";
import Loading from "../components/Loading/Loading";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import useAuthContext from '../context/providers/AuthContext';
import { DialogPopup } from "../components/DialogPopup/DialogPopup";

interface WithingsProps {}

export const Withings: React.FC<WithingsProps> = ({}) => {
  let navigate = useNavigate();

  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const [syncView, setSyncView] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false);
  
  const { state: { participant } } = useAuthContext();

  const [type, setType] = useState<any>('');
  const [redirecturl, setRedirectUrl] = useState<any>('withings');

  const [searchParams] = useSearchParams();
  const security_code = searchParams.get("code");
  
  async function checkUserSync() {
    try {
        let URL =  `/api/withings/get-user-sync`; 
        URL = searchParams.get("patientId") ? URL+`?service_user_id=${searchParams.get("patientId")}`:URL;    
          
        await apiFunctions
            .get(URL)
            .then((_result) => {
                if(_result.data.result.withingsSync === true){
                  navigate(appUrls.ecg +'?patientId='+searchParams.get("patientId"));
                }
                if(searchParams.get("patientId") &&_result.data.result.withingsSync === false ){
                  setSyncView(true)
                 }
                setLoading(false);
               
            })
            .catch((err) => {
                setLoading(false);
              
                console.log("error", err);
            })
    } catch (error: any) {
      setLoading(false);
        console.log(error.response, 'errorresposne')
    }
   }

  async function getSyncWithingData() {

    try {
        let URL =  `/api/withings/user-sync?security_code=${security_code}`;       
        await apiFunctions
            .post(URL,{})
            .then((_result) => {
                setLoading(false);
                SetPopupcontent({status:'Success.',message:`Your withing's data has been synced successfully. Now you can view your records.`})
                setRedirectUrl(appUrls.ecg)
                setType("");
                setPopup(true)
                setHidepopup(false)
                setDialogOpen(true);

            })
            .catch((err) => {
                setLoading(false);
                SetPopupcontent({status:'Your Sync data failed.',message: err.response && err.response.data && err.response.data.msg ? err.response.data.msg:'Please try again.'})
                setType("syncwithings");
                setPopup(true)
                setHidepopup(true)
                setDialogOpen(true);

                console.log("error", err);
            })
    } catch (error: any) {
      setLoading(false);
      SetPopupcontent({status:'Your Sync data failed.',message: error.response && error.response.data && error.response.data.msg ? error.response.data.msg:'Please try again.'})
      setType("syncwithings");
      setPopup(true)
      setHidepopup(true)
      setDialogOpen(true);

        console.log(error.response, 'errorresposne')
    }
   }
   
  useEffect(() => {
    if(security_code){
      setLoading(true)
      getSyncWithingData()
    }else{
      setLoading(true)
      checkUserSync();
    }
  }, []);

 

  return (
    <Layout>
      <main className="main-container">
      {!syncView && <section className="outer-container">
          <h5 tabIndex={0}>Withings Update </h5>
          <br />
          
          <> <p tabIndex={0}>
          For the purpose of this study, we will need you to sync your
          Withings data with this portal every day. Please sync your data by
          clicking the button below
        </p>
         <section className="">
          <div className="h-10" />
          <div className="h-10" />
          <div className="h-10" />
          <div className="text-center">
            <Button
              btntype="button"
              id="draft"
              text="Sync data now"
              addClass="primary-btn btn-padding btn-other-entry"
              onClick={()=>{    
               window.open(process.env.REACT_APP_SYNC_WITHINGS_PAGE,'_self', '');
            }}
            />
          </div>

          <br/>
          <br/>
          <div className="h-10" />
          <div className="h-10" />
          <div className="h-10" />
        </section>
          </>
         
         </section> }

         {syncView &&
         
         <section className="overview-outer-container">
              {searchParams.get("patientId") && (
                <div className="username-data">
                  <h5 tabIndex={0} className="particitant-w">
                    Participant: {participant && `${participant?.name || participant?.label}`}
                  </h5>
                </div>
              )}
              <section className="no-data" tabIndex={0}>
                The user does not sync withings.
              </section>
            </section>
         } 
           

        <DialogPopup
          open={dialogOpen}
          onRequestClose={() => {
            setDialogOpen(!dialogOpen);
          }}
          showIcon
          closeOnOutsideClick
          redirectTo={redirecturl}
          hidePopup={hidepopup}
          withings={true}
        >
            <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            type={type}            
            redirectTo={redirecturl}
            hidePopup={hidepopup}
            setPopup={setPopup}
            onRequestClose={() => {
              setDialogOpen(!dialogOpen);
            }}
          />
        </DialogPopup> 
   
         <Loading status={loading} />
      </main>
    </Layout>
  );
};
