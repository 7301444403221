import React from 'react';
import "../styles/QbCard.css";
import appUrls from '../../services/AppURLs'
import { useSearchParams ,useNavigate} from 'react-router-dom';
import  moment  from 'moment';

interface QbCardProps {
    questions?: any
    title?: string
    completed?: Boolean
    patientView?:Boolean
    adminView?:Boolean
    name?:string
    session?:any,
    id?:any

}

export const QbCard: React.FC<QbCardProps> = ({
    title,
    questions,
    completed,
    patientView,
    adminView,
    name,
    session,
    id
}) => {
    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

const goToQuestionnair = (setId:any)=>{
let patientId= searchParams.get('patientId');
    if(adminView){
        navigate(appUrls.questionnaires +'?patientId='+setId.uuid);
    }
    if(!patientView){
        navigate(`${appUrls.question}?setId=${setId.id}&session=${session[0].uuid}&patientId=${patientId && patientId !==null ?patientId :''}`);
    }
}

    return (
        <main>
            <section id={id}>
                {title !== "remove-line" &&
                    <div className="hr-line">
                        <label tabIndex={0}>{title}</label>
                    </div>
                }

                <div className='qb-card-group'>
                    {questions.length > 0 && questions.map((qs: any, index: any) => {
                        return (
                            <button className='qb-card' onClick={() => goToQuestionnair(qs)} id={index}>
                                <div className='card-info'>
                                    <div className='card-head-block'>
                                        <h5>{qs.name}</ h5>
                                    </div>
                                    <div className='output-group'>
                                        <p >Category:</p>
                                        <p >{qs.questionSetCategory && qs.questionSetCategory.name}</p>
                                    </div>
                                    {name ==='completed' && 
                                    <div className='output-group'>
                                        <p >Completed Date:</p>
                                        <p >{moment(session[0].questionSet.filter((res:any)=>res.questionSetName === qs.name)[0].questionnire_completed_day).format('YYYY-MM-DD h:mm:a')}</p>
                                    </div>
                                    }
                                </div>

                                {
                                    !completed && qs.status !=='draft' && <div className='card-footer'>
                                        <div></div>
                                        <div className='mini-link-set'>
                                            <span >NEW</span>
                                        </div>
                                    </div>
                                }
                            </button>
                        )
                    })
                    }
                    {!questions.length && <div tabIndex={0}> There is no {name} questionnaire</div>}
                </div>
                

            </section>
        </main>
    );
}