import React, { Fragment, useState } from "react";
import { ReactComponent as SalfordLogo } from "../assets/svg/SalfordLogo.svg";
import { ReactComponent as NCAlliance } from "../assets/svg/NorthernCareAlliance.svg";
import { Button } from "../components/Button/Button";
import { TextInput } from "../components/TextInput/TextInput";
import "../styles/Login.css";
import { Link } from "react-router-dom";
import apiFunctions, { setToken } from "../services/api-service";
import appUrls from "../services/AppURLs";
import useAuthContext from "../context/providers/AuthContext";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import Loading from "../components/Loading/Loading";
// import useAuthContext from '../../context/providers/AuthContext';
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import validationErrors from "../services/ValidationSchema";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";

interface SignInProps {
}

const defaultFormVal: any = {
  email: "",
  password: "",
};

const rejectReasonSchema = Yup.object().shape({
    email: Yup.string()
    .email(validationErrors[1002])
    .required(validationErrors[1029]),
  password: Yup.string().required(validationErrors[1030]),
});

export const SignIn: React.FC<SignInProps> = () => {
  const [changeType, setChangeType] = useState("password");
  const [formdata, setFormData] = useState({ email: "", password: "" });
  const [userRole, setUserData] = useState(3);
  let navigate = useNavigate();
  const [type, setType] = useState("");

  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const { authDispatch } = useAuthContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  // const {state : {user}} = useAuthContext();

  const formik = useFormik({
    initialValues: defaultFormVal,
    validationSchema: rejectReasonSchema,

    onSubmit: async (values: any) => { 
      const payload: any = { ...values };
      payload.email= payload.email.toLowerCase()

      loginUser(payload);
    },
  });
    
  const loginUser = async(payload: any) => {
    setLoading(true);
    try {
     await apiFunctions
        .post(
          // process.env.REACT_APP_SERVICE_BACKEND_URL + "auth/login",
          "/api/auth/login",
          payload
        )
        .then(async (_result) => {
          setToken(
            _result &&
              _result.data &&
              _result.data.result.accessToken
              ? _result.data.result.accessToken
              : null,
            null
          );
          authDispatch({ type: "LOGIN" });
          authDispatch({
            type: "LOAD_USER",
            user: _result.data.result.findRes,
          });
          //   Set user role
          setUserData(_result.data.result.userRole.id);
          // localStorage.setItem('user_role', _result.data.result.userRole.id);
          setLoading(false);
          let redirect =
            _result.data.result.userRole.id === 2
              ? appUrls.cliniciandashboard
              : _result.data.result.userRole.id === 3 ? appUrls.dashboard :appUrls.admindashboard;
          
          if(_result.data.result.findRes.withingsSync === false && _result.data.result.userRole.id === 3 ){
             SetPopupcontent({status:'UPDATE YOUR WITHINGS DATA',sigin_in:'yes',message:`Welcome to the Metabolic Med portal.`, message1:`Please click the button below to go the withings page and sync your data to provide accurate information for the study.`})
             setType("syncwithings");
             setPopup(true)
             setHidepopup(false)
             setDialogOpen(true);
          }else{
            navigate(redirect);
          }
        })
        .catch((err) => {
          console.log("error-----", err);
          setLoading(false);
          SetPopupcontent({ status: "Failed", message: err.response.data.error ? err.response.data.error.message : err.response.data.message ? err.response.data.message :err.response.data.msg });
          setPopup(true);
          setHidepopup(true);
          setDialogOpen(true);

        });
    } catch (error: any) {
      console.log(error, "errorresposne");
      setLoading(false);
      SetPopupcontent({ status: "Failed", message:error.response.data.error ? error.response.data.error.message :error.response.data.message ? error.response.data.message:error.response.data.msg });
      setPopup(true);
      setHidepopup(true);
      setDialogOpen(true);
    }
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formdata,
      // Trimming any whitespace
      [e.target.id]: e.target.value.trim(),
    });
  };


  return (
    <Fragment>
      <div className="main-section">
        <div className="block-center">
          <form action="submit" className="" onSubmit={formik.handleSubmit}>
            <span>
              <SalfordLogo />
            </span>
            <br />
            <br />
            <TextInput
              type="text"
              placeholder="Enter your email"
              id="email"
              name="email"
              //   values={formdata.email}
              //   onChange={handleChange}
              onChange={formik.handleChange}
              errors={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
              values={formik.values.email}
            />
            <br />
            <TextInput
              id="password"
              type={changeType}
              placeholder="Enter your password"
              icon="password"
              name="password"
              //   onChange={handleChange}
              //   values={formdata.password}
              onChange={formik.handleChange}
              errors={
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : null
              }
              values={formik.values.password}
              onIconClick={(e) =>
                changeType === "password"
                  ? setChangeType("text")
                  : setChangeType("password")
              }
              
            />
            <br />
            <div className="wrap-content-center">
              <Link to="/user/forgot">Forgotten password </Link>
            </div>

            <br />
            <div className="wrap-content-center">
              <Button
                btntype="submit"
                text="Log in"
                addClass="primary-btn btn-login"
                // disabled ={(formik.values.email.length && formik.values.password.length) ? false : true}
                // onClick={loginUser}
                onClick={() => {}}
              />
            </div>
            <div className="line-height"></div>
          </form>
        </div>

        <DialogPopup
           open={dialogOpen}
           onRequestClose={() => {
             setDialogOpen(!dialogOpen);          
           }}
           showIcon
           closeOnOutsideClick
           redirectTo={ appUrls.dashboard}
          hidePopup={hidepopup}
         > 
         <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            type={type}            
            redirectTo={
              userRole === 1 ? appUrls.cliniciandashboard : appUrls.dashboard
            }
            hidePopup={hidepopup}
            setPopup={setPopup}
            onRequestClose={() => {
              setDialogOpen(!dialogOpen);          
            }}
          />
         </DialogPopup>

      
        <Loading status={loading} />
      </div>
      <div className="block-bottom">
        <NCAlliance tabIndex={0} aria-label="NHS FOOTER LOGO" />
      </div>
    </Fragment>
  );
};
