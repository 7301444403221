import React, { useEffect, useState } from "react";
import { ClinicianLayout } from "../components/Layout/ClinicianLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import appUrls from "../services/AppURLs";

import apiFunctions from "../services/api-service";
import { Button } from "../components/Button/Button";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import Loading from "../components/Loading/Loading";
import { Accordion } from "../components/Accordion/Accordion";
import "../styles/AdminDashboard.css";
import { Radio } from "../components/Radio/Radio";
import { CSVReader } from "../components/CSV-Reader/CSVReader";
import { DatePicker } from "../components/DatePicker/DatePicker";
import moment from "moment";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";

interface AdminDashboardProps { }

export const AdminDashboard: React.FC<AdminDashboardProps> = ({ }) => {
  const fileReader = new FileReader();
  const [file, setFile] = useState<any>();
  const [diarysession, setDiarySession] = useState("all"); //Food diary export part.
  const [diarytype, setdiaryType] = useState("all");
  const [question, setQuestion] = useState("all"); // Questionnaire export part.
  const [questionType, setQuestionType] = useState("all");

  const [withingsType, setWithingsType] = useState("heart_v2_get");

  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successRate, setsuccessRate] = useState(100);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showAccordion, setshowAccordion] = useState(true);
  const [redirect, setRedirect] = useState(appUrls.admindashboard);
  const [dateRange, setDateRange] = useState<any>({
    min: moment().subtract(50, "year"),
    max: new Date(),
  });


  function generateCSV(data: any) {
    const csvRows = [];

    // Generate header row
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    // Generate data rows
    data.forEach((item: any) => {
      const values = headers.map(header => {
        const escapedValue = item[header] && item[header].toString().replace(/"/g, '""');
        return `"${escapedValue}"`;
      });
      csvRows.push(values.join(','));
    });

    // Combine rows into a CSV string
    const csvContent = csvRows.join('\n');
    return csvContent;
  }

  const exportCSVFile = (result: any, name: string, percentage: any) => {

    // Headers for each column
    const csvContent = generateCSV(result);

    // let headers: any = [];
    // for (var i = 0; i < result.length; i++) {
    //   Object.keys(result[i]).forEach(function (key) {
    //     if (headers.indexOf(key) === -1) {
    //       headers.push(key);
    //     }
    //   });
    // }

    // // Convert users data to a csv
    // let usersCsv = result.reduce((acc: any, user: any) => {      
    //   acc.push([Object.values(user)].join(","));
    //   return acc;
    // }, [])




    downloadICSFile({
      data: csvContent,
      fileName: `${name}.csv`,
      fileType: "text/csv",
      percentage: percentage,
    });
  };
  const downloadICSFile = ({ data, fileName, fileType, percentage }: any) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();


    setLoading(false);
    successToast(percentage);
  };
  const successToast = (percentage: Number) => {
    setLoading(false);
    toast.dismiss();
    if (percentage === 100) {
      toast.success("The users data fetching successfully.", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(`The users data fetching success rate 100 out of ${percentage}. Data is still processing, please try to download again later.  If you have ongoing issues, please contact metmed.support@mindwaveventures.com`, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-width"
      });
    }
  };
  const noDataToastwithins = () => {
    toast.dismiss();
    setLoading(false);
    toast.error(`The users do not have any data between ${moment(startDate).format("DD/MM/YYYY")} to ${moment(endDate).format("DD/MM/YYYY")}`, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  const noDataToast = () => {
    toast.dismiss();
    setLoading(false);
    toast.error(`The users do not have any data.`, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  {
    /* Export food diary*/
  }
  const exportDiary = async () => {
    try {
      setLoading(true);

      let URL =

        `/api/fooddiary/get-record/export?session=${diarysession}&type=${diarytype}`;
      await apiFunctions
        .get(URL)
        .then((_result) => {
          toast.dismiss();
          let result = _result.data.result;
          if (result.length > 0) {
            exportCSVFile(result, 'fooddiaries', 100);
          } else {
            noDataToast();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);
      console.log(error.response, "errorresposne");
    }
  };

  {
    /* Export Questionnaire*/
  }
  const exportQuestionnaire = async () => {
    try {
      setLoading(true);
      let URL =
        `/api/questionnaire/export`;
      await apiFunctions
        .post(URL, {})
        .then((_result) => {
          let result = _result.data.result;
          toast.dismiss();
          if (result.FinalResult.length > 0) {
            let Filterdata = result.FinalResult;
            if (questionType !== "all") {
              Filterdata = Filterdata.filter((res: any) => res.Session_status === questionType);
            }
            if (question !== "all") {
              Filterdata = Filterdata = Filterdata.filter((res: any) => (res.Questionnaire_name).includes(question))
            }
            if (Filterdata.length > 0) {
              exportCSVFile(Filterdata, "questionnaires", 100);
            } else {
              noDataToast();
            }
          } else {
            noDataToast();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);
      console.log(error.response, "errorresposne");
    }
  };
  /* Get Withings export URL End poit*/
  function getURL(S_date: any, E_date: any) {
    let url_slug = "";
    switch (withingsType) {
      case "heart_v2_list":
        url_slug = `withings/export-heartlist?startdate=${S_date}&enddate=${E_date}`;
        break;
      case "heart_v2_get":
        url_slug = `withings/export-heart-rate?startdate=${S_date}&enddate=${E_date}`;
        break;
      case "measure_getMeas":
        url_slug = `withings/export-measure?startdate=${S_date}&enddate=${E_date}`;
        break;
      case "measure_v2_getactivity":
        url_slug = `withings/export-getactivity?startdate=${S_date}&enddate=${E_date}`;
        break;
      case "measure_v2_getintradayactivity":
        url_slug = `withings/export-getintradayactivity?startdate=${S_date}&enddate=${E_date}`;
        break;
      case "measure_v2_getworkouts":
        url_slug = `withings/export-workout?startdate=${S_date}&enddate=${E_date}`;
        break;
      case "sleep_v2_get":
        url_slug = `withings/export-sleep?startdate=${S_date}&enddate=${E_date}`;
        break;
      case "sleep_v2_getsummary":
        url_slug = `withings/export-sleepsummary?startdate=${S_date}&enddate=${E_date}`;
        break;
    }
    return url_slug;
  }

  {
    /* Export Withings*/
  }
  const exportWithings = async () => {
    try {
      setLoading(true);
      // successToast(93);
      // return;
      const S_date = moment(startDate).format("YYYY-MM-DD");
      const E_date = moment(endDate).format("YYYY-MM-DD");

      let url_slug = getURL(S_date, E_date);
      let URL = '/api/' + url_slug;
      await apiFunctions
        .post(URL, {})
        .then((_result) => {
          toast.dismiss();
          setLoading(false);
          let result = _result.data.result.FinalDataSet ? _result.data.result.FinalDataSet : _result.data.result;
          // setsuccessRate(;
          if (result !== null && result.length > 0) {
            exportCSVFile(result, `withings-${withingsType}`, 100);
          } else {
            noDataToastwithins();
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);
      console.log(error.response, "errorresposne");
    }
  };

  {
    /* Upload csv file*/
  }

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };
  {
    /* Convert CSV string to josn object */
  }

  const getvalues = (data: any) => {
    const lines = data.split("\r\n");
    const keys = lines[0].split(",");
    const array = [];
    // iterate over the rest of lines, notice we start at 1
    for (let i = 1; i < lines.length - 1; i++) {
      const values = lines[i].split(",");
      const dict: any = {};
      for (let k = 0; k < keys.length; ++k) {
        dict[keys[k]] = values[k];
      }
      array.push(dict);
    }
    return array;
  };
  {
    /* Import Participants */
  }

  const importParticipantUser = async (payload: any) => {
    try {
      setLoading(true);
      setshowAccordion(true);

      let URL =

        `/api/account/import-participants`;

      await apiFunctions
        .post(URL, payload)
        .then((_result) => {
          setRedirect(appUrls.admindashboard);
          setFile("");
          SetPopupcontent({
            status: "Success!",
            message: _result.data.result.msg,
          });
          setPopup(true);
          setHidepopup(false);
          setDialogOpen(true);
          setLoading(false);
        })
        .catch((err) => {
          setRedirect(redirect);
          setFile("");
          setLoading(false);
          SetPopupcontent({
            status: "Failed",
            message:
              err.response && err.response.data && err.response.data
                ? err.response.data.msg
                : "Some things went wrong",
          });
          setPopup(true);
          setDialogOpen(true);
          setHidepopup(false);
          console.log("_result", err.response.data);
        });
    } catch (error: any) {
      setLoading(false);
      setRedirect(redirect);
      setFile("");
      SetPopupcontent({
        status: "Failed",
        message:
          error.response && error.response.data && error.response.data.error
            ? error.response.data.error.message
            : "Some things went wrong",
      });
      setPopup(true);
      setHidepopup(false);
      console.log(error.response, "errorresposne");
    }
  };
  const NoData = () => {
    setRedirect("");
    setLoading(false);
    SetPopupcontent({
      status: "Failed",
      message:
        "Kindly note that the CSV file must carry uniqueIdentifier, firstName, lastName, email & password.",
    });
    setPopup(true);
    setDialogOpen(true);
    setHidepopup(true);
  };
  const handleOnSubmit = (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (file) {
      fileReader.onload = async (event: any) => {
        let payload = await getvalues(event.target.result);
        if (!payload.length) {
          setLoading(false);
          toast.dismiss();
          NoData();
        } else {
          toast.dismiss();
          const uniqueidentifer = Object.keys(payload[0]).includes(
            "uniqueIdentifier"
          );
          const firstName = Object.keys(payload[0]).includes("firstName");
          const lastName = Object.keys(payload[0]).includes("lastName");
          const email = Object.keys(payload[0]).includes("email");
          const password = Object.keys(payload[0]).includes("password");

          if (uniqueidentifer && firstName && lastName && email && password) {
            importParticipantUser(payload);
          } else {
            NoData();
          }
        }
      };
      fileReader.readAsText(file);
    } else {
      setLoading(false);
    }
  };
  const changeDate = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setDateRange({ min: moment().subtract(50, "year"), max: new Date() });
  };
  const changeMaxDate = (date: any) => {
    setStartDate(new Date());
    let add = moment(date ? date : startDate)
      .add(30, "day")
      .format("YYYY-MM-DD");
    let maxDate =
      add < moment().format("YYYY-MM-DD") ? add : moment().format("YYYY-MM-DD");
    setEndDate(new Date(maxDate));
    setDateRange({ ...dateRange, max: maxDate });
  };
  return (
    <ClinicianLayout>
      <main className="main-container">
        <section className="outer-container">
          <div className="inner-accordion">
            <p tabIndex={0}>
              In this section, you can view the list of participants, their
              questionnaire and food diary responses and Withings data.
            </p>
            <br />
            <br />
            <Accordion head="Food diary">
              <div className="accord-body">
                <p tabIndex={0}> Select session type</p>
                <div className="warpper-session">
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Session 1"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="sessionType"
                      value="1"
                      onChange={(e) => setDiarySession("1")}
                      checked={diarysession === "1" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Session 2"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="sessionType"
                      value="2"
                      onChange={(e) => setDiarySession("2")}
                      checked={diarysession === "2" ? true : false}
                    />
                  </div>

                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Session 3"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="sessionType"
                      value="3"
                      onChange={(e) => setDiarySession("3")}
                      checked={diarysession === "3" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="All sessions"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="sessionType"
                      value="all"
                      onChange={(e) => setDiarySession("all")}
                      checked={diarysession === "all" ? true : false}
                    />
                  </div>
                </div>
                <br />
                <br />
                <p tabIndex={0}>Select question type</p>
                <div className="warpper-session">
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Completed"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup"
                      value="completed"
                      onChange={(e) => setdiaryType("completed")}
                      checked={diarytype === "completed" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Incompleted"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup"
                      value="not-completed"
                      onChange={(e) => setdiaryType("not-completed")}
                      checked={diarytype === "not-completed" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="All"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup"
                      value="all"
                      onChange={(e) => setdiaryType("all")}
                      checked={diarytype === "all" ? true : false}
                    />
                  </div>
                </div>

                <div className="wrap-csv">
                  <Button
                    btntype="button"
                    id="save"
                    text="Export CSV"
                    addClass="primary-btn btn-padding"
                    onClick={(e: any) => {
                      exportDiary();
                    }}
                  />
                </div>
              </div>
            </Accordion>
            <br />
            <Accordion head="Questionnaires">
              <div className="accord-body">
                <p tabIndex={0}> Select question set</p>
                <div className="warpper-session">
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="BPI"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup2"
                      value={'BPI'}
                      onChange={(e) => setQuestion('BPI')}
                      checked={question === 'BPI' ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="FSS"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup2"
                      value={'FSS'}
                      onChange={(e) => setQuestion('FSS')}
                      checked={question === 'FSS' ? true : false}
                    />
                  </div>

                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="SF12"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup2"
                      value={'SF-12'}
                      onChange={(e) => setQuestion('SF-12')}
                      checked={question === 'SF-12' ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="GSRS"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup2"
                      value={'GSRS'}
                      onChange={(e) => setQuestion('GSRS')}
                      checked={question === `GSRS` ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="All questionnaires"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup2"
                      value="all"
                      onChange={(e) => setQuestion("all")}
                      checked={question === "all" ? true : false}
                    />
                  </div>
                </div>
                <br />
                <br />
                <p tabIndex={0}> Select session type</p>
                <div className="warpper-session">
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Completed"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup1"
                      value="completed"
                      onChange={(e) => setQuestionType("completed")}
                      checked={questionType === "completed" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Draft"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup1"
                      value="not-completed"
                      onChange={(e) => setQuestionType("not-completed")}
                      checked={questionType === "not-completed" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="All"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup1"
                      value="all"
                      onChange={(e) => setQuestionType("all")}
                      checked={questionType === "all" ? true : false}
                    />
                  </div>
                </div>
                <div className="wrap-csv">
                  <Button
                    btntype="button"
                    id="save"
                    text="Export CSV"
                    addClass="primary-btn btn-padding"
                    onClick={(e: any) => {
                      exportQuestionnaire();
                    }}
                  />
                </div>
              </div>
            </Accordion>
            <br />
            <Accordion head="Withings">
              <div className="accord-body">
                <div className="warpper-date">
                  <div>
                    <p tabIndex={0}>Start Date</p>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      className="form-control text-field-date"
                      name="startDate"
                      dropdownMode="select"
                      onChange={(e: Date) => {
                        setStartDate(e);
                        // if (
                        //   withingsType === "measure_v2_getintradayactivity" ||
                        //   withingsType === "sleep_v2_get"
                        // ) {
                        //   changeMaxDate(e);
                        //   setStartDate(e);
                        // }
                      }}
                      isIconVisible="true"
                      autoComplete="off"
                      placeholderText="Please select a date"
                      maxDate={new Date()}
                    />
                  </div>
                  <div>
                    <p tabIndex={0}>End Date</p>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={endDate}
                      className="form-control text-field-date"
                      name="startDate"
                      dropdownMode="select"
                      onChange={(e: Date) => setEndDate(e)}
                      isIconVisible="true"
                      autoComplete="off"
                      placeholderText="Please select a date"
                      maxDate={new Date(dateRange.max)}
                      minDate={new Date(startDate)}
                    />
                  </div>
                </div>
                <br />
                <p tabIndex={0}> Select type</p>
                <div className="warpper-session">
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Heart_V2_Get"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="heart_v2_get"
                      onChange={(e) => {
                        setWithingsType("heart_v2_get");
                        changeDate();
                      }}
                      checked={withingsType === "heart_v2_get" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Heart_V2_List"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="heart_v2_list"
                      onChange={(e) => {
                        setWithingsType("heart_v2_list");
                        changeDate();
                      }}
                      checked={withingsType === "heart_v2_list" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Measure_GetMeas"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="measure_getMeas"
                      onChange={(e) => {
                        setWithingsType("measure_getMeas");
                        changeDate();
                      }}
                      checked={
                        withingsType === "measure_getMeas" ? true : false
                      }
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Measure_v2_Getactivity"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="measure_v2_getactivity"
                      onChange={(e) => {
                        setWithingsType("measure_v2_getactivity");
                        changeDate();
                      }}
                      checked={
                        withingsType === "measure_v2_getactivity" ? true : false
                      }
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Measure_v2_Getintradayactivity"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="measure_v2_getintradayactivity"
                      onChange={(e) => {
                        setWithingsType("measure_v2_getintradayactivity");
                        changeDate();
                      }}
                      checked={
                        withingsType === "measure_v2_getintradayactivity"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Measure_v2_Getworkouts"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="measure_v2_getworkouts"
                      onChange={(e) => {
                        setWithingsType("measure_v2_getworkouts");
                        changeDate();
                      }}
                      checked={
                        withingsType === "measure_v2_getworkouts" ? true : false
                      }
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Sleep_v2_Get"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="sleep_v2_get"
                      onChange={(e) => {
                        setWithingsType("sleep_v2_get");
                        changeDate();
                      }}
                      checked={withingsType === "sleep_v2_get" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Sleep_v2_Getsummary"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name="agegroup3"
                      value="sleep_v2_getsummary"
                      onChange={(e) => {
                        setWithingsType("sleep_v2_getsummary");
                        changeDate();
                      }}
                      checked={
                        withingsType === "sleep_v2_getsummary" ? true : false
                      }
                    />
                  </div>
                </div>

                <div className="wrap-csv">
                  <Button
                    btntype="button"
                    id="save"
                    text="Export CSV"
                    addClass="primary-btn btn-padding"
                    onClick={() => exportWithings()}
                  />
                </div>
              </div>
            </Accordion>
            <br />
            <Accordion showAccordion={showAccordion} name='import' head="CSV import file">
              <div className="accord-body">
                <div className="accord-body-csv">
                  <CSVReader
                    fileSelected={file}
                    handleOnChange={handleOnChange}
                    onChange={""}
                    handleOnSubmit={handleOnSubmit}
                  />
                  <br />
                </div>
              </div>
            </Accordion>
            <br />
            <br />
          </div>
        </section>

        <DialogPopup
          open={dialogOpen}
          type='import'
          onRequestClose={() => {
            setFile("");
            setDialogOpen(!dialogOpen);
          }}
          redirectTo={redirect}
          hidePopup={hidepopup}

          showIcon
          closeOnOutsideClick
        >
          <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            redirectTo={redirect}
            hidePopup={hidepopup}
            setPopup={setPopup}
            type='import'
            onRequestClose={() => {
              setFile("");
              setDialogOpen(!dialogOpen);
            }}
          />
        </DialogPopup>

        <Loading status={loading} />
        <ToastContainer />
      </main>
    </ClinicianLayout>
  );
};
