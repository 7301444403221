import React, { Fragment, useState } from "react";
import { Button } from "../components/Button/Button";
import { TextInput } from "../components/TextInput/TextInput";
import { ReactComponent as SalfordLogo } from "../assets/svg/SalfordLogo.svg";
import { ReactComponent as NCAlliance } from "../assets/svg/NorthernCareAlliance.svg";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import Loading from "../components/Loading/Loading";
import "../styles/Login.css";

import apiFunctions from "../services/api-service";
import appUrls from "../services/AppURLs";

import { useFormik } from "formik";
import * as Yup from "yup";
import validationErrors from "../services/ValidationSchema";
import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "../assets/svg/backarrow.svg";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";

interface ForgotPasswordProps {}

const defaultFormVal: any = {
  email: "",
};

const rejectReasonSchema = Yup.object().shape({
  email: Yup.string()
    .email(validationErrors[1028])
    .required(validationErrors[1029]),
});

export const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const [formdata, setFormData] = useState({ email: "" });
  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const [gobackurl, setGoBackUrl] = useState("/user/login");
  const [dialogOpen, setDialogOpen] = useState(false);

  const formik = useFormik({
    initialValues: defaultFormVal,
    validationSchema: rejectReasonSchema,

    onSubmit: async (values: any) => {
      const payload: any = { ...values };
      payload.email= payload.email.toLowerCase()
      Forgot(payload);
    },
  });

  const Forgot = (payload: any) => {
    setLoading(true);
    try {
      apiFunctions
        .post(
         
            "/api/auth/send-reset-password-mail",
          payload
        )
        .then(async (_result) => {
          // window.location.href = appUrls.user.login;
          setLoading(false);
          SetPopupcontent({
            status: "Success!",
            message: _result.data.msg,
          });
          setPopup(true);
          setHidepopup(false);
          setDialogOpen(true);

        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
          SetPopupcontent({
            status: "Failed!",
            message: err.response.data.message
              ? err.response.data.message
              : err.response.data.error.error.msg,
          });
          setPopup(true);
          setHidepopup(true);
          setDialogOpen(true);

        });
    } catch (error: any) {
      setLoading(false);
      SetPopupcontent({
        status: "Failed!",
        message: error.response.data.message
        ? error.response.data.message :error.response.data.error.message,
      });
      setPopup(true);
      setHidepopup(true);
      setDialogOpen(true);
      console.log(error.response, "errorresposne");
    }
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formdata,
      // Trimming any whitespace
      [e.target.id]: e.target.value.trim(),
    });
  };
  return (
    <Fragment>
      <div className="main-section">
        <div className="block-center">
          <form action="submit" className="" onSubmit={formik.handleSubmit}>
            <span>
              <SalfordLogo />
            </span>
            <br />
            <br />
            <h4 tabIndex={0} className="wrap-content-center">Forgot Password</h4>
            <br /><br />
            <p tabIndex={0}>Enter your email address to reset password</p>
            <br />
            <TextInput
              type="text"
              placeholder="Enter your email address"
              id="email"
              name="email"
              // values={formdata.email}
              // onChange={handleChange}
              onChange={formik.handleChange}
              errors={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
              values={formik.values.email}
            />
            <br />
            <div className="wrap-content-center">
              <Button
                btntype="submit"
                text="Send"
                addClass="primary-btn btn-login"
                // onClick={Forgot}
                onClick={() => {}}
              />
            </div>
            <br />
          
            <div className="forgot-back-arrow">
                <Link to={gobackurl} className="back-arrow">
                  <div>
                    <BackArrow />
                  </div>
                  <p> Back to log in</p>
                </Link>
            </div>
          </form>
        </div>

        <DialogPopup
          open={dialogOpen}
          onRequestClose={() => {
            setDialogOpen(!dialogOpen);
          }}
          showIcon
          closeOnOutsideClick
          redirectTo={appUrls.user.login}
            hidePopup={hidepopup}
        >
            <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            redirectTo={appUrls.user.login}
            hidePopup={hidepopup}
            setPopup={setPopup}
            onRequestClose={() => {
              setDialogOpen(!dialogOpen);
            }}
          />
        </DialogPopup>

        <Loading status={loading} />       
      </div>
      <div className="block-bottom">
          <NCAlliance tabIndex={0} aria-label="NHS FOOTER LOGO"/>
        </div>
    </Fragment>
  );
};
