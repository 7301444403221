import React,{ useEffect,useState }from 'react';

import {Navigate ,Outlet} from 'react-router-dom';
import {  checkUserAuthentication} from '../services/api/AuthAPI';



export default function ProtectedRoutes_1() {
 const [isLoading, setIsLoading] = useState(true);
 const [userData, setUserData]=useState<any>();
    useEffect(() => {
        fetchData();
      }, []);

      async function fetchData() {
        try {
          const response = await checkUserAuthentication();    
          setUserData(response.data.result);
          setIsLoading(false);

        } catch (error) {
          setIsLoading(false);
          console.error(error);
        }
      }
    
      if (isLoading) {
        // Display a loading indicator while waiting for the API response
        return <div>Loading...</div>;
      }else{
          const role:any = userData && userData.role;
          const auth = userData && userData.accessToken ? true : false; // determine if authorized, from context or however you're doing 
          return (userData != undefined && !auth) ? <Outlet /> : (role === 3 ? <Navigate to="/dashboard" />:
          (role === 2 ? <Navigate to="/cliniciandashboard" />:<Navigate to="/admindashboard" />)
          );
      }
}

