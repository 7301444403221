import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../components/Layout/Layout";
import { QbCard } from "../components/QbCard/QbCard";
import { TextInput } from "../components/TextInput/TextInput";
import "../styles/Questionnaires.css";
import moment from "moment";

import { useSearchParams } from "react-router-dom";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";
import apiFunctions from "../services/api-service";
import Loading from "../components/Loading/Loading";
import useAuthContext from "../context/providers/AuthContext";
import { Accordion } from "../components/Accordion/Accordion";

interface QuestionnairesProps {}

export const Questionnaires: React.FC<QuestionnairesProps> = ({}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let qbSession: any = process.env.REACT_APP_QB_SESSIONS || 3;
  let time: any = process.env.REACT_APP_FD_NEXT_START_TIME_QB || 1;
  let types: any = process.env.REACT_APP_FD_NEXT_START_TYPE || "month";

  const [newSets, setNewSet] = useState([]);
  const [completedSets, setCompletedSet] = useState([]);
  const [draftSets, setDraftSet] = useState([]);

  const [searchNewSets, setSearchNewSet] = useState([]);
  const [searchDraftSets, setSearchDraftSet] = useState([]);
  const [searchCompletedSets, setSearchCompletedSet] = useState([]);

  const [currentSession, setCurrentSession] = useState([]);
  const [CMP_Session, setCompletedSession] = useState<any>([]);
  const [NT_CMP_Session, setNotCompletedSession] = useState<any>([]);

  const [sessionStart, setSessionStart] = useState(1);
  const [sessionList, setSessionList] = useState<any>([]);
  const [activeSession, setActiveSession] = useState<any>([]);

  const [loading, setLoading] = useState(true);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const [buttonText, setButtonText] = useState("CONTINUE");
  const [dialogOpen, setDialogOpen] = useState(false);

  const [accordion, setAccordion] = useState(0);
  const {
    state: { participant },
  } = useAuthContext();
  const [patientView, setpatientView] = useState(false);
  const [serchText, setSearchText] = useState("");

  const getQB_Sessions = () => {
    let arr = [];
    for (let i = 1; i <= qbSession; i++) {
      arr.push(i);
    }
    setSessionList(arr);
  };

  useEffect(() => {
    getQB_Sessions();
    getSessions();
  }, []);

  useEffect(() => {
    getUserQuestionsets();
  }, [sessionStart]);

  async function getSessions() {
    try {
      let URL =
        `/api/questionnaire/get-all-question-sessions`;
      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `?service_user_id=${searchParams.get("patientId")}`;
      }
      await apiFunctions
        .get(URL)
        .then((_result) => {
          const sessionInformation = _result.data.result.sessions;
          let completedSession: any = [];
          sessionInformation.map((data: any) => {
            completedSession.push(parseInt(data.questionnaire_session));
          });
          let complete: any = [];
          let not_completed: any = [];
          sessionInformation.map((res: any) => {
            if (res.session_status === "completed") {
              complete.push(res);
            }
            if (res.session_status === "not-completed") {
              not_completed.push(res);
            }
          });
          if (!not_completed.length && complete.length) {
            let count = Math.max(...completedSession) + 1;
            completedSession.push(count);
          }
          setNotCompletedSession(not_completed);
          setCompletedSession(complete);
          setActiveSession(completedSession);
        })
        .catch((err) => {
          setLoading(false);
          console.log("_result", err);
        });
    } catch (error: any) {
      console.log(error.response, "errorresposne");
    }
  }
  async function getUserQuestionsets() {
    try {
      setLoading(true);
      let URL =
        
        `/api/questionnaire/get-all-question-sets?session=${sessionStart}&sessions_start=${
          NT_CMP_Session.length <= 0 &&
          accordion > 1 &&
          CMP_Session.filter(
            (res: any) => parseInt(res.questionnaire_session) === accordion
          ).length === 0
            ? true
            : false
        }`;

      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `&service_user_id=${searchParams.get("patientId")}`;
      }
      await apiFunctions
        .get(URL)
        .then((_result) => {
          //   console.log("_result", _result.data.result);
          setNewSet(_result.data.result.newSet);
          setDraftSet(_result.data.result.draftSet);
          setCompletedSet(_result.data.result.completed);
          setCurrentSession(_result.data.result.sessions);
          //   const result = getSessionList(_result.data.result.allSessions)

          setSearchNewSet(_result.data.result.newSet);
          setSearchDraftSet(_result.data.result.draftSet);
          setSearchCompletedSet(_result.data.result.completed);
          // setAccordion(true)
          getSessions();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("_result", err);
        });
    } catch (error: any) {
      console.log(error.response, "errorresposne");
    }
  }

  // Set session start
  const SelectSession = (list: number) => {
    setSessionStart(list);
    setAccordion(list);
  };
  // SET popup for session start
  const showPopup = (startDate: any, date: any, list: any) => {
    if (new Date(startDate).getTime() >= new Date().getTime()) {
      const Tilldate = moment(new Date(date))
        .add(time, types)
        .format("DD/MM/YYYY");
      SetPopupcontent({
        status: "Note!",
        message: `Your next session will start from ${Tilldate}. `,
      });
      // setPopup(true);
      setHidepopup(true);
      setDialogOpen(true);
      setAccordion(0);
    }
  };
  return (
    <Layout>
      <main className="main-container">
              {!CMP_Session.length && searchParams.get("patientId")?(
               <section className="overview-outer-container">
               {searchParams.get("patientId") && (
                 <div className="username-data">
                   <h5 tabIndex={0} className="particitant-w">
                     Participant: {participant && `${participant?.name || participant?.label}`}
                   </h5>
                 </div>
               )}
               <section className="no-data" tabIndex={0}>
                 The user does not complete any session.
               </section>
             </section>
              ): <section className="outer-container">
           
              {!searchParams.get("patientId") && (
                <>
                  <h5 tabIndex={0}>
                    The questionnaire builder has 12 sessions.
                    Once you complete a session, next session will start after a month.
                  </h5>
                  <br />
                </>
              )}
              {searchParams.get("patientId") && (
                 <div className="username-data">
                   <h5 tabIndex={0} className="particitant-w">
                     Participant: {participant && `${participant?.name || participant?.label}`}
                   </h5>
                 </div>
               )}
              <br />
              {sessionList.map((list: number, i: number) => (
                <>
                  {searchParams.get("patientId") &&
                  searchParams.get("patientId") !== "null" ? (
                    CMP_Session &&
                    CMP_Session.some(
                      (item: any) => parseInt(item.questionnaire_session) === list
                    ) ? (
                      <Accordion
                        head={`Month - ${list}`}
                        addclass={
                          activeSession.includes(list)
                            ? searchParams.get("patientId") &&
                              searchParams.get("patientId") !== "null"
                              ? CMP_Session &&
                                CMP_Session.some(
                                  (item: any) =>
                                    parseInt(item.questionnaire_session) === list
                                )
                                ? ""
                                : "accordion-disabale"
                              : ""
                            : "accordion-disabale"
                        }
                        list={list}
                        SelectSession={SelectSession}
                        showAccordion={accordion === list ? true : false}
                        CMP_Session={CMP_Session}
                        NT_CMP_Session={NT_CMP_Session}
                        showPopup={showPopup}
                        type={"questionnaire"}
                      >
                        <div className="accord-body">
                          <div className="search-block">
                            <TextInput
                              icon="search"
                              label="Search"
                              type="text"
                              placeholder="Search for your questionnaire"
                              id="search"
                              name="search"
                              values={serchText}
                              onChange={(e) => {
                                setSearchText(e.target.value);
                                if (e.target.value.length) {
                                  setNewSet([
                                    ...searchNewSets.filter((res: any) =>
                                      res.name
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase())
                                    ),
                                  ]);
                                  setDraftSet([
                                    ...searchDraftSets.filter((res: any) =>
                                      res.name
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase())
                                    ),
                                  ]);
                                  setCompletedSet([
                                    ...searchCompletedSets.filter((res: any) =>
                                      res.name
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase())
                                    ),
                                  ]);
                                } else {
                                  setNewSet([...searchNewSets]);
                                  setDraftSet([...searchDraftSets]);
                                  setCompletedSet([...searchCompletedSets]);
                                }
                              }}
                            />
                            {searchParams.get("patientId") && (
                              <div className="qb-user-name">
                                <h5 tabIndex={0} className="particitant-w">
                                  Participant:{" "}
                                  {participant &&
                                    `${participant?.name || participant?.label}`}
                                </h5>
                              </div>
                            )}
                          </div>
                          <br />
                          {(!searchParams.get("patientId") ||
                            searchParams.get("patientId") === "null") && (
                            <>
                              <QbCard
                                questions={newSets}
                                title="New  Questionnaires"
                                patientView={patientView}
                                completed={false}
                                name="new"
                                session={currentSession}
                                id={i + "new"}
                              />
                              <QbCard
                                questions={draftSets}
                                title="Drafted Questionnaires"
                                patientView={patientView}
                                completed={true}
                                name="draft"
                                session={currentSession}
                                id={i + "draft"}
                              />
                            </>
                          )}
                          <QbCard
                            questions={completedSets}
                            title="Completed Questionnaires"
                            patientView={patientView}
                            completed={true}
                            name="completed"
                            session={currentSession}
                            id={i + "completed"}
                          />
                        </div>
                      </Accordion>
                    ) : (
                      ""
                    )
                  ) : (
                    <Accordion
                      head={`Month - ${list}`}
                      addclass={
                        activeSession.includes(list)
                          ? searchParams.get("patientId") &&
                            searchParams.get("patientId") !== "null"
                            ? CMP_Session &&
                              CMP_Session.some(
                                (item: any) =>
                                  parseInt(item.questionnaire_session) === list
                              )
                              ? ""
                              : "accordion-disabale"
                            : ""
                          : "accordion-disabale"
                      }
                      list={list}
                      SelectSession={SelectSession}
                      showAccordion={accordion === list ? true : false}
                      CMP_Session={CMP_Session}
                      NT_CMP_Session={NT_CMP_Session}
                      showPopup={showPopup}
                      type={"questionnaire"}
                    >
                      <div className="accord-body">
                        <div className="search-block">
                          <TextInput
                            icon="search"
                            label="Search"
                            type="text"
                            placeholder="Search for your questionnaire"
                            id="search"
                            name="search"
                            values={serchText}
                            onChange={(e) => {
                              setSearchText(e.target.value);
                              if (e.target.value.length) {
                                setNewSet([
                                  ...searchNewSets.filter((res: any) =>
                                    res.name
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase())
                                  ),
                                ]);
                                setDraftSet([
                                  ...searchDraftSets.filter((res: any) =>
                                    res.name
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase())
                                  ),
                                ]);
                                setCompletedSet([
                                  ...searchCompletedSets.filter((res: any) =>
                                    res.name
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase())
                                  ),
                                ]);
                              } else {
                                setNewSet([...searchNewSets]);
                                setDraftSet([...searchDraftSets]);
                                setCompletedSet([...searchCompletedSets]);
                              }
                            }}
                          />
                          {searchParams.get("patientId") && (
                            <div className="qb-user-name">
                              <h5 tabIndex={0} className="particitant-w">
                                Participant:{" "}
                                {participant &&
                                  `${participant?.name || participant?.label}`}
                              </h5>
                            </div>
                          )}
                        </div>
                        <br />
                        {(!searchParams.get("patientId") ||
                          searchParams.get("patientId") === "null") && (
                          <>
                            <QbCard
                              questions={newSets}
                              title="New  Questionnaires"
                              patientView={patientView}
                              completed={false}
                              name="new"
                              session={currentSession}
                              id={i + "new"}
                            />
                            <QbCard
                              questions={draftSets}
                              title="Drafted Questionnaires"
                              patientView={patientView}
                              completed={true}
                              name="draft"
                              session={currentSession}
                              id={i + "draft"}
                            />
                          </>
                        )}
                        <QbCard
                          questions={completedSets}
                          title="Completed Questionnaires"
                          patientView={patientView}
                          completed={true}
                          name="completed"
                          session={currentSession}
                          id={i + "completed"}
                        />
                      </div>
                    </Accordion>
                  )}
  
                  <br />
                </>
              ))}
  
              <DialogPopup
                open={dialogOpen}
                onRequestClose={() => {
                  setDialogOpen(!dialogOpen);
                }}
                hidePopup={hidepopup}
                showIcon
                closeOnOutsideClick
              >
                <CommonPopup
                  PopupSize="md"
                  PopContent={PopContent}
                  hidePopup={hidepopup}
                  text={buttonText}
                  onRequestClose={() => {
                    setDialogOpen(!dialogOpen);
                  }}
                />
              </DialogPopup>
            </section>}
       
        <Loading status={loading} />
      </main>
    </Layout>
  );
};
