import React from 'react';

import '../styles/Loading.css';

export interface LoadingProps {
  status?: boolean;
}

const Loading: React.FC<LoadingProps> = ( props ) => {

  const {status}=props;

  return (
    <>
      {status && (
        <div className="loading-wrapper">
          <svg className="spinner" viewBox="0 0 50 50">
            <circle
              className="path stroke-current text-primary-100"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke="currentColor"
              strokeWidth="5"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default Loading;
