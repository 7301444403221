import React from 'react';
import "../styles/Button.css";
import { ReactComponent as ArrowIcon } from "../../assets/svg/right-arrow.svg";
import { ReactComponent as Icon } from "../../assets/svg/left-icon.svg";

interface ButtonProps {
    text?: string;
    addClass?: string;
    btntype: 'submit' | 'button';
    disabled?: boolean;
    onClick?: any;
    show_icon?: boolean;
    id?: any;
    arrowIcon?: boolean;
    rightarrow?: boolean;
}

export const Button: React.FC<ButtonProps> = (
    {
        text,
        addClass,
        btntype,
        disabled,
        onClick,
        show_icon,
        id,
        arrowIcon,
        rightarrow
    }) => {
    const tabIndex = 0;
    return (
        <section className='btn-relative' id={id}>
            <button
                tabIndex={tabIndex}
                id={id}
                onClick={onClick}
                type={btntype === 'button' ? 'button' : 'submit'}
                className={`btn ${addClass}`}
                disabled={disabled}
            >
                {text}
                {rightarrow && <div className='back-icon'><Icon /></div>}
            </button>
            <span className='btn-absolute'>
                {arrowIcon && <ArrowIcon />}
            </span>

        </section>
    );
}
