import React, { useEffect, useRef, useState } from "react";
import "../styles/CSVReader.css";

import { useFormik } from "formik";
import * as Yup from "yup";
import { TextInput } from "../TextInput/TextInput";
import validationErrors from "../../services/ValidationSchema";
import { Button } from "../Button/Button";
import { ReactComponent as CSVDownload } from "../../assets/svg/CSVDownload.svg";

interface CSVReaderProps {
  handleOnChange?: any;
  onChange?: any;
  handleOnSubmit?:any
  filename?:any
  fileSelected :any,
}

export const CSVReader: React.FC<CSVReaderProps> = ({ handleOnChange ,handleOnSubmit,filename, fileSelected}) => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<any>('');
  const fileInputRef = useRef<any>(null);


  useEffect(() => {
      fileInputRef.current.value = null;
    
  }, [fileSelected])
  return (
    <>
      <div className="file-upload-blk">
        <div className="waiting-time-blk">
          <h5 tabIndex={0}>Choose a CSV data file</h5>
          {/* <br />

          <div className="footer-btn">
            <label htmlFor="fileUpload">
              Choose CSV File
              <input
                id="fileUpload"
                type="file"
                onChange={(e: any) => {
                  console.log(e.target.files[0]);
                  setFileName(e.target.files[0].name);
                  handleOnChange(e);
                }}
              />
            </label>
          </div> */}
        </div>
        <br />
        <form action="submit" className="">
          <span tabIndex={0} >Selected file:</span>
          <div>
            <div className="csv-input csv-icon">
              <TextInput
                type="text"
                placeholder=""
                id=""
                name="filename"
                values={!file ? '':fileName}
                disabled
              />
              <div className="csv-icon-file">
              <label htmlFor="fileUpload">
              <CSVDownload />
              <input
                ref={fileInputRef}
                id="fileUpload"
                type="file"
                aria-label="Attach csv document"
                onChange={(e: any) => {                  
                  setFile(e.target.files[0]);
                  setFileName(e.target.files[0].name);
                  handleOnChange(e);  
                }}
              />
              </label>

              </div>
            </div>
          </div>
          <br />
        
          <div className="btn-csv-center">
            <Button
              btntype="button"
              id="save"
              text="Import CSV file"
              addClass={`primary-btn btn-padding csv-btn  ${!file ? 'disabled':'' }`}
              disabled = {!file ? true:false }
              onClick={handleOnSubmit}

            />
          </div>
          <div> <h5>Note! </h5> Kindly note that the CSV file must carry uniqueIdentifier, firstName, lastName, email & password</div>
        </form>
        <br />
      </div>
    </>
  );
};
