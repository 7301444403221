import React, { useEffect, useState } from "react";
import { Button } from "../components/Button/Button";
import { Layout } from "../components/Layout/Layout";
import Loading from "../components/Loading/Loading";
import "../styles/FoodDiary.css";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import appUrls from "../services/AppURLs";
import apiFunctions from "../services/api-service";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import { useNavigate } from "react-router-dom";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";
import { Radio } from "../components/Radio/Radio";

interface FoodDiaryProps {
  disabled?: boolean;

}

export const FoodDiary: React.FC<FoodDiaryProps> = ({
  disabled = true,
}) => {
  let navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const [sessionInfo, setSessionInfo] = useState<any>({});
  const [redirectUrl, setRedirectUrl] = useState("");
  const [type, setType] = useState("");
  const [selectSession, setSelecteSession] = useState({
    session: "",
    day: "",
    date: "",
  });
  const [loading, setLoading] = useState(true);
  const [Popup, setPopup] = useState(false);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);

  const [sessionsCompleted, setSessionCompleted] = useState(false);
  const [sessionValue, setSesstionValue] = useState<any>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [questionDisabled, setQuestionDisabled] = useState(false)

  const [dotType, setDotType] = useState<any>({
    have_you_experienced:'',
    have_you_felt:'',
    have_you_found:''
  });

  // const getFood
  useEffect(() => {
    getUserSession();
  }, []);

  function getUserQuestionResponse(data:any) {
  
   return data.have_you_experienced !== null && data.have_you_felt !== null && data.have_you_found !== null  ? true : false
  }
  async function getUserSession() {
    try {
      let URL =  `/api/fooddiary/session`;
      await apiFunctions
        .get(URL)
        .then((_result) => {
          let result = _result.data.result;
          if (result) {
            if (
              result.completedSession &&
              result.completedSession.session >= 3
            ) {
              setSessionCompleted(true);
            } else {
              setSessionInfo({ ...result });

              setSesstionValue(
                result &&result.notCompletedSession&&
                  result.notCompletedSession.length &&
                  result.notCompletedSession[0].session
                  ? result.notCompletedSession[0].session
                  : ""
              );
              setQuestionDisabled(getUserQuestionResponse(result.FoodDiaryQuestion));
              setDotType({...result.FoodDiaryQuestion})
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);

      console.log(error.response, "errorresposne");
    }
  }
  const btnGroup = [
    {
      name: "Day 1 - Friday",
      session:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].session,
      session_uuid:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].uuid,
      day: 1,
      date:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        moment(sessionInfo.notCompletedSession[0].session_start_day).format(
          "MM/DD/YYYY"
        ),
    },

    {
      name: "Day 2 - Saturday",
      session:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].session,
      session_uuid:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].uuid,
      day: 2,
      date:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        moment(
          moment(sessionInfo.notCompletedSession[0].session_start_day).add(
            1,
            "d"
          )
        ).format("MM/DD/YYYY"),
    },
    {
      name: "Day 3 - Sunday",
      session:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].session,
      session_uuid:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].uuid,
      day: 3,
      date:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        moment(
          moment(sessionInfo.notCompletedSession[0].session_start_day).add(
            2,
            "d"
          )
        ).format("MM/DD/YYYY"),
    },
    {
      name: "Day 4 - Monday",
      session:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].session,
      session_uuid:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession &&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].uuid,
      day: 4,
      date:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        moment(
          moment(sessionInfo.notCompletedSession[0].session_start_day).add(
            3,
            "d"
          )
        ).format("MM/DD/YYYY"),
    },
    {
      name: "Day 5 - Tuesday",
      session:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].session,
      session_uuid:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        sessionInfo.notCompletedSession[0].uuid,
      day: 5,
      date:
        Object.keys(sessionInfo).length !== 0 &&sessionInfo.notCompletedSession&&
        sessionInfo.notCompletedSession.length &&
        moment(
          moment(sessionInfo.notCompletedSession[0].session_start_day).add(
            4,
            "d"
          )
        ).format("MM/DD/YYYY"),
    },
  ];
  async function addSession(data: any) {
    setLoading(true);
    let currentDateObj = new Date();
    let todayFriday = moment().day() === 5;
    currentDateObj.setDate(
      currentDateObj.getDate() - ((currentDateObj.getDay() + 2) % 7)
    );
    let payload = {
      session:
        Object.keys(sessionInfo).length !== 0 && sessionInfo.completedSession &&
        Object.keys(sessionInfo.completedSession).length !== 0
          ? sessionInfo.completedSession.session + 1
          : 1,
      session_start_day: todayFriday
        ? moment().toDate()
        : moment(currentDateObj).toDate(),
      session_status: "not-completed",
    };
    try {
      let URL =
        `/api/fooddiary/session-add`;
      await apiFunctions
        .post(URL, payload)
        .then((_result) => {
          getUserSession();
          updateQuestion()
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);
      console.log(error.response, "errorresposne");
    }
  }
  async function clickContinue(data: any) {
    if (data === "yes") {
      setLoading(true);
      setDialogOpen(!dialogOpen);
      await addSession("");
      setLoading(false);
      SetPopupcontent({
        status: "Success!",
        message: `Your session has started. `,
      });
      setPopup(true);
      setHidepopup(true);
      setType("");
      setDialogOpen(true);
    }
  }
  const addDiary = (item: any) => {
if(dotType && (dotType.have_you_experienced === null)
|| dotType.have_you_felt === null ||
dotType.have_you_found === null){
  SetPopupcontent({
    status: "Note!",
    message: `Before you start the food diary session you need to answer the above questions . `,
  });
  setPopup(true);
  setHidepopup(true);
  setDialogOpen(true);
}else{
    let completedDate: any = moment(
      sessionInfo &&
        sessionInfo.completedSession &&
        sessionInfo.completedSession.session_completed_day
    ).toDate();

    let time: any = process.env.REACT_APP_FD_NEXT_START_TIME || 1;
    let types: any = process.env.REACT_APP_FD_NEXT_START_TYPE || "days";
    completedDate = moment(completedDate).add(time, types);

    if (
      sessionInfo &&
      sessionInfo.notCompletedSession &&
      sessionInfo.notCompletedSession.length > 0
    ) {
      navigate(
        appUrls.diaryquestions +
          `?session=${item.session}&day=${item.day}&date=${moment(
            item.date
          ).format("DD/MM/YYYY")}`
      );
    } else if (
      sessionInfo &&
      sessionInfo.completedSession &&
      new Date(completedDate).getTime() >= new Date().getTime()
    ) {
      const date = moment(
        new Date(
          sessionInfo.completedSession.session_completed_day
            ? sessionInfo.completedSession.session_completed_day
            : sessionInfo.completedSession.updatedAt
        )
      )
        .add(time, types)
        .format("DD/MM/YYYY");
      SetPopupcontent({
        status: "Note!",
        message: `Your next session will start from ${date}. `,
      });
      setPopup(true);
      setHidepopup(true);
      setDialogOpen(true);

      // setType('start')
    } else {
      let currentDateObj = new Date();
      let todayFriday = moment().day() === 5;
      currentDateObj.setDate(
        currentDateObj.getDate() - ((currentDateObj.getDay() + 2) % 7)
      );
      let date = todayFriday
        ? moment().format("YYYY-MM-DDTHH:mm:ss")
        : moment(currentDateObj).format("YYYY-MM-DDTHH:mm:ss");
      SetPopupcontent({
        status: "Note!",
        message: `Your session starts from friday. Do you want to start your session from ${moment(
          date
        ).format("DD/MM/YYYY")}? `,
      });
      setPopup(true);
      setDialogOpen(true);
      setHidepopup(true);
      setType("start");
    }
    }
  };
  const questionnaries1 = [
    {
      label:'1. In the past week have you experienced any pain or discomfort in your abdomen after eating?',
      key:'have_you_experienced'
    },
    {
      label:'2. In the past week have you felt very full very soon after you started eating?',
      key:'have_you_felt'
    },
    {
      label:'3. In the past week have you found you have continued to feel full for a long time after eating?',
      key:'have_you_found'
    },
  ]
  // Update Food diary Questions
  const updateQuestion = async() =>{
    setLoading(true);
    const  URL = `/api/fooddiary/add-question`;
    const method = apiFunctions.post;
    try {
      await method(URL, dotType);
      getUserSession();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error.response, "errorresposne");
    }
  }
  return (
    <Layout>
      <main className="main-container">
        <section className="outer-container">
          <div className="inner-container">
            {/* <h3 tabIndex={0}>Note:</h3> <br /> */}
            {!sessionsCompleted && (
              <>
            <h5 tabIndex={0}>
              Before you start, please answer the following questions by
              selecting "Yes" or "No".
            </h5>
            <br /> 
            {questionnaries1 && questionnaries1.map((item:any,index:number)=>(
            <section className="view-data-group" key={index}>
              <label tabIndex={0}>{item.label}</label>           
              <br />            
              <div className="pain-btn-block">
                <div className="">
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="Yes"
                      addClass={`w-100 pt-1`}
                      parentClass="block"
                      name={item.key}
                      value={item.key}
                      onChange={(e) => {
                        dotType[item.key]='yes'
                        setDotType({...dotType})
                      }}
                      disabled={questionDisabled}
                      checked={dotType[item.key] === "yes" ? true : false}
                    />
                  </div>
                  <div className="btn-space">
                    <Radio
                      type="radio"
                      label="No"
                      addClass="w-100 pt-1"
                      parentClass="block"
                      name={item.key}
                      value={item.key}
                      onChange={(e) => {
                        dotType[item.key]='no'
                        setDotType({...dotType})
                      }}
                      disabled={questionDisabled}
                      checked={dotType[item.key] === "no" ? true : false}
                    />
                  </div>
                </div>
              </div>
            </section>
            )) }

            <div className="hr-line" />
           
              <h5 tabIndex={0}>
                The Food Diary section is of 3 sessions, each for a span of 5
                days. It starts on Friday and ends on Tuesday
              </h5>
              </>
            )}
            <section>
              {!sessionsCompleted && (
                <>
                  <br />
                  <br />
                  <div className="title-block">
                    <h5 tabIndex={0}>My Food Entries</h5>
                    {/* <h5>2022</h5> */}
                  </div>
                </>
              )}

              {!sessionsCompleted && (
                <div>
                  <br />
                  <br />
                  <div className="seession-block">
                    <div className="session-info" tabIndex={0}>
                      {" "}
                      Session{" - "}
                      {Object.keys(sessionInfo).length !== 0 && sessionInfo.completedSession && Object.keys(sessionInfo.completedSession) &&
                      Object.keys(sessionInfo.completedSession).length !== 0
                        ? sessionInfo.completedSession.session + 1
                        : 1}
                    </div>
                  </div>

                  <div className="sub-title-block">
                    {btnGroup.map((item: any, idx: any) => {
                      return (
                        <>
                          {/* {moment(item.date)} */}
                          <Button
                            id={idx}
                            btntype="button"
                            text={
                              item.name +
                              (item.date
                                ? " - " + moment(item.date).format("DD/MM/YYYY")
                                : "")
                            }
                            addClass={`primary-btn btn-diary food-seession-btn btn-session-day ${
                              item.date &&
                              (moment(item.date) <= moment(new Date())
                                ? ""
                                : "disabled")
                            } `}
                            onClick={() => addDiary(item)}
                            disabled={
                              item.date &&
                              (moment(item.date) <= moment(new Date())
                                ? false
                                : true)
                            }
                            arrowIcon
                          />
                        </>
                      );
                    })}
                  </div>
                  <br />
                  <br />
                  <div className="seession-block">
                    {sessionInfo &&
                      sessionInfo.notCompletedSession &&
                      sessionInfo.notCompletedSession.length > 0 &&
                      sessionInfo.notCompletedSession[0].session !== 1 && (
                        <div>
                          <div className="session-disable"> Session - 1</div>
                          <br />
                        </div>
                      )}
                    {sessionInfo &&
                      sessionInfo.notCompletedSession &&
                      sessionInfo.notCompletedSession.length > 0 &&
                      sessionInfo.notCompletedSession[0].session !== 2 && (
                        <div>
                          <div className="session-disable"> Session - 2</div>
                          <br />
                        </div>
                      )}
                    {sessionInfo &&
                      sessionInfo.notCompletedSession &&
                      sessionInfo.notCompletedSession.length > 0 &&
                      sessionInfo.notCompletedSession[0].session !== 3 && (
                        <div>
                          <div className="session-disable"> Session - 3</div>
                          <br />
                        </div>
                      )}
                  </div>
                  {sessionValue &&
                    sessionInfo.diaryRes &&
                    sessionInfo.diaryRes.count > 0 && (
                      <div className="sub-title-block overview">
                        <Button
                          btntype="button"
                          id="draft"
                          text="Go to overview "
                          addClass="primary-btn btn-padding overview-btn"
                          onClick={() => {
                            navigate(
                              appUrls.fooddiaryoverview +
                                `?session=${sessionInfo.notCompletedSession[0].session}&day=1`
                            );
                          }}
                        />
                      </div>
                    )}
                </div>
              )}
            </section>
            {sessionsCompleted && (
              <div className="no-data1">
                <h4>Congratulations!!</h4>
                <br />
                <p>You have completed all your Food Diary sessions.</p>
              </div>
            )}
          </div>

          <DialogPopup
            open={dialogOpen}
            onRequestClose={() => {
              setDialogOpen(!dialogOpen);
            }}
            showIcon
            closeOnOutsideClick
          >
            <CommonPopup
              PopupSize="md"
              PopContent={PopContent}
              redirectTo={redirectUrl}
              hidePopup={hidepopup}
              setPopup={setPopup}
              type={type}
              clickContinue={clickContinue}
              onRequestClose={() => {
                setDialogOpen(!dialogOpen);
              }}
            />
          </DialogPopup>

          <Loading status={loading} />
        </section>
      </main>
    </Layout>
  );
};
