import React, { useEffect, useState } from "react";

import { ReactComponent as DropdownArrow } from "../../assets/svg/Dropdown_Arrow.svg";
import "../styles/ProfileDropdown.css";

import apiFunctions, { removeToken } from "../../services/api-service";
import appUrls from "../../services/AppURLs";
import Loading from "../Loading/Loading";
interface ProfileDropdownProps {
  user: string;
  showProfileDropdown: boolean;
  onMenuChange: (e: boolean) => void;
}

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  user,
  showProfileDropdown,
  onMenuChange,
}) => {
  const tabIndex = 0;
  const [showprofile, setShowprofile] = useState(showProfileDropdown);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShowprofile(showProfileDropdown);
  }, [showProfileDropdown]);

  useEffect(() => {
    onMenuChange(showprofile);
  }, [showprofile]);

  const Logout = () => {
    setLoading(true);
    try {
      apiFunctions
        .delete( "/api/account/logout")
        .then(async (_result) => {
          // localStorage.setItem('role', _result.data.result.userRole.roleInfo.id)
          removeToken(
            _result &&
              _result.data &&
              _result.data &&
              _result.data.result.accessToken
              ? _result.data.result.accessToken
              : null,
            null
          );
          setLoading(false);
          window.location.href = appUrls.user.login;
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      console.log(error.response, "errorresposne");
    }
  };

  return (
    <div className="profile-block">
      <button
        tabIndex={tabIndex}
        className="dropdown-btn"
        type="button"
        onClick={() =>
          showprofile ? setShowprofile(false) : setShowprofile(true)
        }
      >
        <p className="dropdown-u-name">{user}</p>
          <DropdownArrow />
      </button>

      {showprofile && (
        <div className="dropdown-menu">
          <>
            <button tabIndex={tabIndex} type="button" onClick={Logout}>
              Log out
            </button>
          </>
        </div>
      )}
      <Loading status={loading} />
    </div>
  );
};
