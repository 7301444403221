import React, { useEffect, useState, createRef } from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/Layout/Layout";
import "../styles/FoodDiaryOverview.css";
import DataTable from "react-data-table-component";
import { Button } from "../components/Button/Button";
import { SelectDropdown } from "../components/SelectDropdown/SelectDropdown";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import Loading from "../components/Loading/Loading";
import appUrls from "../services/AppURLs";
import moment from "moment";

import { useSearchParams, useNavigate } from "react-router-dom";
import apiFunctions from "../services/api-service";
import { ReactComponent as BackArrow } from "../assets/svg/backarrow.svg";
import { BackArrowIcon } from "../components/BackArrow/BackArrowIcon";
import useAuthContext from "../context/providers/AuthContext";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";


interface FoodDiaryOverviewProps { }

export const FoodDiaryOverview: React.FC<FoodDiaryOverviewProps> = ({ }) => {
  let navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const [deletId, setDeleteId] = useState<any>();
  const [deleteType, setDeleteType] = useState<any>("");
  const [sessionIds, SetSessionIds] = useState<any>([]);
  const [noRecord, SetNoRecord] = useState(true);
  const [clinicianTable, setClinicianTable] = useState(searchParams.get("patientId"));
  // const [redirect, Setredirect] = useState(appUrls.fooddiaryoverview);
  const {
    state: { participant },
  } = useAuthContext();

  const [sessionDay, setSessionDay] = useState<any>({
    session: Number(searchParams.get('session')),
    day: searchParams.get('patientId') ? 1 : Number(searchParams.get('day')),
  });
  const [selectedDate, setSelectedDate] = useState<any>('')
  const [diaryInfo, setDiaryInfo] = useState<any>({
    food: [],
    drink: [],
    supplement: []
  });

  const [sessionInfo, setSessionInfo] = useState<any>();

  let sessionList = [
    {
      name: "Session 1",
      id: 1,
    },
    {
      name: "Session 2",
      id: 2,
    },
    {
      name: "Session 3",
      id: 3,
    },
  ];

  let columns = [
    {
      name: "What",
      width: '250px',
      selector: (row: any) => row.what,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.what ? (row.what) : ''}
        </div>
      ),
    },
    {
      name: "Quantity",
      width: '250px',
      selector: (row: any) => row.quantity,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.quantity ? (row.quantity) : ''}
        </div>
      ),
    },
    {
      name: "When",
      selector: (row: any) => row.when,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.when ? (row.when) : ''}
        </div>
      ),
    },
    {
      name: "Description",
      width: '250px',
      selector: (row: any) => row.discription,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.discription ? (row.discription) : ''}
        </div>
      ),
    },
    {
      name: "Action",
      width: '180px',
      cell: (row: any) => (
        <div className="view-btn">
          <div className="view-btn-margin">
            <Button
              btntype="button"
              id="food"
              text="Update"
              addClass="primary-btn btn-padding btn-view-remove"
              onClick={() => {
                viewDiary(row, sessionInfo[0]);
              }}
            />
          </div>
          <div>
            <Button
              btntype="button"
              id="food"
              text="Remove"
              addClass="primary-btn btn-padding btn-diary btn-view-remove"
              onClick={() => {
                deletePopup(row);
                setDialogOpen(true);
              }}
            />
          </div>
        </div>
      ),
    },
  ];
  let columnsDrink = [
    {
      name: "What",
      width: '250px',
      selector: (row: any) => row.what,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.what ? (row.what) : ''}
        </div>
      ),
    },
    {
      name: "Quantity",
      width: '250px',
      selector: (row: any) => row.quantity,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.quantity ? (row.quantity) : ''}
        </div>
      ),
    },
    {
      name: "When",
      selector: (row: any) => row.when,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.when ? (row.when) : ''}
        </div>
      ),
    },
    {
      name: "Description",
      width: '250px',
      selector: (row: any) => row.discription,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.discription ? (row.discription) : ''}
        </div>
      ),
    },
    {
      name: "Action",
      width: '180px',
      cell: (row: any) => (
        <div className="view-btn">
          <div className="view-btn-margin">
            <Button
              btntype="button"
              id="drink"
              text="Update"
              addClass="primary-btn btn-padding btn-view-remove"
              onClick={() => {
                viewDiary(row, sessionInfo[0]);
              }}
            />
          </div>
          <div>
            <Button
              btntype="button"
              id="drink"
              text="Remove"
              addClass="primary-btn btn-padding btn-diary btn-view-remove"
              onClick={() => {
                deletePopup(row);
                setDialogOpen(true);
              }}
            />
          </div>
        </div>
      ),
    },
  ];
  
  let columnsSupplement = [
    {
      name: "What",
      width: '250px',
      selector: (row: any) => row.what,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.what ? (row.what) : ''}
        </div>
      ),
    },
    {
      name: "Quantity",
      width: '250px',
      selector: (row: any) => row.quantity,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.quantity ? (row.quantity) : ''}
        </div>
      ),
    },
    {
      name: "When",
      selector: (row: any) => row.when,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.when ? (row.when) : ''}
        </div>
      ),
    },
    {
      name: "Description",
      width: '250px',
      selector: (row: any) => row.discription,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.discription ? (row.discription) : ''}
        </div>
      ),
    },
    {
      name: "Action",
      width: '180px',
      cell: (row: any) => (
        <div className="view-btn">
          <div className="view-btn-margin">
            <Button
              btntype="button"
              id="supplement"
              text="Update"
              addClass="primary-btn btn-padding btn-view-remove"
              onClick={() => {
                viewDiary(row, sessionInfo[0]);
              }}
            />
          </div>
          <div>
            <Button
              btntype="button"
              id="supplement"
              text="Remove"
              addClass="primary-btn btn-padding btn-diary btn-view-remove"
              onClick={() => {
                deletePopup(row);
                setDialogOpen(true);
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  // Get Food Diary Records
  useEffect(() => {
    getUserQuestions();
  }, [sessionDay.day, sessionDay.session])


  async function getUserQuestions() {
    try {
      let URL =  `/api/fooddiary/get-record?session=${sessionDay.session ? sessionDay.session : ''}&day=${sessionDay.day ? sessionDay.day : ''}`;
      const patientId = searchParams.get('patientId');
      if (patientId && patientId !== 'null') {
        URL += `&service_user_id=${patientId}`
      }
      await apiFunctions
        .get(URL,)
        .then((_result) => {
          let result = _result.data.result;
          setLoading(false)
          if (result.completedSession) {
            SetNoRecord(false)
            setSessionInfo(result.sessionRes[0])
            if (searchParams.get('patientId')) {
              SetSessionIds(result.completedSession.map((res: any) => {
                return res.session
              }));
              setSessionDay((prevState: any) => ({
                ...prevState,
                session: result.sessionRes[0].session,
                // day:1
              }));
            }
            setDiaryInfo({
              food: result.Food,
              drink: result.Drink,
              supplement: result.Supplement
            })
            setSelectedDate((setDate(result.sessionRes[0].session_start_day)))
          }
        })
        .catch((err) => {
          SetPopupcontent({ status: 'Failed', message: err.msg })
          setPopup(true)
          setHidepopup(true)
          setLoading(false);
          console.log("error", err);
        })
    } catch (error: any) {
      SetPopupcontent({ status: 'Failed', message: error.response.msg })
      setPopup(true)
      setHidepopup(true)
      setLoading(false);
      console.log(error.response, 'errorresposne')
    }
  }

  const setDate = (date: any) => {
    let day = Number(searchParams.get('day'));
    return day === 1 ?
      moment(date).format("DD/MM/YYYY") : moment(moment(date).add(day, "d")).format("DD/MM/YYYY")
  }

  const FoodList = [
    {
      name: `Day 1 - Friday - ${sessionInfo && moment(sessionInfo.session_start_day).format("DD/MM/YYYY")}`,
      day: 1,
      date: sessionInfo && moment(sessionInfo.session_start_day).format("DD/MM/YYYY")
    }, {
      name: `Day 2 - Saturday - ${sessionInfo && moment(moment(sessionInfo.session_start_day).add(1, "d")).format("DD/MM/YYYY")}`,
      day: 2,
      date: sessionInfo && moment(moment(sessionInfo.session_start_day).add(1, "d")).format("DD/MM/YYYY"),
    },
    {
      name: `Day 3 - Sunday - ${sessionInfo && moment(moment(sessionInfo.session_start_day).add(2, "d")).format("DD/MM/YYYY")}`,
      day: 3,
      date: sessionInfo && moment(moment(sessionInfo.session_start_day).add(2, "d")).format("DD/MM/YYYY"),
    },
    {
      name: `Day 4 - Monday - ${sessionInfo && moment(moment(sessionInfo.session_start_day).add(3, "d")).format("DD/MM/YYYY")}`,
      day: 4,
      date: sessionInfo && moment(moment(sessionInfo.session_start_day).add(3, "d")).format("DD/MM/YYYY"),
    },
    {
      name: `Day 5 - Tuesday - ${sessionInfo && moment(moment(sessionInfo.session_start_day).add(4, "d")).format("DD/MM/YYYY")}`,
      day: 5,
      date: sessionInfo && moment(moment(sessionInfo.session_start_day).add(4, "d")).format("DD/MM/YYYY"),
    },
  ];
  // Delete Record
  const deletePopup = (data: any) => {
    setDeleteType('delete')
    setDeleteId(data.uuid)
    setLoading(false);
    SetPopupcontent({ status: 'Remove', message: "Are you sure you want to remove this entry?" })
    setPopup(true)
    setHidepopup(true)

  }
  async function deleteDiaryRecord() {

    setLoading(true);
    setDialogOpen(!dialogOpen)
    try {
      await apiFunctions
        .delete(
          
          `/api/fooddiary/delete/${deletId}`
        )
        .then((_result) => {
          setDialogOpen(dialogOpen);
          setDeleteType("");
          // Setredirect('')
          SetPopupcontent({
            status: "Success",
            message: _result.data.result.msg,
          });
          setPopup(true);
          setHidepopup(true);
          getUserQuestions();
          setLoading(false);
        })
        .catch((err) => {
          console.log("error", err);
          setDialogOpen(dialogOpen);
          setDeleteType("");
          SetPopupcontent({ status: "Failed", message: err.msg });
          setPopup(true);
          setHidepopup(true);
          setLoading(false);
        });
    } catch (error: any) {
      setDialogOpen(dialogOpen);
      console.log(error.response, "errorresposne");
      setDeleteType("");
      SetPopupcontent({ status: "Failed", message: error.msg });
      setPopup(true);
      setHidepopup(true);
      setLoading(false);
    }
  }
  if (searchParams.get("patientId")) {
    sessionList = sessionList.filter((res) => {
      return sessionIds.includes(res.id);
    });
    columns = columns.filter((res) => {
      return res.name !== "Action";
    });
    columnsDrink = columnsDrink.filter((res) => {
      return res.name !== "Action";
    });
    columnsSupplement = columnsSupplement.filter((res) => {
      return res.name !== "Action";
    });
  }
  const viewDiary = (diary: any, session: any) => {
    navigate(
      appUrls.diaryquestions +
      `?session=${sessionInfo.session}&day=${diary.day}&uuid=${diary.uuid}&date=${selectedDate}`
    );
  };

  return (
    <Layout>
      <main className="main-container">
        {!loading && <div>
          {!noRecord && (
            <section className="overview-outer-container">
              <section className="bg-session-view">
                <section>
                  {!searchParams.get("patientId") && (
                    <BackArrowIcon
                      title="Back to food entries"
                      toUrl={appUrls.fooddiary}
                    />
                  )}
                </section>
                <div className="h-4" />
                <p tabIndex={0}>View your food diary entries</p>
                <div className="h-8" />
                <div className="session-view">
                  <div className="session-flex">
                    <div className="session-day">
                      <SelectDropdown
                        type="text"
                        name="center"
                        label=""
                        placeholder="Select date"
                        optionsArray={FoodList.map((item: any) => {
                          return {
                            value: item.day,
                            label: item.name,
                            date: item.date,
                          };
                        })}
                        onChange={(e: any) => {
                          setSessionDay((prevState: any) => ({
                            ...prevState,
                            day: e.value,
                          }));
                          setSelectedDate(e.date);
                        }}
                        value={sessionDay.day}
                      />
                    </div>
                    {searchParams.get("patientId") && (
                      <div className="session-day">
                        <SelectDropdown
                          type="text"
                          name="center"
                          label=""
                          placeholder="Select Session"
                          optionsArray={sessionList.map((item: any) => {
                            return { value: item.id, label: item.name };
                          })}
                          isDisabled={sessionList.length <= 1}
                          onChange={(e: any) => {
                            setSessionDay((prevState: any) => ({
                              ...prevState,
                              session: e.value,
                            }));
                          }}
                          disabled={true}
                          value={sessionDay.session}
                        />
                      </div>
                    )}

                    {!searchParams.get("patientId") && (
                      <div className="session-day">
                        <div tabIndex={0} className="session-label">
                          Session {sessionDay.session}
                        </div>
                      </div>
                    )}
                  </div>
                  {searchParams.get("patientId") && (
                    <div>
                      <h5 tabIndex={0} className="particitant-w">
                        Participant: {participant &&
                          `${participant?.name || participant?.label}`}
                      </h5>
                    </div>
                  )}
                </div>

                <div className="h-10" />
              </section>
              <section>
                <div className="title-view">
                  <h5 tabIndex={0}>Food</h5>
                </div>
                <div className="space-5" />
                <div className= {clinicianTable?"clinic-overview":"fd-overview"} >  
                  <DataTable columns={columns} data={diaryInfo.food} />
                </div>
              </section>
              <div className="space-10" />
              <section>
                <div className="title-view">
                  <h5 tabIndex={0}>Drink</h5>
                </div>
                <div className="space-5" />
                <div className= {clinicianTable?"clinic-overview":"fd-overview"}>
                  <DataTable columns={columnsDrink} data={diaryInfo.drink} />
                </div>
              </section>
              <div className="space-10" />
              <section>
                <div className="title-view">
                  <h5 tabIndex={0}>Supplement</h5>
                </div>
                <div className="space-5" />
                <div className= {clinicianTable?"clinic-overview":"fd-overview"}>
                  <DataTable columns={columnsSupplement} data={diaryInfo.supplement} />
                </div>
              </section>
              {!searchParams.get("patientId") && (
                <section className="bg-session-view">
                  <div className="h-10" />
                  <div className="text-center">
                    <Button
                      btntype="button"
                      id="draft"
                      text="Add another entry"
                      addClass="primary-btn btn-padding btn-other-entry"
                      onClick={() => {
                        navigate(appUrls.fooddiary);
                      }}
                    />
                  </div>
                  <div className="h-10" />
                </section>
              )}
            </section>
          )}
          {noRecord && (
            <section className="overview-outer-container">
              {searchParams.get("patientId") && (
                <div className="username-data">
                  <h5 tabIndex={0} className="particitant-w">
                    Participant: {participant && `${participant?.name || participant?.label}`}
                  </h5>
                </div>
              )}
              <section className="no-data" tabIndex={0}>
                The user does not complete any session.
              </section>
            </section>
          )}
        </div>}


        <DialogPopup
          open={dialogOpen}
          onRequestClose={() => {
            setDialogOpen(!dialogOpen);
          }}
          //  redirectTo={redirect}
          hidePopup={hidepopup}
          showIcon
          closeOnOutsideClick
        >
          <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            // redirectTo={redirect}
            hidePopup={hidepopup}
            setPopup={setPopup}
            deleteDiaryRecord={deleteDiaryRecord}
            type={deleteType}
            onRequestClose={() => {
              setDialogOpen(!dialogOpen);
            }}
          />

        </DialogPopup>



        <Loading status={loading} />
      </main>
    </Layout>
  );
};
