import React, { useEffect, useState, createRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Layout } from "../components/Layout/Layout";
import "../styles/FromQuestionnaire.css";
import { ReactComponent as BackArrow } from "../assets/svg/backarrow.svg";
import { Link } from "react-router-dom";
import { ChooseTags } from "../components/ChooseTags/ChooseTags";
// import RawData from "../Data/RawData";
import moment from "moment";
import { CheckBox } from "../components/CheckBox/CheckBox";
import { RangeSlider } from "../components/RangeSlider/RangeSlider";
// import { PictureCard } from "../components/PictureCard/PictureCard";
import { PictureCard_1 } from "../components/PictureCard/PictureCard_1";

import { Button } from "../components/Button/Button";
import { Radio } from "../components/Radio/Radio";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";
import Loading from "../components/Loading/Loading";
import appUrls from "../services/AppURLs";
import apiFunctions from "../services/api-service";
import useAuthContext from "../context/providers/AuthContext";

interface FromQuestionnaireProps {}

export const FromQuestionnaire: React.FC<FromQuestionnaireProps> = () => {
  //get query params
  const PictureElement = React.createRef();
  let [searchParams, setSearchParams] = useSearchParams();
  const [questionSet, setquestionSet] = useState<any>([]);
  const [questionSession, setquestionSession] = useState<any>({
    completed: [],
    not_completed: [],
  });
  const [completedSet, setCompletedSet] = useState<any>([]);
  const [questionsAndAnswer, setquestionsAndAnswer] = useState<any>([]);
  const [dotType, setDotType] = useState("pain");
  const [popupType, setPopupType] = useState("");
  const [clear, setClear] = useState(false);

  const [imageMark, setImageMark] = useState<any>([]);
  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const [buttonText, setButtonText] = useState("CONTINUE");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [direct, setDirect] = useState(appUrls.questionnaires);

  const {
    state: { participant },
  } = useAuthContext();

  const [gobackurl, setGoBackUrl] = useState("/questionnaires");
  const [clinicianView, setClinicianViw] = useState(false);
  useEffect(() => {
    getUserQuestions();
    if (
      searchParams.get("patientId")?.length &&
      searchParams.get("patientId") !== "null"
    ) {
      setGoBackUrl(
        `/questionnaires?patientId=${searchParams.get("patientId")}`
      );
      setClinicianViw(true);
    }
  }, []);

  useEffect(() => {
    getSessions();
  }, []);
  async function getSessions() {
    try {
      let URL =
      
        `/api/questionnaire/get-all-question-sessions?session=${searchParams.get(
          "session"
        )}`;
      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `&service_user_id=${searchParams.get("patientId")}`;
      }
      await apiFunctions
        .get(URL)
        .then((_result) => {
          const sessionInfo = _result.data.result.sessions;
          setCompletedSet(_result.data.result.completedSets);
          setPopupType(
            _result.data.result.completedSets.length > 2
              ? "session_completed"
              : ""
          );
          let completedSession: any = [];
          let not_completedSession: any = [];
          sessionInfo.map((res: any) => {
            if (res.session_status === "completed") {
              completedSession.push(res);
            }
            if (res.session_status === "not-completed") {
              not_completedSession.push(res);
            }
          });
          setquestionSession({
            completed: completedSession,
            not_completed: not_completedSession,
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log("_result", err);
        });
    } catch (error: any) {
      console.log(error.response, "errorresposne");
    }
  }
  async function getUserQuestions() {
    try {
      let URL =
        `/api/questionnaire/get-question/${searchParams.get(
          "setId"
        )}?session_uuid=${searchParams.get("session")}`;
      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `&service_user_id=${searchParams.get("patientId")}`;
      }
      await apiFunctions
        .get(URL)
        .then((_result) => {
          setquestionsAndAnswer(_result.data.result.QuestionsAndAnswers);
          setquestionSet(_result.data.result.QuestionSet);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      console.log(error.response, "errorresposne");
    }
  }
  const checkBoxValue = (value: any, id: any, answer_uuid: any) => {
    questionsAndAnswer[0].answer = value;
    setquestionsAndAnswer([...questionsAndAnswer]);
  };
  //Set Image marker response
  const setImgaeans = (
    value: any,
    id: any,
    answer_uuid: any,
    index: number
  ) => {
    if (value && value !== null) {
      questionsAndAnswer[index].answer = value;
      setquestionsAndAnswer(questionsAndAnswer);
      SetPopupcontent({
        status: "Success!",
        message: "Your marked image is saved successfully.",
      });
    } else {
      questionsAndAnswer[index].answer = "";
      setquestionsAndAnswer(questionsAndAnswer);
      SetPopupcontent({
        status: "Failed!",
        message: "Please mark your pain points to continue!",
      });
      setButtonText("");
    }
    setPopupType("");
    setDirect("");
    setPopup(true);
    setDialogOpen(true);
    setHidepopup(true);
  };
  //Clear Image marker response
  const setClearImgaeans = (
    value: any,
    id: any,
    answer_uuid: any,
    index: number
  ) => {
    questionsAndAnswer[index].answer = value;
    setquestionsAndAnswer(questionsAndAnswer);
  };
  //Set Multichoice response
  const multichoice = (
    value: any,
    id: any,
    answer_uuid: any,
    arrayindex: any
  ) => {
    questionsAndAnswer[arrayindex].answer = value;
    setquestionsAndAnswer([...questionsAndAnswer]);
  };
  const setValue = (id: any, value: any, index: number) => {
    questionsAndAnswer[index].answer = value;
    setquestionsAndAnswer(questionsAndAnswer);
  };
  // Check all question have answer or not here
  function getAllAnswer(arg: string) {
    let count = 0;
    let filterData = questionsAndAnswer.filter(function (e: any) {
      return e.type !== "statement" && e.type !== "rating";
    });

    filterData.map((data: any) => {
      let conditionVariable =
        typeof data.answer === "string" || typeof data.answer === "object"
          ? data.answer && data.answer.length > 0
          : data.answer > 0;
      if (conditionVariable) {
        count += 1;
      }
    });

    if (arg === "completed") {
      return count === filterData.length ? true : false;
    } else {
      return count > 0 ? true : false;
    }
  }
  const checkClear=(arr:string)=>{
    setClear( arr ==='clear' ? false : true)
    
  }
  // Save Draft function
  async function saveDraft(e: any) {
    e.preventDefault();
    setLoading(true);
    setPopupType("");

    let allans: any = getAllAnswer("draft");

    let filterData = questionsAndAnswer.filter(function (e: any) {
      return e.type !== "statement" && e.type !== "rating";
    });
    let image = filterData.filter((ans: any) => {
      return (
        (!ans.answer || !ans.length || ans.answer === '{"lines":[]}') &&
        ans.type === "picturechoice"
      );
    });
    const markers = localStorage.getItem("drawing") || '{"lines":[]}';    
    if (
      !allans ||
      (allans && 
        (image.length &&
        ((image[0].answer === '{"lines":[]}' || image[0].answer === "") 
        &&
         markers !== '{"lines":[]}')
         || 
        (image.length > 0 && ((markers.length > 12 && markers.length) !== image[0].answer.length)  &&
        markers !== '{"lines":[]}')))
    ) {
      setLoading(false);
      SetPopupcontent({
        status: "Failed!",
        message:
          (image.length > 0 && imageMark.length === 0 && markers !== '{"lines":[]}') || ( image.length > 0 && ((markers.length > 12 && markers.length) !==  ( image[0].answer.length ) && markers !== '{"lines":[]}'))
            ? "Please save the shading of pain."
            : "You need to answer alteast one question to save draft.",
      });
      setDirect("");
      setPopup(true);
      setDialogOpen(true);
      setHidepopup(true);
    } else {
      let ansPayload = {
        questionSetId: questionSet.id,
        questionSetName: questionSet.name,
        status: "draft",
        question_session_id: searchParams.get("session"),
        questionSet: questionsAndAnswer.map((ans: any) => {
          return {
            questionId: ans.question_id,
            answer: ans.answer,
            uuid: ans.answer_uuid,
          };
        }),
      };
      try {
        localStorage.removeItem("drawing");
        await apiFunctions
          .post(
             "/api/questionnaire/answer",
            ansPayload
          )
          .then((_result) => {
            setLoading(false);
            SetPopupcontent({
              status: "Success!",
              message: "Your questionnaire has been saved as a draft.",
            });
            setDirect(appUrls.questionnaires);
            setPopup(true);
            setHidepopup(false);
            setDialogOpen(true);
            setButtonText("Back to questionnaires");
            setPopupType("");
          })
          .catch((err) => {
            setLoading(false);
            SetPopupcontent({ status: "Failed!", message: err.msg });
            setPopup(true);
            setHidepopup(true);
            setDirect("");
            setPopupType("");
            console.log("error", err);
          });
      } catch (error: any) {
        setLoading(false);
        SetPopupcontent({ status: "Failed!", message: error.msg });
        setPopup(true);
        setHidepopup(true);
        setDirect("");
        setPopupType("");
        console.log(error.response, "errorresposne");
      }
    }
  }

  // Save Completed function
  async function saveCompleted(e: any, type: string) {
    e.preventDefault();
    setLoading(true);

    let ansPayload = {
      questionSetId: questionSet.id,
      questionSetName: questionSet.name,
      question_session_id: searchParams.get("session"),
      status: "completed",
      session_status: completedSet.length > 2 ? "completed" : "not-completed",
      questionnire_completed_day: moment(new Date()).toDate(),
      session_completed_day:
        type && type === "session_completed" ? moment(new Date()).toDate() : "",
      session_uuid: questionSet.session_uuid,
      questionSet: questionsAndAnswer.map((ans: any) => {
        return {
          questionId: ans.question_id,
          answer: ans.answer,
          uuid: ans.answer_uuid,
        };
      }),
    };
    try {
      await apiFunctions
        .post(
          "/api/questionnaire/answer",
          ansPayload
        )
        .then((_result) => {
          setLoading(false);
          SetPopupcontent({
            status: "Success!",
            message: "The questionnaire is submitted successfully.",
          });
          setDirect(appUrls.questionnaires);
          setPopup(true);
          setHidepopup(false);
          setDialogOpen(true);
          setButtonText("Back to questionnaires");
          setPopupType("");
        })
        .catch((err) => {
          setLoading(false);
          SetPopupcontent({ status: "Failed", message: err.msg });
          setPopup(true);
          setDialogOpen(true);
          setHidepopup(true);
          setDirect("");
          setPopupType("");
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);
      SetPopupcontent({ status: "Failed", message: error.response.msg });
      setPopup(true);
      setDialogOpen(true);
      setHidepopup(true);
      setDirect("");
      setPopupType("");
      console.log(error.response, "errorresposne");
    }
  }
  // click completed
  const clickComplete = (e: any) => {
    let allans: any = getAllAnswer("completed");
    let filterData = questionsAndAnswer.filter(function (e: any) {
      return e.type !== "statement" && e.type !== "rating";
    });
    let image = filterData.filter((ans: any) => {
      return (
        (!ans.answer || !ans.length || ans === '{"lines":[]}') &&
        ans.type === "picturechoice"
      );
    });
    const markers = localStorage.getItem("drawing") || '{"lines":[]}';

    if (
      !allans ||
      // ((allans && image.length && image[0].answer === '{"lines":[]}')|| ((image.length >0 && ((markers.length > 12 && markers.length) > image[0].answer.length))))
      (allans && 
        (image.length &&
        ((image[0].answer === '{"lines":[]}' || image[0].answer === "") 
        &&
         markers !== '{"lines":[]}')
         || 
        (image.length > 0 && ((markers.length > 12 && markers.length) !== image[0].answer.length)  &&
        markers !== '{"lines":[]}')))
    ) {

      setLoading(false);
      SetPopupcontent({
        status: "Failed!",
        message:
        // (image.length > 0 && imageMark.length === 0 && markers !== '{"lines":[]}' && !clear) || ( image.length > 0 && ((markers.length > 12 && markers.length) !==  ( image[0].answer.length ) && markers !== '{"lines":[]}') &&!clear)
        (image.length > 0 && imageMark.length === 0 && markers !== '{"lines":[]}') || ( image.length > 0 && ((markers.length > 12 && markers.length) !==  ( image[0].answer.length ) && markers !== '{"lines":[]}'))

        ? "please save the shading of pain."
            : "You need to answer all the questions to submit.",
      });
      setDirect("");
      setPopup(true);
      setHidepopup(true);
      setDialogOpen(true);
      setPopupType("");
    } else {
      if (completedSet.length > 2) {
        let time: any = process.env.REACT_APP_FD_NEXT_START_TIME_QB || 1;
        let types: any = process.env.REACT_APP_FD_NEXT_START_TYPE || "month";
        const startDate = moment().add(time, types).format("DD/MM/YYYY");
        let Message1 = `Once you completed the session your next session starts after a month (${startDate}). Do you still want to complete this session?`;
        let Message2 = `This your last Questionnaire session.`;
        setLoading(false);
        SetPopupcontent({
          status: "Note!",
          message: Message1,
          message1:
            process.env.REACT_APP_QB_SESSIONS === completedSet.length
              ? Message2
              : "",
        });
        setPopup(true);
        setDialogOpen(true);
        setHidepopup(true);
        setPopupType("session_completed");
      } else {
        saveCompleted(e, "");
      }
    }
  };
  return (
    <Layout>
      <Loading status={loading} />
      <main className="main-container">
        {!loading && (
          <section className="outer-container">
            {/* <h3 style={{ height: "250px" }} >Questions </h3> */}
            <section>
              <div className="fq-username">
                <Link to={gobackurl} className="back-arrow">
                  <div>
                    <BackArrow />
                  </div>
                  <p> Back to questionnaires</p>
                </Link>
                {searchParams.get("patientId") && (
                  <div className="">
                    <h5 tabIndex={0} className="particitant-w">
                      Participant:{" "}
                      {participant &&
                        `${participant?.name || participant?.label}`}
                    </h5>
                  </div>
                )}
              </div>
              <br />
              <br />
              <br />
              <br />
              <div className="qb-hr-line">
                <label tabIndex={0}>{questionSet.name}</label>
              </div>
            </section>
            <main className="qb-container">
            <DialogPopup
                open={dialogOpen}
                onRequestClose={() => {
                  setDialogOpen(!dialogOpen);
                }}
                redirectTo={direct}
                hidePopup={hidepopup}
                showIcon
                closeOnOutsideClick
              >
                <CommonPopup
                  PopupSize="md"
                  PopContent={PopContent}
                  redirectTo={direct}
                  hidePopup={hidepopup}
                  setPopup={setPopup}
                  text={buttonText}
                  saveCompleted={saveCompleted}
                  type={popupType}
                  onRequestClose={() => {
                    setDialogOpen(!dialogOpen);
                  }}
                />
              </DialogPopup>
            
              {questionsAndAnswer &&
                questionsAndAnswer.filter(function (e: any) {
                  return e.type === "likert";
                }).length > 0 && (
                  <>
                    {(questionSet.name).includes('FSS')&& (
                      <h5 tabIndex={0}>
                        Please drag the circle to the number between 1 and 7,
                        which you feel best fits the following statements. This
                        refers to your usual way of life within the last week. 1
                        indicates “strongly disagree” and 7 indicates “strongly
                        agree.”
                      </h5>
                    )}
                    {(questionSet.name).includes('GSRS')&& (
                      <div>
                        <h5 tabIndex={0}>
                          Please drag the circle to the number between 1 and 7,
                          which you feel best fits the following statements.
                          This refers to your usual way of life within the last
                          week.
                        </h5>
                        <br />
                        <div className="indicate-likert">
                          <div>
                            <p tabIndex={0}>1 - No discomfort at all </p>
                            <p tabIndex={0}>2 - Minor discomfort </p>
                            <p tabIndex={0}>3 - Mild discomfort </p>
                            <p tabIndex={0}>4 - Moderate discomfort </p>
                          </div>
                          <div>
                            <p tabIndex={0}>5 - Moderately severe discomfort</p>
                            <p tabIndex={0}>6 - Severe discomfort</p>
                            <p tabIndex={0}>7 - Very severe discomfort</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              <br />
              <br />
              {questionsAndAnswer &&
                questionsAndAnswer.map((data: any, index: any) => {
                  return (
                    <>
                      {/* Here the Yes or No type Section */}
                      {data.type === "yesnoquestion" && (
                        <section
                          className="view-data-group"
                          id={index}
                          key={index}
                        >
                          <label tabIndex={0}>{data.question}</label>
                          <br />
                          <br />
                          <div>
                            {data.options.map((optn: any, index: any) => {
                              return (
                                <ChooseTags
                                  key={index}
                                  type="radio"
                                  label={optn.text}
                                  name="checkBoxCard"
                                  addClass="text-base font-light choose-yes-no"
                                  onChange={checkBoxValue}
                                  value={optn.text}
                                  id={data.question_id}
                                  answer_uuid={data.answer_uuid}
                                  checked={data.answer === optn.text}
                                  disabled={
                                    questionSet.status === "completed" ||
                                    clinicianView
                                      ? true
                                      : false
                                  }
                                />
                              );
                            })}
                          </div>
                        </section>
                      )}

                      {/* Here the radioinput type Section */}
                      {data.type === "radioinput" && (
                        <section
                          className="view-data-group"
                          id={index}
                          key={index}
                        >
                          <label tabIndex={0}>{data.question}</label>
                          <br />
                          <br />
                          {data.options.map((item: any, idx: number) => {
                            return (
                              <CheckBox
                                type="checkbox"
                                label={item.text}
                                arrayindex={index}
                                name="tobaccoList"
                                addClass="text-base font-light"
                                // onChange={()=>checkBoxValue}
                                value={item.id}
                                id={data.question_id}
                                multichoice={multichoice}
                                answer_uuid={
                                  data.answer_uuid ? data.answer_uuid : ""
                                }
                                checked={data.answer.includes(item.id)}
                                disabled={
                                  questionSet.status === "completed" ||
                                  clinicianView
                                    ? true
                                    : false
                                }
                              />
                            );
                          })}
                        </section>
                      )}

                      {/* Here the Likert type Section */}
                      {data.type === "likert" && (
                        <section
                          className="view-data-group"
                          id={index}
                          key={index}
                        >
                          <label tabIndex={0}>{data.question}</label>
                          <br />
                          <br />
                          <div className="range-block" tabIndex={0}>
                            <RangeSlider
                              // sliderValue={[15]}
                              sliderValue={[data.answer ? data.answer : 0]}
                              domain={[0, 7]}
                              onChangeValue={(e: any) =>
                                setValue(data.question_id, e[0], index)
                              }
                              disabled={
                                questionSet.status === "completed" ||
                                clinicianView
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="range-label-wrapper">
                            <span key="0">0</span>

                            {data.options.map((item: any, idx: number) => {
                              return <span key={idx}>{item.text}</span>;
                            })}
                          </div>
                        </section>
                      )}
                      {/* Here the Image type Section */}

                      {data.type === "picturechoice" && (
                        <section
                          className="view-data-group"
                          id={index}
                          key={index}
                        >
                          <div className="scrolling-body">
                            <PictureCard_1
                              onAction={(e: any) => setImageMark([e])}
                              label={data.question}
                              Setimageanswer={setImgaeans}
                              Setclearimageanswer={setClearImgaeans}
                              checkClear={checkClear}
                              value={data.answer ? data.answer : []}
                              id={data.question_id}
                              answer_uuid={
                                data.answer_uuid ? data.answer_uuid : ""
                              }
                              index={index}
                              disabled={
                                questionSet.status === "completed" ||
                                clinicianView
                                  ? true
                                  : false
                              }
                              selectedType={dotType}
                            />
                          </div>
                          {/* <PictureCard
                          onAction={(e) => setImageMark([e])}
                          label={data.question}
                          Setimageanswer={setImgaeans}
                          value={data.answer ? data.answer : []}
                          id={data.question_id}
                          answer_uuid={data.answer_uuid ? data.answer_uuid : ""}
                          index={index}
                          disabled={
                            questionSet.status === "completed" || clinicianView
                              ? true
                              : false
                          }
                          selectedType={dotType}
                        /> */}
                          {questionSet.status !== "completed" &&
                            !clinicianView && (
                              <div className="pain-btn-block">
                                <div className="pain-group-wrapper">
                                  <div className="btn-space">
                                    <Radio
                                      type="radio"
                                      label="Pain"
                                      addClass="w-100 pt-1"
                                      parentClass="block"
                                      name="agegroup"
                                      value="Pain"
                                      onChange={(e) => setDotType("pain")}
                                      checked={
                                        dotType === "pain" ? true : false
                                      }
                                    />
                                  </div>
                                  <div className="btn-space">
                                    <Radio
                                      type="radio"
                                      label="Heavy Pain"
                                      addClass="w-100 pt-1"
                                      parentClass="block"
                                      name="agegroup"
                                      value="Heavy Pain"
                                      onChange={(e) => setDotType("heavy")}
                                      checked={
                                        dotType === "heavy" ? true : false
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                        </section>
                      )}

                      {data.type === "statement" && (
                        <section className="view-data-group" id={index}>
                          <h5 tabIndex={0}>{data.question}</h5>
                        </section>
                      )}
                    </>
                  );
                })}

              <br />
              <br />

              {/* Here the Save Draft and Submited Section */}
              {questionSet.status !== "completed" && !clinicianView && (
                <section className="view-data-group">
                  <div className="form-btn-group">
                    <div>
                      <Button
                        btntype="button"
                        id="draft"
                        text="SAVE AS DRAFT"
                        addClass="primary-btn btn-padding"
                        onClick={saveDraft}
                      />
                    </div>
                    <div>
                      <Button
                        btntype="button"
                        id="save"
                        text="SUBMIT"
                        addClass="primary-btn btn-padding"
                        onClick={clickComplete}
                      />
                    </div>
                  </div>
                </section>
              )}

            </main>
          </section>
        )}
      </main>
    </Layout>
  );
};
