import {ILoginPayload
} from '../../models/Types';
import apiFunctions from '../api-service';
import appUrls from '../AppURLs';


export function logout() {
      localStorage.clear();
      window.location.href=`${appUrls.user.login}`;
     
}

export function getUserInfo() { 
  return apiFunctions.get('/api/account/get-user-data').then((user) => {
    return user;
  });
}

export function checkUserAuthentication() { 
  return apiFunctions.get('/api/auth/check-authentication').then((user) => {    
    return user;
  });
}
export function login(payload: ILoginPayload) {
  return apiFunctions.post('/api/auth/login', payload).then((user) => {
    return user as any;
  });
}
