import React from 'react';
import "../styles/TextInput.css";
import { ReactComponent as Password } from "../../assets/svg/View.svg";
import { ReactComponent as PasswordHidden } from "../../assets/svg/hidden.svg";
import { ReactComponent as Search } from '../../assets/svg/Search.svg';


interface TextInputProps {
  style?: string;
  text?: string;
  id: string;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  type: string;
  name: string;
  addClass?: string;
  input_Class?: string;
  values?: string | number | Array<string>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onIconClick?: (e: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors?: string | Array<string> | any;
  helpIcon?: boolean;
  helpContent?: string;
  icon?: 'search' | 'password' | 'delete' | 'file' | null;
}

export const TextInput: React.FC<TextInputProps> = (
  {
    label,
    placeholder,
    id,
    style,
    disabled,
    type,
    onChange,
    onIconClick,
    addClass,
    input_Class,
    name,
    errors,
    onBlur,
    values,
    helpIcon,
    helpContent,
    icon,
  }) => {
  const tabIndex = 0;
  const handleClick = (e: any)=>{
    if (e.keyCode === 13){
        e.target.click();
    }
};

  return (
    <>
      <div className={`input-group ${addClass}`}>
        <div className="flex">
          <div className="flex-grow">
            {label && (
              <label tabIndex={tabIndex} htmlFor={id}>
                {label}
              </label>
            )}
          </div>
        </div>

        <div className="relative">
          <input
            tabIndex={tabIndex}
            id={id}
            type={type}
            className={`text-field w-100 ${input_Class || ''} ${errors ? 'input-error' : ''
              }`}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            value={values}
            autoComplete="off"
            aria-labelledby={id}
          />
          {icon === 'password' && (
            <div tabIndex={0} onKeyDown={handleClick} className="input-password onkeyup" aria-label={type==='password'?'hide password icon':'show password icon'} onClick={onIconClick}>
              {type === 'password' ? <PasswordHidden /> : < Password />}
            </div>
          )}

          {icon === 'search' && (
            <div className="input-search">
              <Search />
            </div>
          )}
        </div>
        {errors && <div className="form-error-msg">{errors}</div>}
      </div>
    </>
  );
}