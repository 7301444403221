import React from 'react';
import { Button } from '../Button/Button';
import "../styles/CommonPopup.css";
import { ReactComponent as Closebutton } from "../../assets/svg/Closebutton.svg";
import { useNavigate } from "react-router-dom";


interface CommonPopupProps {
    PopupSize?: 'sm' | 'md' | 'lg' | 'xlg';
    PopContent?:any,
    redirectTo?:any,
    hidePopup?:boolean,
    setPopup?:any
    type?:any,
    deleteDiaryRecord?:any,
    saveAndAddEntry?:any,
    text?:string,
    clickContinue?:any,
    url?:boolean,
    onRequestClose?:any,
    saveCompleted?:any



}

export const CommonPopup: React.FC<CommonPopupProps> = (
    
    {
        PopupSize,
        PopContent,
        redirectTo,
        hidePopup,
        setPopup,
        type,
        deleteDiaryRecord,
        saveAndAddEntry,
        clickContinue,
        text,
        url,
        onRequestClose,
        saveCompleted
    
    }) => {
        let navigate = useNavigate();

        function continueButton(){            
            if(hidePopup){
                setPopup(false)
            }else{
                navigate(redirectTo)
            }
        }
    return (
        <>
           
                        <div className=''>
                            <div className='popup-info-center'>
                                {/* {!url && <div  className='close-mark'> <Closebutton tabIndex={0} onClick={(e:any)=>{ continueButton ()}} /></div>} */}
                                <br />
                                <h5 tabIndex={0} className=''>{PopContent.status}</h5>
                                <br /><br />
                              {!PopContent.message1 &&
                               <> 
                               <p tabIndex={0}>{PopContent.message}</p>  <br /><br />
                              </> } 
                               
                              { PopContent.message1 && 
                              <>
                               <p tabIndex={0}>{PopContent.message}</p>
                                {PopContent.sigin_in === 'yes'&& <br /> }
                                <p tabIndex={0}>{PopContent.message1}</p>
                                <br /><br /><br />
                              </>
                              }
                                {!url&&<div className='popup-btn'>
                                    <Button
                                        btntype="button"
                                        text= { text ? 
                                            text :(type === 'delete' ? "REMOVE ENTRY" : 
                                            (type=== 'start' ? "Yes"
                                            :(type=== 'syncwithings' ? 'Go to Sync Withings data' :"CONTINUE")
                                            ))}
                                        addClass="primary-btn btn-padding w-full"
                                        onClick={(e:any)=>{                                            
                                            if(type === 'delete'){
                                                deleteDiaryRecord()
                                            }else if(type === 'session-submited')
                                            {
                                                saveAndAddEntry('session-submited')
                                            }else if ( type === 'start'){
                                                setPopup(false)
                                                clickContinue('yes');
                                            }
                                            else if(type=== 'syncwithings'){ 
                                                window.open(process.env.REACT_APP_SYNC_WITHINGS_PAGE,'_self', '');
                                            }else if(type === 'session_completed'){
                                                saveCompleted(e,'session_completed')
                                            }else{
                                                onRequestClose();
                                                if( !hidePopup && type === 'import'){
                                                    window.location.reload();
                                                }else if(!hidePopup && redirectTo){
                                                    navigate(redirectTo)  
                                                }else if(hidePopup && !redirectTo){
                                                    onRequestClose();
                                                }                                        

                                            }
                                        }}
                                    />
                                </div>}
                                <br></br>
                                {(type === 'start') && <div className='popup-btn'>
                                    <Button
                                        btntype="button"
                                        text= "No"
                                        addClass="primary-btn btn-padding w-full"
                                        onClick={(e:any)=>{ onRequestClose()}}
                                    />
                                </div>}

                            
                                 {(type === 'delete' || type=== 'session-submited') && <button tabIndex={0} className="cancel btn-padding w-full" onClick={(e:any)=>{ onRequestClose ()}}>Cancel</button>}                                 
                           
                               
                               {(type === 'syncwithings') && <div className='popup-btn'>
                                    <Button
                                        btntype="button"
                                        text= "I'll do this later"
                                        addClass="primary-btn btn-padding btn-diary w-full"
                                        onClick={(e:any)=>{   if(!hidePopup && redirectTo){
                                            navigate(redirectTo)  
                                        }else{
                                            onRequestClose()
                                        } }}
                                    />
                                </div>}
                            </div>
                        </div>
                 
        </>
    );
}

