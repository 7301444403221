// import React from 'react';
import React, { useEffect, useState } from "react";

import "../styles/PictureCard.css";
import BPain from "../../assets/img/Close.png";
import BodyPain from "../../assets/img/Body-image.jpg";
import CanvasDraw from "react-canvas-draw";
import { curry, ap } from "../canvas-draw/functional";
import { useCounter } from "../canvas-draw/hooks";
import { withRef } from "../canvas-draw/react-helper";
import { Button } from "../Button/Button";

interface PictureCard1Props {
  disabled?: boolean;
  checked?: boolean;
  label?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAction: (e: any) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors?: string | null;
  addClass?: string;
  parentClass?: string;
  selectedType: string;
  user_data?: any;
  isRequired?: Boolean;
  description?: string | null;
  Setimageanswer?: any;
  value?: any;
  id?: any;
  answer_uuid?: any;
  index?: number;
  Setclearimageanswer?: any;
  checkClear?: any;
}
const getCanvasData = (canvasDraw: any) => {
  return canvasDraw.getSaveData();
};

const saveCanvas = (canvasDraw: any) => {
  localStorage.setItem("drawing", getCanvasData(canvasDraw));
  return void 0;
};

const loadCanvasData = curry(
  (immediately: any, canvasDraw: any, dataString: any) => {
    return canvasDraw.loadSaveData(dataString, immediately);
  }
);
const loadCanvasDataImmediately = loadCanvasData(true);
const loadCanvasDataAnimated = loadCanvasData(false);

const undoCanvas = (canvasDraw: any) => {
  canvasDraw.undo();
};

const clearCanvas = (canvasDraw: any) => {
  canvasDraw.clear();
};
const redoCanvas = curry(
  (cacheDataString: any, undos: any) => (canvasDraw: any) => {
    const { lines, ...rest } = JSON.parse(cacheDataString);
    const undosAfterRedo = undos - 1;
    const linesAfterRedo = undosAfterRedo
      ? lines.slice(0, -1 * undosAfterRedo)
      : lines;
    loadCanvasDataImmediately(
      canvasDraw,
      JSON.stringify({ lines: linesAfterRedo, ...rest })
    );
  }
);

const syncCacheData = (setCacheData: any) => (canvasDraw: any) =>
  setCacheData(canvasDraw.getSaveData());

export const PictureCard_1: React.FC<PictureCard1Props> = ({
  label,
  errors,
  parentClass,
  isRequired,
  description,
  Setimageanswer,
  value,
  id,
  answer_uuid,
  index,
  disabled,
  onAction,
  selectedType,
  Setclearimageanswer,
  checkClear,
}) => {
  /* Use Hooks */
  const val = localStorage.getItem("drawing") || '{"lines":[]}';

  const [values, setValues] = useState<any>(
    localStorage.getItem("drawing") || '{"lines":[]}'
  );
  const CanvasRef: any = React.useRef();
  useEffect(() => {
    if (value.length && !value[0].left) {
      setValues(value);
    }
  }, []);
  const [cacheData, setCacheData] = React.useState(values);
  const {
    counter: undos,
    inc: incUndos,
    dec: decUndos,
    reset: resetUndos,
    set: setUndos,
  } = useCounter(0);

  /* Variables */
  const isAtTheBeginning = JSON.parse(cacheData).lines.length === undos;

  const isAtTheEnd = undos === 0;


  /* Helpers */
  const whenCanvasReady = withRef(CanvasRef);
  const setUndoAll = () => {
    setUndos(JSON.parse(cacheData).lines.length);
    checkClear('not-clear')
    
  };

  const mouseUpAction = ap(
    resetUndos,
    whenCanvasReady(syncCacheData(setCacheData)),
    whenCanvasReady(saveCanvas)
  );
  const drawAction: any = ap(
    mouseUpAction,
    whenCanvasReady((canvasDraw: any) => {
      return loadCanvasDataAnimated(canvasDraw, getCanvasData(canvasDraw));
    })
  );
  /* Actions */
  const undoAction = ap(incUndos, whenCanvasReady(undoCanvas)); // Un-Do
  const redoAction = ap(decUndos,whenCanvasReady(redoCanvas(cacheData, undos)) ); // Re-Do
  const clearAction = ap(setUndoAll, whenCanvasReady(clearCanvas)); // Clear


  return (
    <div className={`${parentClass || "input-group inline-block"} `}>
      {label && (
        <>
          <label
            tabIndex={0}
            className={`${isRequired && "required"}`}
            htmlFor=""
          >
            {label}
          </label>
        </>
      )}
      <div>
        <div
          className={`add-image-block`}
          onMouseUp={mouseUpAction}
          onTouchEnd={mouseUpAction}
        >
          <div className="" tabIndex={0}>
            <CanvasDraw
              ref={CanvasRef}
              saveData={values}
              immediateLoading={true}
              imgSrc={BodyPain}
              brushColor={`${
                selectedType === "pain"
                  ? "hsl(200deg 88% 45% / 60%)"
                  : "hsl(0deg 100% 48% / 68%)"
              }`}
              brushRadius={selectedType === "pain" ? 8 : 4}
              disabled={disabled}
              hideInterface={true}
              className={`canvas-image-resize`}
              lazyRadius={0}
              canvasWidth={833}
              canvasHeight={833}
            />
          </div>
        </div>
      </div>
      {!disabled && (
        <div>
          <br />
          <div className="image-btn-group">
            <div className="btn-space">
              <Button
                btntype="button"
                id="button"
                text="UNDO"
                addClass="primary-btn btn-padding"
                onClick={undoAction}
                disabled={!value ? false : (CanvasRef && CanvasRef.current && CanvasRef.current.lines.length == 0 ? true : false)}
              />
            </div>
            <div className="btn-space">
              <Button
                btntype="button"
                id="button"
                text="RE-DO"
                addClass="primary-btn btn-padding"
                onClick={redoAction}
                disabled={isAtTheEnd}
                // onClick={clearAction}
              />
            </div>
            <div className="btn-space">
              <Button
                btntype="button"
                id="button"
                text="CLEAR"
                addClass="primary-btn btn-padding"
                onClick={clearAction}
              />
            </div>
            <div className="btn-space">
              <Button
                btntype="button"
                id="button"
                text="SAVE"
                addClass="primary-btn btn-padding"
                onClick={() => {
                  drawAction();
                  checkClear('clear');
                  localStorage.removeItem("drawing");
                  let markers = CanvasRef.current.lines.length
                    ? JSON.stringify({
                        lines: CanvasRef.current.lines,
                        width: 833,
                        height: 833,
                      })
                    : "";
                  Setimageanswer(markers, id, answer_uuid, index);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {errors && <div className="form-error-msg">{errors}</div>}
    </div>
  );
};
