import React, { useRef, useEffect, useState } from 'react';

import { ReactComponent as PopupClose } from '../../assets/svg/Closebutton.svg';
import { useDialogPolyfill } from '../DialogPopup/useDialogPolyfill';
import '../styles/DialogPopup.css';
import { useNavigate } from "react-router-dom";

interface DialogPopupProps {
  closeOnOutsideClick: any;
  onRequestClose: any;
  open?: any;
  children?: any;
  userdata?: any;
  PopupSize?: 'sm' | 'md' | 'lg';
  heading?: string;
  content?: string;
  showIcon?: boolean;
  closeIcon?:boolean;
  redirectTo?:any,
  hidePopup?:boolean,
  withings?:boolean,
  type?:string
}

export const DialogPopup: React.FC<DialogPopupProps> = ({
  closeOnOutsideClick,
  onRequestClose,
  open,
  children,
  PopupSize,
  showIcon,
  redirectTo,
  hidePopup,
  withings,
  type
}) => {
  const dialogRef = useRef<any>(null);
  const lastActiveElement = useRef<any>(null);
  const firstRender = useRef<boolean>(true);
  const [tabActive, settabActive] = useState<boolean>(true);

  useDialogPolyfill(dialogRef);
  let navigate = useNavigate();

  // Trap Inner Popup focus using Tab
  function trapTabKey(
    e: any,
    firstTabStop: any,
    lastTabStop: any,
    dialogNode: any
  ) {
    // Check for TAB key press
    if (e.keyCode === 9) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === firstTabStop) {
          e.preventDefault();
          lastTabStop.focus();
        }

        // TAB
      } else if (document.activeElement === lastTabStop) {
        e.preventDefault();
        firstTabStop.focus();
      }
    }

    // ESCAPE
    if (e.keyCode === 27) {
      dialogNode.close();
      settabActive(true);
    }
  }

  useEffect(() => {
    // prevents calling imperative methods on mount since the polyfill will throw an error since we are not using the `open` attribute

    // For popup staging issue hide the condition
    // if (firstRender.current) {
    //   firstRender.current = false;
    // } else {
      const dialogNode = dialogRef.current;
      if (open) {
        lastActiveElement.current = document.activeElement;
        dialogNode.showModal();
        // Find all focusable children
        const focusableElementsString =
          'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

        let focusableElements = dialogNode.querySelectorAll(
          focusableElementsString
        );
        focusableElements = Array.prototype.slice.call(focusableElements);
        const firstTabStop = focusableElements[0];
        const lastTabStop = focusableElements[focusableElements.length - 1];

        // Focus first child
        // firstTabStop.focus();

        // Traps focus popup
        dialogNode.addEventListener('keydown', (event: any) => {
          trapTabKey(event, firstTabStop, lastTabStop, dialogNode);
          if (event.keyCode === 9) {
            settabActive(false);
          }
        });
      } else {
        dialogNode.close();
        settabActive(true);
        if (
          lastActiveElement &&
          lastActiveElement.current &&
          lastActiveElement.current !== null
        ) {
          lastActiveElement.current.focus();
        }
      }
    // }
  }, [open]);

  useEffect(() => {
    const dialogNode = dialogRef.current;
    const handleCancel = (event: any) => {
      event.preventDefault();
      onRequestClose();
    };
    if (dialogNode) {
      dialogNode.addEventListener('cancel', handleCancel);
    }
    return () => {
      if (dialogNode) {
        dialogNode.removeEventListener('cancel', handleCancel);
      }
    };
  }, [onRequestClose]);

  function handleOutsideClick(event: any) {
    const dialogNode = dialogRef.current;
 
    if (closeOnOutsideClick && event.target === dialogNode) {
      onRequestClose();
   
        if( !hidePopup && type === 'import'){
          window.location.reload();
      }
      
    }
  }

  return (
    <dialog ref={dialogRef} onClick={handleOutsideClick}>
      <div className='popup-wrapper'>
        <div className={`popup-card ${PopupSize}`}>
          {showIcon && (
            <div className='popup-card-close'>
              <button
                className={`cursor-pointer ${
                  open && tabActive ? 'tab_inactive' : ''
                }`}
                type='button'
                onClick={()=>{
                  onRequestClose()
                  if(!hidePopup && redirectTo && type !== 'import'){
                    navigate(redirectTo)
                  }
                  else if (hidePopup && redirectTo && withings){
                    onRequestClose()
                  } 
                  else if (hidePopup && redirectTo){
                    window.location.reload();
                    onRequestClose()
                  }
                  else if( !hidePopup && type === 'import'){
                    window.location.reload();
                 }
                }
                
                 }
              >
                <PopupClose />
                <span className='sr-only'>popup</span>
              </button>
            </div>
          )}
          <div className='popup-card-content'>
            {open && <div className='popup-body'>{children}</div>}
          </div>
        </div>
        {/* <div onClick={onRequestClose} className='backdrop' /> */}
      </div>
    </dialog>
  );
};
