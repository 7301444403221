import React from 'react';

import "../styles/FooterMenu.css";
import { ReactComponent as NCAlliance } from "../../assets/svg/NorthernCareAlliance.svg";
import { Link, useLocation } from 'react-router-dom';

interface FooterMenuProps {

}

export const FooterMenu: React.FC<FooterMenuProps> = () => {
    const tabIndex = 0;
    const location = useLocation();
    const FooterNavBar = [
        {
            title: "Terms and service",
            link: '/terms',
        },
        {
            title: "About Metabolic Med",
            link: '/aboutmetabolic',
        },
        {
            title: "Privacy statement",
            link: '/privacy',          
        },
        {
            title: "Cookies",
            link: '/cookies',            
        },
    ];
    
    return (
        <nav className='pages-footer-desk'>
            <div className='footer-block'>
                <div className='footer-content-section'>
                    {FooterNavBar.map((item: any, idx: number) => {
                        return (                      
                            <React.Fragment key={idx}>
                                <Link
                                    tabIndex={tabIndex}
                                    key={item.link}
                                    className="footer-nav-item" 
                                    to={item.link}
                                >
                                    {item.title}
                                </Link>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
            <div className='footer-logo-section'>
                <NCAlliance tabIndex={0} aria-label="NHS FOOTER LOGO"/>
            </div>
        </nav>
    );
}