import React, { useEffect, useState } from "react";
import { CustomDataTable } from "../components/CustomDataTable/CustomDataTable";
import { ClinicianLayout } from "../components/Layout/ClinicianLayout";
import { TextInput } from "../components/TextInput/TextInput";
import { CommonPopup } from "../components/CommonPopup/CommonPopup";
import Loading from "../components/Loading/Loading";
import { useSearchParams, } from "react-router-dom";
import useAuthContext from "../context/providers/AuthContext";

import appUrls from "../services/AppURLs";
import apiFunctions ,{ setToken } from "../services/api-service";
import { DialogPopup } from "../components/DialogPopup/DialogPopup";
interface ClinicianDashboardProps {}

export const ClinicianDashboard: React.FC<ClinicianDashboardProps> = ({}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [particiantUser, setPaticipantUser] = useState<any>([]);
  const [searchParticiantUser, setSearchPaticipantUser] = useState<any>([]);
  const [searchText, setSearchText] = useState<any>([]);
  const [Popup, setPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hidepopup, setHidepopup] = useState(false);
  const [PopContent, SetPopupcontent] = useState<any>([]);
  const { authDispatch } = useAuthContext();
  const [userRole, setUserData] = useState(2);
  const [dialogOpen, setDialogOpen] = useState(false);


  useEffect(() => {
  if(searchParams.get('string')){
    checkClinicianUser();
  }else{
    getParticipantUsers();
  }
  }, []);

  const getSearchId = (name:string)=> {
    const results = new RegExp ('[\?&]' + name + '=([^&#]*)').exec (window.location.href);
    if (results === null) {
      return null;
    } else {
      return decodeURI (results[1]) || 0;
    }
  }
  async function checkClinicianUser() {
    try {
      await apiFunctions
        .post(
          
            "/api/auth/clinician-verify",{data:getSearchId('string')}
        )
        .then(async(_result) => {
          setToken(
            _result &&
              _result.data &&
              _result.data.result.accessToken
              ? _result.data.result.accessToken
              : null,
            null
          );
          authDispatch({ type: "LOGIN" });
          authDispatch({
            type: "LOAD_USER",
            user: _result.data.result.findRes,
          });
          //   Set user role
          setUserData(_result.data.result.userRole.id);
          // localStorage.setItem('user_role', _result.data.result.userRole.id);
          await getParticipantUsers();
          // setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          SetPopupcontent({status:'Failed',message: err && err.response && err.response.data && err.response.data.msg ? err.response.data.msg :(err && err.response && err.response.data && err.response.data.error ? err.response.data.error.reason: 'Encrypted Faild.')})
          setPopup(true)
          setHidepopup(false);
          setDialogOpen(true);
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);
      SetPopupcontent({status:'Failed',message: error && error.response && error.response.data && error.response.data.msg?error.response.data.msg : (error && error.response && error.response.data && error.response.data.erroror ? error.response.data.error.reason: 'Encrypted Faild.')})
      setPopup(true)
      setHidepopup(false);
      setDialogOpen(true);
      console.log(error.response, "errorresposne");
    }
  }
  async function getParticipantUsers() {
    // setLoading(true);
    try {
      await apiFunctions
        .get(
          
            "/api/account/get-all-user-data"
        )
        .then((_result) => {
          let result = _result.data.result.userList;
          setPaticipantUser(result);
          setSearchPaticipantUser(result);
          setLoading(false);

        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false);
      console.log(error.response, "errorresposne");
    }
  }
  return (
    <ClinicianLayout>
      <main className="main-container">
        <section className="outer-container">
          <p tabIndex={0}>
          In this section, you can view the list of participants, their questionnaire and food diary responses and Withings data.
          </p>
          <br />
          <div className="search-block">
            <TextInput
              icon="search"
              label="Search"
              type="text"
              placeholder="Search for your patient"
              id="search"
              name="search"
              values={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);   
                if (e.target.value.length) {

               
                  
                  setPaticipantUser([
                    ...searchParticiantUser.filter(
                      (res: any) =>
                     
                        (res && res.firstName+res.lastName).toLowerCase()
                        .includes(e.target.value.toLowerCase().replace(/\s/g, ""))
                        ||
                        (res && res.email).toLowerCase()
                        .includes(e.target.value.toLowerCase().replace(/\s/g, ""))
                      
                    ),
                  ]);
                } else {
                  setPaticipantUser([...searchParticiantUser]);
                }
              }}
            />
          </div>
          <div className="hr-line" />
          <br />          
            <CustomDataTable userList={particiantUser} />      
        </section>
      </main>

      <DialogPopup
           open={dialogOpen}
           onRequestClose={() => {
             setDialogOpen(!dialogOpen);          
           }}
           showIcon={false}
           closeOnOutsideClick
         > 
         <CommonPopup
            PopupSize="md"
            PopContent={PopContent}
            redirectTo={appUrls.fooddiaryoverview}
            hidePopup={hidepopup}
            setPopup={setPopup}
            url={true}
            // deleteDiaryRecord={deleteDiaryRecord}
            // type={deleteType}
          />      
         </DialogPopup>
   
        <Loading status={loading} />
    </ClinicianLayout>
  );
};
