import React, { CSSProperties, useId } from "react";

import "../styles/DatePicker.css";

import ReactDatePicker from "react-datepicker";
// import ReactDatePicker from "react-day-picker";
// https://reactdatepicker.com/

import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalenderDate } from "../../assets/svg/CalenderDate.svg";

interface DatePickerProps {
  className?: string | undefined;
  autoComplete?: "on" | "off" | undefined;
  isIconVisible?: string;
  selected?: Date | null | undefined;
  onChange(
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined
  ): void;
  dateFormat?: string | string[] | undefined;
  name?: string | undefined;
  dropdownMode?: "select" | "scroll" | undefined;
  placeholderText?: string | undefined;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  highlightDates?: any;
  modifiers?: any;
}

const styles = {
  dateIcon: {
    right: "10px",
    position: "absolute",
    top: "4px",
    "margin-top": "-5px",
    "z-index": "9",
    display: "flex",
    height: "100%",
    "align-items": "center",
    "pointer-events": "none",
  } as CSSProperties,
};
export const DatePicker: React.FC<DatePickerProps> = ({
  autoComplete,
  className,
  isIconVisible,
  selected,
  onChange,
  dateFormat,
  name,
  dropdownMode,
  placeholderText,
  maxDate,
  minDate,
  highlightDates,
  modifiers,
}) => {
  const ExampleCustomInput: React.FunctionComponent = ({ ...props }) => {
    return (
      <>
        <label htmlFor={id + "to"} className="sr-only">
          To
        </label>
        <input
          id={id + "to"}
          type="text"
          inputMode="none"
          autoComplete={autoComplete}
          className={className}
          {...props}
        />

        <div style={styles.dateIcon}>
          <CalenderDate />
        </div>
      </>
    );
  };
  const id = useId();

  return (
    <div className="input-group">
      <ReactDatePicker
        id={id + "to"}
        className={className}
        autoComplete={autoComplete}
        dateFormat={dateFormat}
        onChange={onChange}
        selected={selected}
        customInput={isIconVisible && <ExampleCustomInput />}
        name={name}
        dropdownMode={dropdownMode}
        placeholderText={placeholderText}
        maxDate={maxDate}
        minDate={minDate}
        onKeyDown={(e: any) => {
          e.preventDefault();
        }}
        highlightDates={highlightDates}
        // modifiers={modifiers}
      />
    </div>
  );
};
