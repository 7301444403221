import React, { useEffect, useState } from "react";
import { ClinicianLayout } from "../components/Layout/ClinicianLayout";
import { PatientCard } from "../components/QbCard/PatientCard";
import { SelectDropdown } from "../components/SelectDropdown/SelectDropdown";
import "../styles/ClinicianPatient.css";
import { useSearchParams } from "react-router-dom";
import useAuthContext from "../context/providers/AuthContext";

import appUrls from "../services/AppURLs";
import apiFunctions from "../services/api-service";
import Loading from "../components/Loading/Loading";
import { Withings } from './Withings';
interface ClinicianPatientProps {}

export const ClinicianPatient: React.FC<ClinicianPatientProps> = ({}) => {
  //get query params
  const { authDispatch } = useAuthContext();
  let [searchParams, setSearchParams] = useSearchParams();
  const newSets = [
    {
      questionName: "Food diary",
      questionSetCategory: "View your user’s food diary",
      title: "food-diary",
    },
    {
      questionName: "Questionnaire data",
      questionSetCategory: "View user’s Questionnaire data",
      title: "questionnaire",
    },
    {
      questionName: "Withings data",
      questionSetCategory: "View user’s Withings data",
      title: "withings",
    },
  ];
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState<any>();
  const [userNameList, setUserNameList] = useState<any>([]);
  const [particiantUser, setPaticipantUser] = useState<any>([]);
  const [selectedName, setSelectedName] = useState<any>({});
  const [MoudleList, setMoudleList] = useState<any>([newSets]);

  useEffect(() => {
    getParticipantUsers();
  }, [])
  async function getParticipantUsers() {
    try {
      await apiFunctions
        .get(
         
            "/api/account/get-all-user-data"
        )
        .then(async(_result) => {
          setPaticipantUser([..._result.data.result.userList]);
          await getParticipantsName(_result.data.result.userList);
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log("error", err);
        });
    } catch (error: any) {
      setLoading(false)
      console.log(error.response, "errorresposne");
    }
  }
  function getParticipantsName(userList: any) {
    const List = userList.map((list: any) => {
      return {
        uuid: list.uuid,
        name: list.firstName + "  " + list.lastName,
        withingsSync:list.withingsSync
      };
    });
    const UserId = List.filter((list: any) => {
      return list.uuid === searchParams.get("patientId");
    });

    setUserNameList([...List]);
    setSelectedName({ ...UserId[0] });
    setValue(UserId[0].uuid);
  }
  function setPatientUuuid() {
    let result = newSets.map((obj) => ({
      ...obj,
      uuid: selectedName.uuid ? selectedName.uuid : selectedName.value,
    }));
    let result1 = result.map((obj) => {
      if(obj.title === "food-diary"){
        obj.questionSetCategory = `View ${selectedName.name ? selectedName.name : selectedName.label}'s food diary.`
      }
      else if(obj.title === "questionnaire"){
        obj.questionSetCategory = `View ${selectedName.name  ? selectedName.name : selectedName.label}'s questionnaire data.`
      }
      else if(obj.title === "withings"){
        obj.questionSetCategory = `View ${selectedName.name  ? selectedName.name : selectedName.label}'s withings data.`
      }
return obj;
    });
    setMoudleList(result1);
    authDispatch({ type: "LOAD_PARTICIAPANT", participant: selectedName });
  }

  useEffect(setPatientUuuid, [selectedName]);

  return (
    <ClinicianLayout>
      <main className="main-container">
        <section className="outer-container">
          <p tabIndex={0}>
            In this section, you can view the participant's withings and food
            diary data and questionnaire responses.
          </p>
          <br />
          <div className="dropdown-block">
            <div />
            <div>
              <SelectDropdown
                type="text"
                name="center"
                label=""
                placeholder="Select patient"
                optionsArray={userNameList.map((item: any) => {
                  return { value: item.uuid, label: item.name, WithingsSync:item.withingsSync };
                })}
                onChange={(e: any) => {
                  setValue(e.value);
                  setSelectedName({ ...e });
                  authDispatch({ type: "LOAD_PARTICIAPANT", participant: e });
                }}
                value={value}
              />
            </div>
          </div>
          <br />
          {/* <h5>Patient name:  {selectedName.name ? selectedName.name :selectedName.label}</h5> */}
          <br />
          <PatientCard questions={MoudleList} />
          <Loading status={loading} />
        </section>
      </main>
    </ClinicianLayout>
  );
};
