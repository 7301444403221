// Doc: https://sghall.github.io/react-compound-slider

import React ,{ Component ,useEffect} from 'react';

import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';

import { SliderRail, Handle, Track, KeyboardHandle } from './slider-components';



const sliderStyle = {
  position: 'relative' as const,
  width: '100%',
  touchAction: 'none',
};

interface myProps {
  domain: Array<number>
  sliderValue: Array<number>
  onChangeValue?: any,
  disabled?:boolean
}

 const defaultValues = [1];

  export class RangeSlider extends Component<myProps, any> {
    state = {
      values: defaultValues.slice(),
      update: defaultValues.slice(),
    }

  onUpdate = (update: ReadonlyArray<number>) => {
    this.setState({ update });
    // this.props.onChangeValue(update)

  }
  onChange = (values: ReadonlyArray<number>) => {
    this.props.onChangeValue(values)
  }


  render() {
    const {
      state: { values, update },
    } = this;

    return (

      <div style={{ width: '100%', margin: '10px 0px' }} className={`${this.props.disabled ? 'disable-none' :''}`}>
        <Slider
          mode={1}          
          step={1}
          domain={this.props.domain}
          rootStyle={sliderStyle}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={this.props.sliderValue || values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className={`slider-handles ${this.props.disabled ? 'disable-none' :''}`}>
                {handles.map((handle) => (
                  <KeyboardHandle
                    key={handle.id}
                    handle={handle}
                    domain={[]}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div className={`slider-tracks ${this.props.disabled ? 'disable-none' :''}`}>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    // disabled = {this.props.disabled}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    );
  }
}
