import React from 'react';

import Select, { components } from 'react-select';
import { ReactComponent as Dropdownicon } from '../../assets/svg/Dropdown.svg';
import '../styles/SelectDropdown.css';

const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'transparent',
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

function IndicatorSeparator(innerProps: any) {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <Dropdownicon />
    </components.DropdownIndicator>
  );
};

export interface SelectDropdownOptionArrayProps {
  value: string | Array<string> | null;
  label: string | Array<string> | null;
  isDisabled ?: boolean;
}

interface SelectDropdownProps {
  label?: string;
  onChange?: (e: any) => void;
  onBlur?: any;
  type?: any;
  name?: any;
  optionsArray: Array<SelectDropdownOptionArrayProps>;
  errors?: string | null | any;
  placeholder?: string;
  value?: any;
  menuIsOpen?: boolean;
  isRequired?: boolean;
  description?: string | null;
  isSearchable?: boolean;
  id?: string;
  disabled?:boolean,
  isDisabled?:boolean
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  label,
  optionsArray,
  onChange,
  onBlur,
  name,
  errors,
  placeholder,
  value,
  menuIsOpen,
  isRequired = false,
  description,
  isSearchable,
  isDisabled

}) => {
  const tabIndex = 0;
  const customStyles = {};

  const defaultValue = (
    optionsArray: Array<SelectDropdownOptionArrayProps> ,
    value: string,
  ) => {
    return optionsArray
      ? optionsArray.find((optionsArray) => optionsArray.value === value)
      : '';
  };

  return (
    <div className="input-group">
      {label && (
        <>
          <label
            tabIndex={tabIndex}
            className={`${isRequired && 'required'}`}           
          >
            {label}
          </label>
          {description && <p tabIndex={tabIndex}>{description}</p>}
        </>
      )}
      <Select     
        components={{ DropdownIndicator, IndicatorSeparator }}
        menuIsOpen={menuIsOpen}
        isSearchable={isSearchable}
        name={name}
        className="react-select-container"
        classNamePrefix="react-select"
        styles={customStyles}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        options={optionsArray}
        isDisabled={isDisabled}
        value={defaultValue(optionsArray, value) || null}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#E0E0E0',
            primary: '#E0E0E0',
          },
        })}
      />
      {errors && <div className="form-error-msg">{errors}</div>}
    </div>
  );
};
