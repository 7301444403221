import React from 'react'
import { Layout } from '../components/Layout/Layout';

interface AboutMetabolicProps {

}

export const AboutMetabolic: React.FC<AboutMetabolicProps> = ({ }) => {
    return (
        <Layout>
            <div >About Metabolic </div>
        </Layout>
    );
}