import React, { useEffect, useRef, useState } from 'react';
import { ProfileDropdown } from '../ProfileDropdown/ProfileDropdown';
import { useLocation } from 'react-router-dom';
import appUrls from '../../services/AppURLs';

import "../styles/HeaderNav.css";
import useAuthContext from '../../context/providers/AuthContext';
import { useSearchParams } from 'react-router-dom';

interface HeaderNavProps {

}

export const HeaderNav: React.FC<HeaderNavProps> = ({ }) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [displyName, setDisplyName] = useState(true)

    const tabIndex = 0;
    const location = useLocation();
    const pathname = location.pathname;
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    /*
    Hook that alerts clicks outside of the passed ref
   */
    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowProfileDropdown(false);
                    // console.log('you clicked outside');
                }
            }
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef:any = useRef(null);
    useOutsideAlerter(wrapperRef);
    const { state: { participant } } = useAuthContext();
    const { state: { user } } = useAuthContext();

    useEffect(() => {
        if ((location.pathname.includes('/cliniciandashboard') && !location.pathname.includes('/cliniciandashboard/patient')) || (user && (user.userRole.roleInfo.name !== 'Admin'))) {
            setDisplyName(false)
        }
    }, [])


    return (
        <header className="header-info-section">
           {user &&  <section className="profile-section">
                <div className="menu-block" ref={wrapperRef}>
                    {user  &&<ProfileDropdown
                        user={`${user.firstName} ${user.lastName}`}
                        showProfileDropdown={showProfileDropdown}
                        onMenuChange={(e) => setShowProfileDropdown(e)}
                    />}

                </div>

            </section>
        }
         
            {(pathname === appUrls.fooddiary
                || pathname === appUrls.fooddiaryoverview
                || pathname === appUrls.diaryquestions) &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>FOOD DIARY</h1>
                    <p tabIndex={tabIndex}>
                        Keep track of your daily food, drink and supplement intake.
                    </p>

                </section>
            }

            {(pathname === appUrls.questionnaires || pathname === appUrls.question) &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>QUESTIONNAIRES</h1>
                    <p tabIndex={tabIndex}>
                        Answer the questions below
                    </p>

                </section>
            }
            {pathname === appUrls.withings &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>WITHINGS</h1>
                     {user && !user.withingsSync && <p tabIndex={tabIndex}>
                       Sync {participant  ?  `${participant?.name ||participant?.label}`:'your'} Withings account below
                    </p>
                    }
                    {user && user.withingsSync && <p tabIndex={tabIndex}>
                       Your Withings account is now linked
                    </p>
                    }

                </section>
            }
            {pathname === appUrls.ecg &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>WITHINGS</h1>
                    {((participant && !participant.withingsSync)||( !participant && user && !user.withingsSync)) && <p tabIndex={tabIndex}>
                       Sync {participant  ?  `${participant?.name ||participant?.label}`:'Your'} Withings account below
                    </p>
                    }
                    {((participant && participant.withingsSync)|| (!participant && user && user.withingsSync)) && <p tabIndex={tabIndex}>
                    {participant  ?  `${participant?.name ||participant?.label}`:'Your'} Withings account is now linked
                    </p>
                    }
                    <h4>{displyName && participant   &&  `${participant?.name ||participant?.label}`}</h4>
                </section>
            }
            {pathname === appUrls.spo &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>WITHINGS</h1>
                    {((participant && !participant.withingsSync)||( !participant && user && !user.withingsSync)) && <p tabIndex={tabIndex}>
                       Sync {participant  ?  `${participant?.name ||participant?.label}`:'your'} Withings account below
                    </p>
                    }
                    {((participant && participant.withingsSync)|| (!participant && user && user.withingsSync)) && <p tabIndex={tabIndex}>
                    {participant  ?  `${participant?.name ||participant?.label}`:'Your'} Withings account is now linked
                    </p>
                    }
                    <h4>{displyName && participant &&  `${participant?.name ||participant?.label}`}</h4>
                </section>
            }
            {pathname === appUrls.passive &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>WITHINGS</h1>
                    {((participant && !participant.withingsSync)||( !participant && user && !user.withingsSync)) && <p tabIndex={tabIndex}>
                       Sync {participant  ?  `${participant?.name ||participant?.label}`:'your'} Withings account below
                    </p>
                    }
                    {((participant && participant.withingsSync)|| (!participant && user && user.withingsSync)) && <p tabIndex={tabIndex}>
                    {participant  ?  `${participant?.name ||participant?.label}`:'Your'} Withings account is now linked
                    </p>
                    }
                    <h4>{displyName  && participant &&  `${participant?.name ||participant?.label}`}</h4>
                </section>
            }
            {pathname === appUrls.dashboard &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>PARTICIPANT DASHBOARD</h1>
                    <p tabIndex={tabIndex}>
                        Some information about  this study and what you will be doing
                    </p>
                </section>
            }
            {pathname === appUrls.aboutmetabolic &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>ABOUT METABOLIC MEDICINE</h1>
                    <p tabIndex={tabIndex}>
                        Some information about this study and what you will be doing
                    </p>
                </section>
            }
            {pathname === appUrls.terms &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>TERMS AND CONDITIONS</h1>
                    <p tabIndex={tabIndex}>
                        See the terms and conditions listed below
                    </p>
                </section>
            }
            {pathname === appUrls.privacy &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>PRIVACY STATEMENT</h1>
                    <p tabIndex={tabIndex}>
                        See the privacy statement listed below
                    </p>
                </section>
            }
            {pathname === appUrls.cookies &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>COOKIES</h1>
                    <p tabIndex={tabIndex}>
                        See the cookies listed below
                    </p>
                </section>
            }
            {/* Clinician Layout */}

            {pathname === appUrls.cliniciandashboard &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>CLINICIAN DASHBOARD</h1>
                    <p tabIndex={tabIndex}>
                        See participant data here
                    </p>

                </section>
            }
            {pathname === appUrls.clinicianoverview &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>CLINICIAN DASHBOARD</h1>
                    <p tabIndex={tabIndex}>
                        See participant data here
                    </p>


                </section>
            }

            {pathname === appUrls.clinicianpatient &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>CLINICIAN DASHBOARD</h1>
                    <p tabIndex={tabIndex}>
                        See participant data here
                    </p>

                </section>
            }

            {pathname === appUrls.admindashboard &&
                <section className="header-title-section" >
                    <h1 tabIndex={tabIndex}>Admin DASHBOARD</h1>
                    <p tabIndex={tabIndex}>
                        See participant data here
                    </p>

                </section>
            }

        </header>


    );
}