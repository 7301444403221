import React, { useEffect, useRef, useState } from 'react';
import { HeaderNav } from '../HeaderNav/HeaderNav';
import { PrimaryBar } from '../PrimaryBar/PrimaryBar';
import { useLocation } from 'react-router-dom';


interface ClinicianLayoutProps {
    children?: React.ReactNode;
}

export const ClinicianLayout: React.FC<ClinicianLayoutProps> = ({ children }) => {
    const location = useLocation();
    const pathname = location.pathname;
    const [headerHeight, setHeaderHeight] = useState<any>(0);
    const headerRef = useRef<HTMLDivElement>(null);
    const handleResize = () => {
        setHeaderHeight(headerRef?.current?.clientHeight);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        
    }, [headerHeight]);

    const totalHeight: any = headerHeight;
    const customHeight = `calc(100vh - ${totalHeight.toString()}px)`;
    // let navBodyHeight = window.innerHeight;
    // navBodyHeight = navBodyHeight - totalHeight;
    let minBodyHeight = customHeight;
    return (
        <section>
            <div className="header-section" ref={headerRef}>
                <HeaderNav />
             
                    <PrimaryBar />
             
            </div>

            {/* <div style={{ minHeight: navBodyHeight }}> */}
            <div className='background-wrapper' style={{ minHeight: minBodyHeight }}>
                {children}
            </div>
            {/* </div> */}

        </section>
    );
}