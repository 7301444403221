import React from 'react';
import "../styles/Radio.css";

interface RadioProps {
    disabled?: boolean;
    checked?: boolean;
    label?: string;
    name?: string;
    type: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    errors?: string | null;
    addClass?: string;
    parentClass?: string;
    colorCode?: string;
    id?: string;
}

export const Radio: React.FC<RadioProps> = (
    {
        disabled,
        checked,
        label,
        onChange,
        name,
        type,
        errors,
        onBlur,
        addClass,
        parentClass,
        value,
        colorCode,    
    }) => {
    const tabIndex = 0;

    return (
        <div className={` ${parentClass || 'inline-block'} `}>
            <label className={`radio-group text-left ${addClass}`}>
                {label}
                <input              
                    tabIndex={tabIndex}
                    type={type}
                    checked={checked}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={disabled}
                />
                {colorCode ? (
                    <span className="radio" style={{ backgroundColor: colorCode }} />
                ) : (
                    <span className="radio" />
                )}
            </label>
            {errors && <div className="form-error-msg">{errors}</div>}
        </div>
    );
}