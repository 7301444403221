import React, { useRef, useState, useEffect } from "react";
import { Layout } from "../Layout/Layout";

import "../styles/EcgChart.css";
// import { SelectDropdown } from "../SelectDropdown/SelectDropdown";
// import { BarChart } from "../GraphCharts/BarChart";
import { LineChart } from "../GraphCharts/LineChart";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { ToastContainer, toast } from "react-toastify";

import apiFunctions from "../../services/api-service";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loading from "../../components/Loading/Loading";
import moment from "moment";
import useAuthContext from "../../context/providers/AuthContext";

interface EcgChartProps { }

export const EcgChart: React.FC<EcgChartProps> = () => {
  const chartRef = useRef(null);
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  // Strat Graph
  const { state: { participant }, } = useAuthContext();

  const [yValues, setyValues] = useState<any>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [recordDates, setRecordDate] = useState<any>([]);
  const [maxEcg, setMaxEcg] = useState(4500);

  //Get chart Dates
  useEffect(() => {
    getChartValues(startDate);
  }, [startDate]);
  //Get chart Dates
  useEffect(() => {
    getChartDate();
  }, [startDate]);

  const withingsSyncError = (message: string) => {
    toast.dismiss();
    setLoading(false);
    toast.error(message, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };


  const getChartDate = async () => {
    try {
      setLoading(true);

      let URL: any;
      URL =
        `/api/withings/v2/get-heart-rate-dates`;
      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `?service_user_id=${searchParams.get("patientId")}`;
      }

      await apiFunctions
        .post(URL, {})
        .then((_result) => {
          const dataValues = _result.data.result;
          const dates = dataValues.map((date: any) => new Date(date.dates))
          setRecordDate(dates);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error.......", err);

          setLoading(false);
          if (err.response.data && err.response.data.result && !err.response.data.result.withingsSync) {
            withingsSyncError(err.response.data.msg);
            setTimeout(() => {
              if (!searchParams.get("patientId") || searchParams.get("patientId") === 'null') {
                navigate(`/withings`)
              }
            }, 3000);
          }
          console.log("error", err);
        });
    } catch (error: any) {
      console.log(error, "errorresposne");
    }
  };
  //Get chart Datas
  const getChartValues = async (date?: any, chartType?: string) => {
    try {
      setLoading(true);
      let URL: any;
      URL =
        `/api/withings/v2/heartlist?date=${moment(date).format("YYYY-MM-DD")}`;
      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `&service_user_id=${searchParams.get("patientId")}`;
      }

      await apiFunctions
        .post(URL, {})
        .then((_result) => {
          const dataValues = _result.data.result.FinnalResult;
          setyValues(dataValues);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      console.log(error, "errorresposne");
    }
  };


  // GET Tick interval for y-axis
  const getInterval = (data: any) => {
    const maxValue = Math.max(...data);
    const minValue = Math.min(...data);

    if (maxValue > 3000) {
      return 240
    } else {
      return 500
    }
  }
  //  Get Min and Max values for Y-axis
  const getMinMaxValue = (data: any, type: any) => {
    const maxValue = Math.max(...data);
    const minValue = Math.min(...data);
    let min = 0, max = 0;
    if (maxValue >= 3000 || minValue <= (-3000)) {
      min = data.length > 1 ? ((minValue) % 2 === 0 ? (minValue) : (minValue) + -1) : -1500;
      max = data.length > 1 ? ((maxValue) % 2 === 0 ? (maxValue) : (maxValue) + 1) : 1500;
    } else {
      min = data.length > 1 ? ((minValue + (-1500)) % 2 === 0 ? (minValue + (-1500)) : (minValue + (-1500)) + -1) : -1500;
      max = data.length > 1 ? ((maxValue + 1500) % 2 === 0 ? (maxValue + 1500) : (maxValue + 1500) + 1) : 1500;
    }
    return type === 'min' ? min : max;
  }
  // Export Get Min and Max values for Y-axis
  const getMinMaxValueExport = (data: any, type: any) => {
    const maxValue = Math.max(...data);

    const minValue = Math.min(...data);
    let min = 0, max = 0;
    if (maxValue >= 3000 || minValue <= (-3000)) {
      min = data.length > 1 ? ((minValue) % 2 === 0 ? (minValue) : (minValue) + -1) : -1500;
      max = data.length > 1 ? ((maxValue) % 2 === 0 ? (maxValue) : (maxValue) + 1) : 1500;
    } else {
      min = data.length > 1 ? ((minValue + (-3000)) % 2 === 0 ? (minValue + (-3000)) : (minValue + (-3000)) + -1) : -3000;
      max = data.length > 1 ? ((maxValue + 3000) % 2 === 0 ? (maxValue + 3000) : (maxValue + 3000) + 1) : 3000;
    }

    return type === 'min' ? min : max;
  }

  // ECG Graph render
  const renderECGChart = (data: any, AVG_HR: number, QRS: number, PR: number, QT: number, QTc: number) => {
    // setLoading(true);

    const optiondata: any = {
      chart: {
        zoomType: 'x',
        resetZoomButton: {
          position: {
            align: 'center', // right by default          
          },
          relativeTo: 'chart'
        },
        zooming: {
          mouseWheel: true
        }
      },
      colorAxis: [{
        tickColor: 'red'
      }],
      plotOptions: {
        series: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 1.5
            }
          }
        },
      },
      title: {
        useHTML: true,
        text: `</br>Average heart rate : ${AVG_HR} BPM  </br>QRS = ${QRS > 0 ? QRS : '--'} ms . PR = ${PR > 0 ? PR : '--'} ms . QT = ${QT > 0 ? QT : '--'} ms . QTc = ${QTc > 0 ? QTc : '--'} ms`
      },
      xAxis: {
        labels: {
          formatter: function (): any {
            const self: any = this;
            if (self.value % 300 == 0) {
              return (self.value / 300 + 's');
            }
          }
        },
        tickInterval: 60,
        minorTicks: true,
        gridLineWidth: 1.2,
        gridLineColor: '#efa6aa',
        minorGridLineColor: "#efa6aa",
        minorGridLineWidth: 0.5,
        min: 0,
        max: 1500,
        scrollbar: {
          enabled: true
        },
      },
      yAxis: {
        labels: {
          enabled: false
        },
        min: getMinMaxValue(data, 'min'),
        max: getMinMaxValue(data, 'max'),
        tickInterval: getInterval(data),
        minorTicks: true,
        minorGridLineWidth: 0.5,
        minorGridLineColor: "#efa6aa",
        gridLineWidth: 2,
        gridLineColor: '#efa6aa',
        title: {
          enabled: false
        }
      },
      tooltip: {
        useHTML: true,
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return this.x +
            `</br> ECG : <b>  ${this.y} (micro-volts) </b>`;
        },
      },

      legend: {
        enabled: false,
      },
      credits: {
        enabled: false
      },

      series: [{
        data: data,
        color: '#212121',
        lineWidth: 1.5
      }],
      exporting: {

        enabled: data.length > 1 ? true : false,
        sourceWidth: 4000,
        sourceHeight: 600,
        chartOptions: {
          xAxis: [{
            min: 0,
            minRange: data.length - 1,
            max: data.length - 1
          }],
          yAxis: [{
            min: getMinMaxValueExport(data, 'min'),
            minRange: getMinMaxValueExport(data, 'max'),
            max: getMinMaxValueExport(data, 'max')
          }],
          scrollbar: {
            enabled: false
          }
        },
        buttons: {
          contextButton: {
            align: "right",
            menuItems: ["viewFullscreen", "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"],

          }
        }
      }
    };
    // setLoading(false);

    return optiondata;
  }

  return (
    <Layout>
      <main className="main-container">
        <Loading status={loading} />
        <ToastContainer />

        <section className="outer-container">
          <br />
          <div className="warpper-circle">
            <div className="circle-dot"></div>
            <p className="ml-2 z-10">Encircled dates in the calendar have ECG values</p>
          </div>
          <div className="warpper-date-chart">

            <div>
              <p tabIndex={0} className="header-p">Selected date:</p>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                className="form-control text-field-date"
                name="startDate"

                dropdownMode="select"
                onChange={(e: Date) => {
                  setLoading(true);
                  setStartDate(e);
                  // document.getElementsByClassName('highcharts-scrollbar-thumb')[0].scrollLeft = 0;

                }}

                isIconVisible="true"
                autoComplete="off"
                placeholderText="Please select a date"
                maxDate={new Date()}
                highlightDates={recordDates}
              />
            </div>
            {searchParams.get("patientId") && searchParams.get("patientId") !== 'null' && (
              <div className="wrapper-date-clinic">
                <h5 tabIndex={0} className="particitant-w">
                  Participant:{" "}
                  {participant && `${participant?.name || participant?.label}`}
                </h5>
              </div>
            )}

          </div>
          <br />
          <section className="inner-chart-container">
            {yValues.length > 0 &&
              yValues.map((res: any) => {
                return (
                  <>
                    <div className="ecg-block">
                      {/* <div>
                    <p>Average heart rate: {res.heart_rate_average} BPM</p>
                    <p> QRS = {res.QRS}ms . PR = {res.PR}ms . QT = {res.QT}ms . QTc = {res.QTc}ms </p>
                    </div> */}
                      <div>
                        <p>
                          {moment(new Date(res.date)).format("llll")}
                        </p>
                      </div>
                    </div>
                    <LineChart
                      chartOption={renderECGChart(res.signal, res.heart_rate_average, res.QRS, res.PR, res.QT, res.QTc)}
                      chartType="line"
                    />
                    <div className="x-text">
                      signals range
                    </div>
                    <br />
                    <br />
                  </>
                );
              })}
            {!yValues.length && (
              <>
                <LineChart chartOption={renderECGChart([0], 0, 0, 0, 0, 0)} chartType="line" />
                <div className="x-text">
                  signals range
                </div>
              </>
            )}
          </section>
          <br /> <br />
        </section>
      </main>
    </Layout>
  );
};







