import React from 'react';
// import Chart from "react-apexcharts";
import  Highcharts from 'highcharts/highstock';

import  HighchartsReact from "highcharts-react-official";
import  exportingInit from 'highcharts/modules/exporting';
import  exportDataInit from 'highcharts/modules/export-data';

interface LineChartProps {
    chartType?: any;
    chartOption?:any;
    ref?:any;

}

export const LineChart: React.FC<LineChartProps> = (
    {
        chartType,
        chartOption,
        ref
    }
    ) => {

        exportingInit(Highcharts);
        exportDataInit(Highcharts);
        return (
            <section>
                <HighchartsReact containerProps={{ className: "line-chart-class" ,id:"line-chart-container"}}
                 highcharts={Highcharts} options={chartOption} type={chartType}   ref={ref}
                 />
            </section>
        );
}