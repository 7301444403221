import React, { useState, useEffect } from "react";
import { Button } from "../Button/Button";
import { Layout } from "../Layout/Layout";
import "../styles/Charts.css";
import { BarChart } from "../GraphCharts/BarChart";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import "../styles/PassiveChart.css";
import apiFunctions from "../../services/api-service";
import { useNavigate, useSearchParams } from "react-router-dom";

import Loading from "../../components/Loading/Loading";
import moment from "moment";
import useAuthContext from "../../context/providers/AuthContext";
import { ToastContainer, toast } from "react-toastify";

interface SpoChartProps {}

export const SpoChart: React.FC<SpoChartProps> = () => {
  const {
    state: { participant },
  } = useAuthContext();
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  // Strat Graph

  const [startDate, setStartDate] = useState(new Date());
  const [xValues, setxValues] = useState<any>([]);
  const [yValues, setyValues] = useState<any>([]);

  const [dayValues, setDateValues] = useState([0, 0, 0, 0, 0]);
  const [timeValues, settimeValues] = useState([
    "00:00",
    "06:00",
    "12:00",
    "18:00",
    "23:00",
  ]);
  const [feildyValues, setFeildValues] = useState<any>([]);
  const [feildType, setFeildType] = useState("SPO2");
  const [chartType, setChartType] = useState("day");
  const [recordDates, setRecordDate] = useState<any>([]);
  const withingsSyncError = (message:string)=>{
    toast.dismiss();
    setLoading(false);
    toast.error(message, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  useEffect(() => {
    getChartValues(startDate, chartType);
  }, [startDate, feildType, chartType]);
    //Get chart Dates
    useEffect(() => {
      getChartDate();
    }, [startDate]);
    
    const getChartDate = async () => {
      try {
        setLoading(true);
  
        let URL: any;
        URL =
          
          `/api/withings/v2/get-spo2-dates`;
        if (
          searchParams.get("patientId") &&
          searchParams.get("patientId") !== "null"
        ) {
          URL += `?service_user_id=${searchParams.get("patientId")}`;
        }
  
        await apiFunctions
          .post(URL, {})
          .then((_result) => {
            const dataValues = _result.data.result;
            const dates = dataValues.map((date:any) => new Date(date.dates))
            setRecordDate(dates);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.data&&err.response.data.result&&!err.response.data.result.withingsSync) {
              withingsSyncError(err.response.data.msg);
              setTimeout(() => {
                if (!searchParams.get("patientId")||searchParams.get("patientId")==='null') {
                  navigate(`/withings`)
                }
              }, 3000);
            }
            console.log("error", err);
          });
      } catch (err: any) {
        console.log(err, "errorresposne");
      }
    };
  //Get chart Datas
  const getDayValues = (value: any) => {
    let result: any = [];
    value.map((res: any) => {
      let objectValues: any = {};
      if (res >= 96 && res <= 100) {
        result.push({
          y: res,
          color: "green",
          marker: {
            radius: 7,
          },
        });
      } else if (res >= 94 && res <= 95) {
        result.push({
          y: res,
          color: "yellow",
          marker: {
            radius: 7,
          },
        });
      } else if (res >= 92 && res <= 93) {
        result.push({
          y: res,
          color: "amber",
          marker: {
            radius: 7,
          },
        });
      } else if (res > 0 && res <= 91) {
        result.push({
          y: res,
          color: "red",
          marker: {
            radius: 7,
          },
        });
      } else {
        result.push(res);
      }
    });
    return result;
  };
   //Get chart Datas
  const getChartValues = async (date?: any, chartType?: string) => {
    try {
      let URL: any;
      URL =
        
        `/api/withings/measure?charttype=${chartType}&date=${moment(date).format(
          "YYYY-MM-DD"
        )}`;
      if (
        searchParams.get("patientId") &&
        searchParams.get("patientId") !== "null"
      ) {
        URL += `&service_user_id=${searchParams.get("patientId")}`;
      }

      await apiFunctions
        .post(URL, {})
        .then((_result) => {
          const dataValues = _result.data.result;
          if (chartType === "day" && dataValues.dateGroup.length <= 0) {
            setxValues(timeValues);
            setyValues([{ data: dayValues, showInLegend: false }]);
            setFeildValues(dayValues);
          } else {
            setxValues(dataValues.xValues);
            setyValues([
              {
                data: chartType === "day" ? getDayValues(dataValues.yValues) :dataValues.yValues ,
                showInLegend: false,
              },
            ]);
            setFeildValues(dataValues.yValues);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    } catch (error: any) {
      console.log(error, "errorresposne");
    }
  };
  const btnWeek = [
    {
      name: "1 D",
      type: "day",
    },
    {
      name: "1 W",
      type: "week",
    },
    {
      name: "1 M",
      type: "month",
    },
    {
      name: "12 M",
      type: "year",
    },
  ];

  const optiondata = {
    chart: {
      height: 350,
      toolbar: {
        show: true,
      },
      scrollablePlotArea: {
        minWidth: 1800,
        scrollPositionX: 1,
      },
      type: chartType === "day" ? "scatter" :'line',
    },
    title: {
      text: "",
    },
    tooltip: {
      useHTML: true,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return this.x + `</br> SpO2 : <b>  ${this.y} (%) </b>`;
      },
    },
    dataLabels: {
      enabled: false,
    },
    xAxis: {
      scrollbar: {
        enabled: true,
      },
      categories: xValues,
      labels: {
        show: true,
        rotation: 270,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: ["var(--font-body)"],
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        grouping: false,
        shadow: false,
        cropThreshold: 100,
      },
    },
    yAxis: {
      title: {
        text: "Sp02 (%)",
      },
      min: 60,
      tickInterval: 5,
      labels: {
        show: true,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: ["var(--font-body)"],
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    colors: ["var(--primary-100)"],
    credits: {
      enabled: false,
    },
    series: yValues,
    exporting: {
      enabled: false,
     }
  };

  return (
    <Layout>
      <Loading status={loading} />
      <ToastContainer/>
      <main className="main-container">
        <section className=" outer-space outer-container">
          <div>
            <br />
            <div className="warpper-circle">
            <div className="circle-dot"></div>
            <p className="ml-2 z-10">Encircled dates in the calendar have SpO2 values</p>
          </div>
            <div className="warpper-date-chart">
              <div>
                <p tabIndex={0} className="header-p">
                  Selected date:
                </p>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={startDate}
                  className="form-control text-field-date"
                  name="startDate"
                  dropdownMode="select"
                  onChange={(e: Date) => {
                    setLoading(true);
                    setStartDate(e);
                    document.getElementsByClassName(
                      "highcharts-scrolling"
                    )[0].scrollLeft = 0;
                  }}
                  isIconVisible="true"
                  autoComplete="off"
                  placeholderText="Please select a date"
                  maxDate={new Date()}
                  highlightDates={recordDates}
                />
              </div>
              {searchParams.get("patientId") &&
                searchParams.get("patientId") !== "null" && (
                  <div className="wrapper-date-clinic">
                    <h5 tabIndex={0} className="particitant-w">
                      Participant:{" "}
                      {participant &&
                        `${participant?.name || participant?.label}`}
                    </h5>
                  </div>
                )}
            </div>
            <br />
            <section className="inner-chart-container">
              <BarChart chartOption={optiondata} chartType="" />

              <div className="x-text">
                <>
               {chartType === "day" && 'Hours of the day'} 
               {(chartType === "week" || chartType === "month") && `Days of the ${chartType}`} 
               {chartType === "year" && 'Weeks of the year'} 
                </>
              </div>
              <div className="warpper-chat-btn">
                {btnWeek.map((item: any) => {
                  return (
                    <Button
                      btntype="button"
                      id="button"
                      text={item.name}
                      addClass={`graph-btn btn-percent  ${
                        chartType === item.type ? "default-btn-active" : ""
                      }`}
                      onClick={(e: any) => {
                        setLoading(true);
                        setChartType(item.type);
                        document.getElementsByClassName(
                          "highcharts-scrolling"
                        )[0].scrollLeft = 0;
                      }}
                      disabled={chartType === item.type ? true : false}
                    />
                  );
                })}
              </div>
            </section>
            <br />
            <br /> <br />
            {/* Start Data display  */}
            <div className="scroll-date">
              <section className="wrapper-date-group">
                <Button
                  btntype="button"
                  id="button"
                  text={
                    chartType === "day"
                      ? "Time"
                      : chartType === "year"
                      ? "Week"
                      : "Date"
                  }
                  addClass="btn-withing cursor-set"
                />
                {xValues.map((res: any) => {
                  return (
                    <Button
                      btntype="button"
                      id="button"
                      text={res}
                      addClass="btn-withing cursor-set btn-active"
                    />
                  );
                })}
              </section>

              <section className="wrapper-date-group">
                <Button
                  btntype="button"
                  id="button"
                  text="Sp02 (%)"
                  addClass="btn-withing cursor-set btn-percent"
                />
                {feildyValues.map((res: any) => {
                  return (
                    <Button
                      btntype="button"
                      id="button2"
                      text={res}
                      addClass="btn-withing cursor-set btn-percent"
                    />
                  );
                })}
              </section>
            </div>
            
          </div>
        </section>
      </main>
    </Layout>
  );
};
