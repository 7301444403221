import React from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import "../styles/CustomDataTable.css";
import { Questionnaires } from '../../pages/Questionnaires';
import apiFunctions from "../../services/api-service";


interface CustomDataTableProps {
  userList?: any;
  adminView?: boolean
}

export const CustomDataTable: React.FC<CustomDataTableProps> = ({
  userList,
  adminView
}) => {
  const columns = [
    {
      name: "Name",
      width: "300px",
      selector: (row: any) => row.firstName + " " + row.lastName,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.firstName + " " + row.lastName ? (row.firstName + " " + row.lastName) : ''}
        </div>
      ),
    },
    {
      name: "Email",
      width: "500px",
      selector: (row: any) => row.email,
      cell: (row: any) => (
        <div tabIndex={0}>
          {row && row.email ? (row.email) : ''}
        </div>
      ),
    },
    {
      name: "Action",
      width: "200px",
      cell: (row: any) => (
        <span className="">
          <Link to={`/cliniciandashboard/patient?patientId=${row.uuid}`} className="action-view">{"VIEW"}{" "}
          </Link>
        </span>
      ),
    },
  ];


  return (
    <>
      <div className="dashboar-action">
        <DataTable columns={columns} data={userList} />

      </div>
    </>
  );
};
